import { Component, HostListener, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter , MatMomentDateModule} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCalendar, MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { firstValueFrom } from 'rxjs';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { DailyTotal } from 'src/app/models/schedules/daily-totals.model';
import * as moment from 'moment';
import { debug } from 'console';
import { UserSchedule } from 'src/app/models/schedules/user-schedule.model';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleCancellationDialogComponent } from '../../dialogs/schedule-cancellation-dialog/schedule-cancellation-dialog.component';
import { faL } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-user-agenda',
  templateUrl: './user-agenda.component.html',
  styleUrls: ['./user-agenda.component.scss'],
  providers: [
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: "pt-PT" },
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }}
  ]
})
export class UserAgendaComponent implements OnInit {

  public selectedDate: any;
  public currentMonthDate: Date;
  public dailyTotals: DailyTotal[] = [];
  public currentSchedules: UserSchedule[] = [];

  constructor(private agendajaClient: AgendaJaHttpClient, private renderer: Renderer2, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.selectedDate = moment();
    let dt = new Date();
    this.currentMonthDate = dt;
    this.getMonthlyData(dt?.getFullYear(), dt?.getMonth()+1);
    this.dateSelectedChange(this.selectedDate, true);
  }


  public getMonthlyData(year:number, month:number){
    this.lastMonth = month;
    this.agendajaClient.GetSchedulesTotalMonthly(year, month)
      .subscribe(
        {
          next: (schedules) => {
            this.dailyTotals = schedules;
          },
          error: (err: any) => {
            alert('Não foi possivel carregar os seus dados.');
          }
        }
      );
  }

  private lastMonth: number = 0;

  dateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate:moment.Moment, view) => {
      const dailyTotal = this.dailyTotals.find(x => new Date(x.MonthDay).getDate() === cellDate.date());
      if ((dailyTotal?.Total ?? 0) > 0) {
          return "date-with-schedules";
      }

      return '';
  };

  ngAfterViewInit() {
    const buttons = document.querySelectorAll('.mat-calendar-previous-button, .mat-calendar-next-button');    
    if (buttons) {
      Array.from(buttons).forEach(button => {
        this.renderer.listen(button, 'click', (e) => {
          this.calendarButtonsHandler(e);
        });
      });
    }
  }

  async calendarButtonsHandler(e:any){
    if(e.srcElement.className.indexOf('mat-calendar-previous-button') > 0){
      //prev
      this.currentMonthDate.setMonth(this.currentMonthDate.getMonth() - 1);
    }

    if(e.srcElement.className.indexOf('mat-calendar-next-button') > 0){
      //next
      this.currentMonthDate.setMonth(this.currentMonthDate.getMonth() + 1);
    }

    const results = await firstValueFrom(this.agendajaClient.GetSchedulesTotalMonthly(this.currentMonthDate.getFullYear(), (this.currentMonthDate.getMonth()+1)))
    this.dailyTotals = results;
    this.highlightDays();
  }

  private highlightDays() {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );

    Array.from(dayElements).forEach((element) => {

      var strtemp = element.getAttribute('aria-label');
      var dt = moment(strtemp, '"D [de] MMMM [de] YYYY"');
      const dailyTotal = this.dailyTotals.find(x => new Date(x.MonthDay).getDate() === dt.date());

      if ((dailyTotal?.Total ?? 0) > 0) {
        this.renderer.addClass(element, 'date-with-schedules');
      } else {
        this.renderer.removeClass(element, 'date-with-schedules');
      }
    });
  }

  dateSelectedChange(event:Date, highlightDays: boolean = false){
    this.selectedDate = event;

    this.agendajaClient.GetSchedulesByDay(this.selectedDate)
      .subscribe(
        {
          next: (schedules) => {
            this.currentSchedules = schedules;

            if(highlightDays)
            {
              this.highlightDays();
            }

          },
          error: (err: any) => {
            alert('Não foi possivel carregar os seus dados.');
          }
        }
      );
  }

  deleteDialog(idSchedule:number){
    const dialogRef = this.dialog.open(ScheduleCancellationDialogComponent,
      {
        autoFocus: false
      });
    dialogRef.componentInstance.idSchedule = idSchedule;

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);

      this.agendajaClient.GetSchedulesByDay(this.selectedDate)
      .subscribe(
        {
          next: (schedules) => {
            this.currentSchedules = schedules;
          },
          error: (err: any) => {
            alert('Não foi possivel carregar os seus dados.');
          }
        }
      );
    });
  }

}
