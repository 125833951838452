import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { BaseSession } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {

  public UserSession: BaseSession | null = null;
  public _requesting: boolean = false;

  constructor(private sessionService: SessionService, private router: Router,
    private agendaJaClient: AgendaJaHttpClient) { 
      this.sessionService.watchSession().subscribe((data:BaseSession) => {
        this.UserSession = data;
      });
    }

  ngOnInit(): void {
    this.UserSession = this.sessionService.Get();

    if(this.UserSession == null || this.UserSession.IsAnonymous){
      this.router.navigate(['/']);
    }
  }

  fileChange(event:any) {
    let fileList: FileList = event.target.files;

    if (fileList.length < 1) {
      return;
    }
    
    let file: File = fileList[0];

    if(!this.isImage(file.name)){
      alert("Só são suportados ficheiros JPG e PNG!");
      return;
    }

    var iConvert = file.size / 10485760;

    if(iConvert > 1.5){
      alert("O ficheiro não pode ser maior que 1.5MB!");
      return;
    }

    let formData:FormData = new FormData();
    formData.append('uploadFile', file, file.name)
    
    this._requesting = true;
    
    this.agendaJaClient.UpdateProfilePicture(formData)
      .subscribe({
        next: (result: any) => {
          this.sessionService.UpdateProfilePicture(result.Path);
          this._requesting = false;
          this.updateSession();
        },
        error: (err: any) => {
          this._requesting = false;
          alert('Não foi possivel carregar a imagem.');
        }
      });
  }

  updateSession(){
    this.agendaJaClient.GetUpdatedSession()
      .subscribe({
        next: (result: any) => {
          this.sessionService.StoreLocalSession(result)
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    
  }

  openInputFile(){
    let element: HTMLElement = document.getElementsByClassName('inputFile')[0] as HTMLElement;
    element.click();
  }

  isImage(filename:string) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'png':
        //etc
        return true;
    }
    return false;
  }

  getExtension(filename:string) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

}
