<div class="user-head">
    <div class="container">

        <div class="user-profile-wrapper" (click)="openInputFile()">

            <div *ngIf="_requesting">
                <mat-spinner [diameter]="65" style="margin: 0 auto;"></mat-spinner>    
            </div>

            <img *ngIf="!_requesting" class="user-profile" src="{{ UserSession?.ProfilePicPath }}">

            <input type="file" (change)="fileChange($event)" placeholder="Upload file" accept=".jpg,.png" class="inputFile">
        </div>
        <div class="user-name-wrapper">
            <h2 class="user-name">{{ UserSession?.Name }}</h2>
        </div>

    </div>
</div>