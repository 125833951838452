<div class="user-head">
    <div class="container">
        <div class="user-profile-wrapper">
            <img class="user-profile" src="{{ UserSession?.ProfilePicPath }}">
        </div>
        <div class="user-name-wrapper">
            <h2 class="user-name">{{ UserSession?.Name }}</h2>
        </div>

    </div>
</div>

<div class="container" style="min-height: 70vh;">

    <div class="row">
        <div class="col-xs-12 col-sm-3 menu-profile">
            <div class="menu-options">
                <div class="menu-option" [ngClass]="{'active': selectedMenu == 1}" (click)="changeMenu(1)">
                    <span>A minha agenda</span>
                </div>
                <div class="menu-option" [ngClass]="{'active': selectedMenu == 2}" (click)="changeMenu(2)">
                    <span>Perfil</span>
                </div>
                <div class="menu-option" [ngClass]="{'active': selectedMenu == 3}" (click)="changeMenu(3)">
                    <span>Carteira</span>
                </div>
                <!-- <div class="menu-option" [ngClass]="{'active': selectedMenu == 4}" (click)="changeMenu(4)">
                    <span>Histórico</span>
                </div> -->
                <div class="menu-option" [ngClass]="{'active': selectedMenu == 5}" (click)="changeMenu(5)">
                    <span>Reembolsos</span>
                </div>
            </div>
        </div>
    
        <div class="col-xs-12 col-sm-9">
            
            <div *ngIf="selectedMenu == 1">
                <span>AGENDA</span>
                <app-user-agenda></app-user-agenda>
            </div>
            <div *ngIf="selectedMenu == 2">
                <app-user-profile></app-user-profile>
            </div>
            <div *ngIf="selectedMenu == 3">
                <span>Carteira</span>
                <app-user-wallet></app-user-wallet>
            </div>
            <div *ngIf="selectedMenu == 4">
                <span>Historico</span>
                <app-user-history></app-user-history>
            </div>
            <div *ngIf="selectedMenu == 5">
                <span>Reembolsos</span>
                <app-user-refunds></app-user-refunds>
            </div>

        </div>    
    </div>
</div>
