import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { Session } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-show-message-dialog',
  templateUrl: './show-message-dialog.component.html',
  styleUrls: ['./show-message-dialog.component.scss']
})
export class ShowMessageDialogComponent implements OnInit {

  isAnimate: true;
  

  constructor(
    public dialog: MatDialog,
    private agendajaClient: AgendaJaHttpClient,
    public dialogRef: MatDialogRef<ShowMessageDialogComponent>, 
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.agendajaClient.Login(this.data.username, this.data.password)
        .subscribe(session => this.setSession(session));
  }

  continue(): void{
    this.dialogRef.close();
  }
  
  private setSession(session: Session|null){
    if(session != null && session != undefined){
      this.sessionService.StoreLocalSession(session);
    }
  }

}
