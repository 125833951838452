import { Injectable } from "@angular/core";
import { CartItem } from "../models/shopping-cart/shopping-cart-item.model";
import { LocalStorageService } from "./localstorage.service";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class CartService {

    private cartSubject = new Subject<CartItem[]>();
    private CART: string = "CART";

    constructor(private storageService :LocalStorageService){
        
    }

    watchCartItems(): Observable<CartItem[]> {
        return this.cartSubject.asObservable();
    }

    public AddScheduleCartItem(cartItem: CartItem) {
        var cartItems = this.GetScheduleCartItems();
        cartItem.Day = cartItem.Day.split('T')[0];
        cartItems.push(cartItem);
        this.storageService.setObject(this.CART, cartItems);
        this.cartSubject.next(cartItems);
    }

    public RemoveScheduleCartItem(cartItemId: number) {
        var cartItems = this.GetScheduleCartItems();
        cartItems = cartItems.filter(obj => {
            return obj.Id !== cartItemId
        });
        this.storageService.setObject(this.CART, cartItems);
        this.cartSubject.next(cartItems);
    }

    public GetScheduleCartItems(): CartItem[] {
        var cartItems = this.storageService.getObject(this.CART);
        if(cartItems == null)
        {
            return [];
        }
        return cartItems as CartItem[];
    }

    public ClearCart(){
        this.storageService.remove(this.CART);
        this.cartSubject.next([]);
    }
    
    public GetScheduleCartItem(serviceId: number, idEmployee:number, day: string, hour:string,): CartItem | null {
        var cartItems = this.storageService.getObject(this.CART) as CartItem[];
        if(cartItems == null)
        {
            return null;
        }
        var item = cartItems.filter(obj => {
            return obj.IdItem === serviceId
                && obj.IdEmployee === idEmployee
                && obj.Day === day
                && obj.Hour === hour
        });
        return item.length > 0 ? item[0] : null;
    }

}