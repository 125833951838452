export class UserRegister {

    FirstName: string;
    LastName: string;
    Birthdate: Date;
    TaxId: string;
    Address: string;
    ZipCode: string;
    ZipCodeExt: string;
    PhoneNumber: string;
    Email: string;
    Password: string;
    SMSNotification: boolean;
    EmailNotification: boolean;
    IdUserType: number;
    IdGenre: number;

    constructor() {
        this.FirstName = "";
        this.LastName = "";
        // this.Birthdate ;
        this.TaxId = "";
        this.Address = "";
        this.ZipCode = "";
        this.ZipCodeExt = "";
        this.PhoneNumber = "";
        this.Email = "";
        this.Password = "";    
        this.SMSNotification = false;
        this.EmailNotification = false;
        this.IdUserType = 1;
    }
}
