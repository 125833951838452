
<div *ngIf="requestStep == 1" class="container example-form">

    <h4>Valor a carregar</h4>

    <div class="row">
        <div style="text-align: center;">
            <mat-form-field style="width: 75px;" >
                <input
                  type="number"
                  class="form-control pay-amount"
                  matInput
                  name="value"
                  min="0"
                  (change)="onChange($event)"
                  [(ngModel)]="value">
                  
              </mat-form-field>   
              <span>€</span>
        </div>
    </div>

    <div class="row amount-buttons-wrapper">
        <div class="col-3" style="padding-left: 5px; padding-right: 5px;">
            <div class="amount-button" [ngClass]="autoAmount == 10 ? 'active' : ''"  (click)="setAmount(10)">
                <span>10€</span>
            </div>
        </div>
        <div class="col-3" style="padding-left: 5px; padding-right: 5px;">
            <div class="amount-button" [ngClass]="autoAmount == 20 ? 'active' : ''" (click)="setAmount(20)">
                <span>20€</span>
            </div>
        </div>
        <div class="col-3" style="padding-left: 5px; padding-right: 5px;">
            <div class="amount-button" [ngClass]="autoAmount == 30 ? 'active' : ''" (click)="setAmount(30)">
                <span>30€</span>
            </div>
        </div>
        <div class="col-3" style="padding-left: 5px; padding-right: 5px;">
            <div class="amount-button" [ngClass]="autoAmount == 50 ? 'active' : ''" (click)="setAmount(50)">
                <span>50€</span>
            </div>
        </div>
    </div>

    <div class="row payment-methods" >
        <div class="col-12">
            <div class="payment-type" (click)="setPaymentType(1)">
                <div class="payment-type-img">
                    <img src="../../../../assets/images/payments/mbway.png" width="45px" />
                </div>
                <span style="margin-left: 30px;font-family: Montserrat;cursor:pointer;">MBWay</span>
                <div class="payment-check" *ngIf="paymentType == 1">
                    <span class="material-symbols-outlined">
                        done
                    </span>
                </div>
                <br>
                <div *ngIf="paymentType == 1">
                    <form class="example-form" [formGroup]="phoneForm" style="margin-top: 20px;">
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Nº Telemóvel" formControlName="PhoneNumber"
                                [errorStateMatcher]="matcher" [(ngModel)]="mbwayPhone" maxlength="9">
                                <mat-error *ngIf="phoneForm.get('PhoneNumber')?.hasError('required')">
                                    O Nº de Telefone é obrigatório.
                                </mat-error>
                                <mat-error *ngIf="!phoneForm.get('PhoneNumber')?.hasError('required') && phoneForm.get('PhoneNumber')?.hasError('pattern')">
                                    Por favor, introduza um número de telefone válido.
                                </mat-error>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="payment-type" (click)="setPaymentType(2)">
                <div class="payment-type-img">
                    <img src="../../../../assets/images/payments/multibanco.png" width="30px" />
                </div>
                <span style="margin-left: 30px;font-family: Montserrat;cursor:pointer;">Multibanco</span>
                <div class="payment-check" *ngIf="paymentType == 2">
                    <span class="material-symbols-outlined">
                        done
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row action-wrapper">
        <button mat-flat-button color="primary" class="action-button" 
            [disabled]="value < 10 
                || paymentType == 0 
                || (paymentType == 1 && (phoneForm.get('PhoneNumber')?.hasError('required') || phoneForm.get('PhoneNumber')?.hasError('pattern')) ) ? '' : null"
            (click)="payWallet()" >Carregar Carteira {{ value | currency:'EUR'}}</button>    
    </div>
</div>

<div *ngIf="requestStep == 2" class="container example-form">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="requestStep == 3" class="container example-form">
    <div *ngIf="!requestSuccess" class="row">
        <p>Pedimos desculpa, mas não foi possível gerar o pedido de carregamento.</p>
    </div>

    <div *ngIf="requestSuccess" class="row">
        <div *ngIf="paymentType == 1">
            <p style="font-weight: 700;">Pedido efetuado com sucesso!</p>
            <p> Por favor, confirme no seu telemóvel o pedido de pagamento na aplicação MBWay.</p>
        </div>
        <div *ngIf="paymentType == 2">
            <p class="payment-label">Deve utilizar os seguintes dados para pagamento:</p>
            <p class="no-margin">Entidade: <b>{{ mbResponse.Entity }}</b></p>
            <p class="no-margin">Referência: <b>{{ mbResponse.Reference }}</b></p>
            <p>Valor: <b>{{ mbResponse.Value | currency:"EUR"}}</b></p>
            <p *ngIf="mbResponse.ExprirationDate != null">Expira em: {{ mbResponse.ExprirationDate }}</p>
        </div>

        <p class="expiration-warning">Os dados para pagamento são válidos durante 24h.</p>          

        <div class="action-buttons">
            <button mat-flat-button color="primary" class="action-button confirm" 
                (click)="close()" >Confirmar</button>    
        </div>
    </div>


</div>
