<div class="container" style="margin-bottom: 25px;">

    <mat-spinner *ngIf="showLoading" style="margin: 0 auto;"></mat-spinner>

    <div class="row" *ngIf="!showLoading">
        <div class="col-2 col-sm-1">
            <div>
                <img class="img-service" src="{{currentImageServicePath}}">
            </div>        
        </div>
        <div class="col-10">

            <h3>{{ serviceDetail?.ServiceName }}</h3>
            <div style="font-size: 19px;font-weight: 700;">
                <span class="material-symbols-outlined icon-header">
                    storefront
                </span>
                <span style="margin-left: 5px;">
                    {{ serviceDetail?.CompanyName }}
                </span>
            </div>

            <div>
                <span class="material-symbols-outlined icon-header">
                    schedule
                </span>
                <span style="font-size: 14px; margin-left: 5px;">
                    Duração: {{ serviceDetail?.DurationInMinutes }} minutos
                </span>
            </div>

            <div *ngIf="serviceDetail?.OnlinePayment">
                <span class="material-symbols-outlined icon-header">
                    credit_card
                </span>
                <span style="font-size: 14px; margin-left: 5px;"> Pagamento Online</span>
            </div>

            <div>
                <span class="material-symbols-outlined icon-header">
                    payments
                </span>
                <span style="font-size: 14px; margin-left: 5px;">
                    Preço: {{ serviceDetail?.FinalPrice | currency:'EUR' }}
                </span>
            </div>        

        </div>
    </div>

</div>

<div class="container" *ngIf="!showLoading">

    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">

            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Funcionário</mat-label>
                <mat-select [(value)]="userSelected">
                  <mat-option *ngFor="let employee of serviceDetail?.Employees" 
                                [value]="employee.IdUser" 
                                style="height: 60px;">
                    <div>
                        <img src="{{employee.PicPath}}" class="employee-pic">
                        <span class="employee-name">{{employee.FirstName}} {{employee.LastName}}</span>
                    </div>
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Data</mat-label>
                <input matInput type="date" [(ngModel)]="selectedDate" min="{{today}}" (ngModelChange)="onDayChange($event)"  >
            </mat-form-field>
            
            <br>

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Escolher Hora</mat-label>
                <input type="time" matInput min="00:00" max="23:59" step="300" [(ngModel)]="selectedHour">
             </mat-form-field>
             <br>
             <div style="padding: 5px; margin-bottom: 15px;">
                <button mat-flat-button style="width: 100%; background-image: linear-gradient(106deg, #02c6e8, #125bb9); color: white;" (click)="AddCustomHourToCart()">Agendar</button>            
            </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-5" style="text-align: center;">
            <div class="service-grid-container">

                <div *ngIf="scheduleBlocks.length > 0">
                    <div style="margin-bottom: 15px;">
                        <span class="cart-item-header">Sugestões</span>
                    </div>

                    <mat-card *ngFor="let item of scheduleBlocks;" class="matcard-block" 
                    [ngClass]="{'clicked': item.Selected, 'unavailable': !item.IsAvailable}"
                    (click)="ScheduleClick(item)">
                        <div>
                            <span><b>{{ item.Hour }}</b> h</span>
                        </div>
                    </mat-card>
                </div>

                <div *ngIf="scheduleBlocks.length == 0">
                    <p>Sem disponiblidade.</p>
                </div>
            </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="service-grid-container">
                <app-shopping-cart class="shoppingCart"></app-shopping-cart>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="!showLoading" style="margin-top: 40px;">
    <h4>Informações do Serviço</h4>

    <div class="row cstm">
        <div class="col-xs-12 col-sm-6">
            <p class="subtitle">Descrição do Serviço</p>
            {{ serviceDetail?.ServiceName }}
        </div>
        <div class="col-xs-12 col-sm-6">
            <p class="subtitle">Políticas de Cancelamento</p>
            <ul>
                <li *ngFor="let item of serviceDetail?.CancellationPolicies;">
                    <span>{{ item.Description }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>


<div class="container" style="margin-top: 40px;" *ngIf="!showLoading">
    <h4>Outros Serviços de {{ serviceDetail?.CompanyName }}</h4>

    <div class="row">
        <app-services-grid [services]="services"></app-services-grid>
    </div>
</div>
