import { Component, Input, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { CompanyCard } from 'src/app/models/company/company-card.model';
import { Company } from 'src/app/models/company/company.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit {

  @Input() company :CompanyCard;

  public rootPath:string=environment.Images.Root;

  constructor() { }

  ngOnInit(): void {
    
  }

  public getRoute(): string {

    if(this.company.CompanyCode != null && this.company.CompanyCode != ""){
      return "/"+this.company.CompanyCode;
    }
    else{
      return "/company/"+ this.company.IdCompany;
    }

  }

}
