export class CompanyInfo {
    Id: number;
    Name:string;
    Address:string;
    ZipCode:string;
    Locality:string;
    PhoneNumber: string;
    PhoneNumberAlternative: string;
    Email: string;
    Latitude: string;
    Longitude: string;
    CountryArea: string;
    Services:any;
    Observations:string;
    Banner: string;
    Logo: string;
    
    constructor() {
    }

}
