<div class="card"  routerLink="{{ getRoute() }}">
    <div>
        <img class="company-image" src="{{company.CardPhoto}}" alt="{{company.CompanyName}}" />
    </div>
    <div class="company-info">
        <div class="company-name">{{company.CompanyName}}</div>
        <div class="company-type">{{company.CompanyType}}</div>
    </div>
    <input type="button" class="company-button" value="Agenda Já">
    <div class="company-district">{{company.CardLocalization}}</div>
</div>

