<app-user-header></app-user-header>

<div class="container" style="min-height: 70vh;">

    <div class="row">
        <div class="col-12 personal-head-title">
            <h4>Agenda</h4>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-3">
            <div>               
                <mat-calendar #calendar [(selected)]="selectedDate" (selectedChange)="dateSelectedChange($event)" >
                </mat-calendar> 

            </div>
        </div>

        <div class="col-xs-12 col-sm-9" *ngIf="this.currentSchedules.length > 0">
            
            <div *ngFor="let schedule of currentSchedules;" class="row schedule-row">
                <div class="d-none d-sm-block col-sm-1">
                    <div>
                        <img src="{{ schedule.ServicePicPath }}" width="100%" style="border-radius: 15px;"/>
                    </div>
                </div>
                <div class="col-5 col-sm-5">
                    <div>
                        <span class="date">{{ schedule.StartDate | date:'dd MMMM'  }}</span>
                    </div>
                    <div>
                        <span class="service">{{ schedule.ServiceName }}</span>
                    </div>
                    <div>
                        <span class="company">{{ schedule.CompanyName }}</span>
                    </div>
                </div>
                <div class="col-5 col-sm-5">
                    <div>
                        <span class="token">Código: {{ schedule.Token }}</span>
                    </div>
                    <div>
                        <span class="week-day"> {{ schedule.StartDate | date:'EEEE' }} </span>
                    </div>
                    <div>
                        <span class="hour">{{ schedule.StartDate | date:'HH:mm' }}h</span>
                    </div>
                </div>

                <!-- Need to evaluate the cancellation policies of each schedule -->
                <div class="col-1 col-sm-1" *ngIf="schedule.CanCancel">
                    <div class="example-button-container">
                        <button mat-mini-fab color="warn" aria-label="Cancelar" (click)="deleteDialog(schedule.IdSchedule)">
                          <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-divider style="margin-top: 15px;"></mat-divider>
            </div>

        </div>

        <div class="col-xs-12 col-sm-9" *ngIf="this.currentSchedules.length == 0">
            <div class="row schedule-row">
                <p>Sem agendamentos para este dia.</p>
            </div>
        </div>

    </div>
</div>

