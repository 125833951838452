<div *ngIf="UserSession != null && UserSession.IdUser > 0" style="color: white; margin-top: 15px; margin-bottom: 15px;">

    <mat-divider></mat-divider>

    <div class="personal-data-menu" style="cursor: pointer;">
        <div class="user-profile-wrapper" (click)="navigateTo('user/profile')">
            <img class="user-profile" src="{{ profilePicPath }}">
        </div>
        <div class="username" (click)="navigateTo('user/profile')">
            <span>{{UserSession.Name}}</span>
        </div>
        <div class="balance" (click)="navigateTo('user/wallet')">
            <span>{{UserSession.Wallet.Balance | currency: 'EUR' }}</span>
        </div>
    </div>


    <div>
        <div class="menu-item" (click)="navigateTo('user/agenda')">
            <span>Agenda</span>
        </div>
        <mat-divider></mat-divider>
        <div class="menu-item" (click)="navigateTo('user/wallet')">
            <span>Carteira</span>
        </div>
        <!-- <div class="menu-item" routerLink="user/profile" (click)="toggleSidenav()">
            <span>Perfil</span>
        </div>
        <mat-divider></mat-divider> -->
        <!-- <div class="menu-item" routerLink="user/history" (click)="toggleSidenav()">
            <span>Histórico</span>
        </div> -->
        <mat-divider></mat-divider>
        <div class="menu-item" (click)="navigateTo('user/refunds')">
            <span>Reembolsos</span>
        </div>
        <mat-divider></mat-divider>
    </div>

    <input type="button" class="btnLogin btnLogout" value="LOGOUT" (click)="LogOut()" />
</div>


<div *ngIf="UserSession == null || UserSession.IdUser == 0" style="color: white; margin-top: 15px;">
    <input type="button" class="btnLogin" value="LOGIN" mat-button (click)="TryLogin()" />
</div>
