<div class="close" (click)="Close()">
    <span>x</span>
</div>

<div class="head-login-modal">
    <h4>Iniciar sessão em Agenda Já</h4>
</div>

<form class="example-form">
    <div *ngIf="showLoading == false">
        
        <mat-form-field class="example-full-width">
            <mat-label>E-mail</mat-label>
            <input matInput placeholder="E-mail" class="login-input" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" ngModel email>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" class="login-input" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <div class="login-errors">
            <mat-error *ngIf="loginErrorEmail">Dados de login inválidos!</mat-error>
        </div>

        <div class="action-row">
            <button mat-flat-button color="primary" class="login-button" (click)="Login()">Iniciar Sessão</button>    
        </div>
        <div class="action-row">
            <button mat-button color="primary" class="forget-password-button" (click)="Recovery()">Esqueci-me da palavra-passe!</button>
        </div>
        <div class="action-row">
            <button mat-stroked-button color="primary" class="register-button" (click)="Register()">Registar</button>   
        </div>

    </div>
    
    <div *ngIf="showLoading" class="loading-spinner">
        <mat-spinner></mat-spinner>
    </div>

</form>
