import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, startWith, map } from 'rxjs';
import { SearchResult } from 'src/app/models/search/search-result.model';
import { MatDividerModule } from '@angular/material/divider';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {


  myControl = new FormControl('');
  contactNameControl = new FormControl();
  contactPhoneControl = new FormControl();
  contactReasonControl = new FormControl();

  options: SearchResult[];
  filteredOptions: Observable<SearchResult[]>;

  constructor(private agendajaClient: AgendaJaHttpClient,private router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    
  }

  private _filter(value: string): SearchResult[] {
    this.search(value);
    return this.options; //?.filter(option => option.Name.toLowerCase().includes(filterValue));
  }
  
  private search(value: string){
    this.agendajaClient.Search(value)
          .subscribe(session => this.setResults(session));
  }

  private setResults(results: SearchResult[]){
    this.options = results;
  }

  public searchOption(option:SearchResult){
    if(option.IdCompany != null){
      this.router.navigate([`/company/${option.IdCompany}`]);
    }
  }

  public contactMe(){
    var name = this.contactNameControl.value;
    var contact = this.contactPhoneControl.value;
    var reason = this.contactReasonControl.value;
    
    this.agendajaClient.SendContactRequest(name, contact, reason)
            .subscribe(result => {
              this.toastr.success("Entraremos em contacto contigo o mais rápido possível. Obrigado!","Pedido de contacto enviado!");
              this.contactNameControl.setValue('');
              this.contactPhoneControl.setValue('');
              this.contactReasonControl.setValue('');
            });
  }

}
