import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from  '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { CheckoutSession } from 'src/app/models/checkout/checkout-session.model';
import { CompanyCard } from 'src/app/models/company/company-card.model';
import { CompanyInfo } from 'src/app/models/company/company-info.model';
import { Company } from 'src/app/models/company/company.model';
import { RefundRequestInput } from 'src/app/models/refunds/refund-request-input.model';
import { RefundRequestMessage } from 'src/app/models/refunds/refund-request-message.model';
import { RefundRequestMessageInput } from 'src/app/models/refunds/refund-request-mssage-input.model';
import { RefundRequest } from 'src/app/models/refunds/refund-request.model';
import { DailyTotal } from 'src/app/models/schedules/daily-totals.model';
import { ScheduleBlock } from 'src/app/models/schedules/schedule-block.model';
import { UserSchedule } from 'src/app/models/schedules/user-schedule.model';
import { SearchResult } from 'src/app/models/search/search-result.model';
import { ServiceCatalog } from 'src/app/models/services/service-catalog';
import { CancellationPolicy, ServiceDetail } from 'src/app/models/services/service-detail.model';
import { Session } from 'src/app/models/session/session.model';
import { CartItem } from 'src/app/models/shopping-cart/shopping-cart-item.model';
import { InstagramEmbed } from 'src/app/models/social/instagram/embed.model';
import { PasswordChange } from 'src/app/models/user/password-change.model';
import { UserInfo } from 'src/app/models/user/user-info.model';
import { UserRegister } from 'src/app/models/user/userregister.model';
import { PaymentResult } from 'src/app/models/wallet/pay-result.model';
import { PayWallet } from 'src/app/models/wallet/pay-wallet.module';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
providedIn:  'root'
})
export class AgendaJaHttpClient {
    private _url = environment.APIS.AgendaJa.URL;
    
    readonly _httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        params: {}
    };

    constructor(private http: HttpClient, private sessionService: SessionService) { 

    }

    public RecoverPasswordViaAccessCode(token: string, password: string){
        let route = `/authentication/password/recovery/submit`;
        return this.http.post<boolean>(`${this._url}${route}`, {Token: token, Password: password}, this.getHeaders())
            .pipe(
                tap((created: boolean) => { return created; }),
                catchError( this.handleError<any>('Cant register!'))
            );        
    }

    public ValidateAccessToken(token: string){
        let route = `/authentication/password/recovery/validate/${token}`;
        return this.http.get<boolean>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((created: boolean) => { return created; }),
                catchError( this.handleError<any>('Cant register!'))
            );        
    }

    public RecoverPassword(emailToRecover: string){
        let route = "/authentication/password/recovery";
        return this.http.post<boolean>(`${this._url}${route}`, { email: emailToRecover }, this.getHeaders())
            .pipe(
                tap((created: boolean) => { return created; }),
                catchError( this.handleError<any>('Cant register!'))
            );        
    }

    public Register(user: UserRegister){
        let route = "/user/register";
        return this.http.post<boolean>(`${this._url}${route}`, user, this.getHeaders())
            .pipe(
                tap((created: boolean) => { return created; }),
                catchError( this.handleError<any>('Cant register!'))
            );        
    }

    public Login(username: string, password:string){
        let route = "/authentication/login";
        let body = { UserName: username, Password: password };
        return this.http.post<Session>(`${this._url}${route}`, body, this.getHeaders())
            .pipe(
                tap((session: Session) => { return session; }),
                catchError( this.handleError<any>('Login not found!'))
            );
    }

    public GetAutoCheckoutSession(){
        let route = "/checkout/external";
        return this.http.post<CheckoutSession>(`${this._url}${route}`, null, this.getHeaders())
            .pipe(
                tap((session: CheckoutSession) => { return session; }),
                catchError( this.handleError<any>('Can not get checkout session!'))
            );
    }

    public ValidateSession(){
        let route = "/authentication/validate";
        return this.http.get<boolean>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((isValid: boolean) => { return isValid; }),
                catchError( this.handleError<any>('Error validating session!'))
            );
    }

    public SessionRefresh(){
        let route = "/authentication/refresh";
        return this.http.get<boolean>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((newSession: boolean) => { return newSession; }),
                catchError( this.handleError<any>('Error validating session!'))
            );
    }

    public SessionWalletRefresh(){
        let route = "/authentication/refresh/wallet";
        return this.http.get<boolean>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((newSession: boolean) => { return newSession; }),
                catchError( this.handleError<any>('Error validating session!'))
            );
    }

    public GetUpdatedSession(){
        let route = "/authentication/update";
        return this.http.get<Session>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((updatedSession: Session) => { return updatedSession; }),
                catchError( this.handleError<any>('Error updating session!'))
            );
    }

    public GetAnonymousSession(){
        let route = "/authentication/anonymous";
        return this.http.get<Session>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((session: Session) => { return session; }),
                catchError( this.handleError<any>('It was not possible to create an anonymous session!'))
            );
    }

    public GetCompanies(){
        let route = "/company";
        return this.http.get<CompanyCard[]>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: CompanyCard[]) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public GetCompany(idCompany:number){
        let route = `/company/${idCompany}`;
        return this.http.get<CompanyInfo>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: CompanyInfo) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public GetCompanyByCode(companyCode:string){
        let route = `/company/code/${companyCode}`;
        return this.http.get<CompanyInfo>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: CompanyInfo) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public GetServices(idCompany:number){
        let route = `/company/${idCompany}/services`;
        return this.http.get<ServiceCatalog>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: ServiceCatalog) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public GetServicesByCompanyCode(companyCode:string){
        let route = `/company/code/${companyCode}/services`;
        return this.http.get<ServiceCatalog>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: ServiceCatalog) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public Search(value: string){
        let route = `/search?s=${value}`;
        return this.http.get<SearchResult[]>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((results: SearchResult[]) => { return results; }),
                catchError( this.handleError<any>('Cant register!'))
            );        
    }

    public GetServiceDetails(idService:number){
        let route = `/service/${idService}`;
        return this.http.get<ServiceDetail>(`${this._url}${route}`,this.getHeaders())
            .pipe(
                tap((companies: ServiceDetail) => { return companies; }),
                catchError( this.handleError<any>('Can not get companies!'))
            );
    }

    public GetScheduleBlocks(idService:number, idEmployee: number, date:string){
        let route = `/schedules`;
        return this.http.get<ScheduleBlock>(`${this._url}${route}?idService=${idService}&day=${date}&idEmployee=${idEmployee}`, this.getHeaders())
            .pipe(
                tap((schedules: ScheduleBlock) => { return schedules; }),
                catchError( this.handleError<any>('Can not get schedules!'))
            );
    }

    public AddItemToCart(item: CartItem){
        let route = `/cart`;
        return this.http.post<CartItem>(`${this._url}${route}`, item, this.getHeaders())
            .pipe(
                tap((cartItem: CartItem) => { return cartItem; }),
                catchError( this.handleError<any>('Can not get schedules!'))
            );
    }

    public RemoveItemFromCart(cartItemId: number){
        let route = `/cart/delete`;
        return this.http.get(`${this._url}${route}/${cartItemId}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( this.handleError<any>('Can not get schedules!'))
            );
    }

    public Checkout(){
        let route = `/cart/checkout`;
        return this.http.post(`${this._url}${route}`,null, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetUser(){
        let route = `/user`;
        return this.http.get(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public UpdateUser(user: UserInfo){
        let route = `/user`;
        return this.http.post(`${this._url}${route}`,user, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public UpdatePassword(passwordData: PasswordChange){
        let route = `/user/password`;
        return this.http.post(`${this._url}${route}`, passwordData, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public UpdateProfilePicture(formData:FormData){
        let route = `/user/pic`;
        return this.http.post(`${this._url}${route}`, formData, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetSchedulesTotalMonthly(year: number, month: number){
        let route = `/schedules/monthly/totals/${year}/${month}`;
        return this.http.get<DailyTotal[]>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetSchedulesByDay(date: moment.Moment){
        let route = `/schedules/daily/${date.format("yyyy-MM-DD")}`;
        return this.http.get<UserSchedule[]>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetSchedulesCancellationPolicy(idSchedule:number){
        let route = `/schedules/cancellationPolicy/${idSchedule}`;
        return this.http.get<CancellationPolicy>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public CancelSchedule(idSchedule:number){
        let route = `/schedules/cancel`;
        let body = { IdSchedule: idSchedule };
        return this.http.post<boolean>(`${this._url}${route}`, body, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public PayWallet(request:PayWallet){
        let route = `/wallet/pay`;
        return this.http.post<PaymentResult>(`${this._url}${route}`, request, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetRefunds(){
        let route = `/refund`;
        return this.http.get<RefundRequest[]>(`${this._url}${route}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public CreateRefundRequest(request:RefundRequestInput){
        let route = `/refund`;
        return this.http.post<boolean>(`${this._url}${route}`, request, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public CreateRefundRequestMessage(request:RefundRequestMessageInput){
        let route = `/refund/message`;
        return this.http.post<RefundRequestMessage>(`${this._url}${route}`, request, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public RemoveAccount(){
        let route = `/account/delete`;
        return this.http.post<boolean>(`${this._url}${route}`,null, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public GetInstagramEmbed(idCompany: number){
        let route = `/social/instagram`;
        return this.http.get<InstagramEmbed[]>(`${this._url}${route}?idCompany=${idCompany}`, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    public SendContactRequest(name: string, contact: string, reason: string){
        let route = `/contact`;

        var input :any = {
            Name: name,
            Contact: contact,
            Reason: reason
        };

        return this.http.post<boolean>(`${this._url}${route}`, input, this.getHeaders())
            .pipe(
                tap((result) => { return result; }),
                catchError( 
                    this.handleErrorAndThrow
                )
            );
    }

    private handleErrorAndThrow(error: HttpErrorResponse) {
        return throwError(() => error);
    }

    private getHeaders(){
        var header = {
            headers: new HttpHeaders()
              .set('Authorization',  `Bearer ${this.sessionService.GetSessionToken()}`)
          };
        return header;
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            // //   console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);
    
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}