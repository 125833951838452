import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';

@Component({
  selector: 'app-password-recovery-confirm-page',
  templateUrl: './password-recovery-confirm-page.component.html',
  styleUrls: ['./password-recovery-confirm-page.component.scss']
})
export class PasswordRecoveryConfirmPageComponent implements OnInit {

  public passwordFormGroup: FormGroup;
  public token: string;
  public newPassword: string;
  public confirmNewPassword: string;
  public isTokenValid: boolean = false;
  public showLoading: boolean = true;

  constructor(private route: ActivatedRoute, private agendajaClient: AgendaJaHttpClient, private router: Router) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.token = String(routeParams.get('code'));
    this.createFormGroup();
    this.validateAccessCode();
  }

  validateAccessCode(){
    this.agendajaClient.ValidateAccessToken(this.token)
    .subscribe({
      next: (isValid:boolean) => {
        this.isTokenValid = isValid;
        this.showLoading = false;
      },
      error: (err:any) => {
        this.isTokenValid = false;
        this.showLoading = false;
      }
    })
  }

  createFormGroup(){
    this.passwordFormGroup = new FormGroup({
      passwordCtrl: new FormControl(this.newPassword,
        [Validators.required,
        Validators.nullValidator,
        Validators.minLength(6),
        Validators.maxLength(18),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ]),
      passwordConfirmationCtrl: new FormControl(this.confirmNewPassword,
        [Validators.required,
        Validators.nullValidator,
        Validators.minLength(6),
        Validators.maxLength(18),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
        ])
    });

    this.passwordFormGroup.addValidators(
      this.matchValidator(
        this.passwordFormGroup.get('passwordCtrl'),
        this.passwordFormGroup.get('passwordConfirmationCtrl')
      )
    );
  }

  matchValidator(control: AbstractControl | null, controlTwo: AbstractControl | null): ValidatorFn {
    return () => {
      if (control?.value !== controlTwo?.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  submitNewPassword(){
    this.agendajaClient.RecoverPasswordViaAccessCode(this.token, this.newPassword)
      .subscribe({
        next: (result) => {
          if(result){
            alert("Password alterada com sucesso!");
            this.router.navigate(['/']);
          }
          else{
            alert("Não foi possível alterar a password!");
          }
        },
        error: () => {
          alert("Não foi possível alterar a password!");
        }
      });
  }

}
