export const environment = {
  production: true,

  APIS : {
    AgendaJa : {
      URL : "https://staging-api.agendaja.pt"
    }
  },

  Images:{
    Root: "https://agendaja.pt/"
  }
};
