import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { Employee, ServiceDetail } from 'src/app/models/services/service-detail.model';
import { MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ScheduleBlock, ScheduleBlockViewModel } from 'src/app/models/schedules/schedule-block.model';
import { CartItem } from 'src/app/models/shopping-cart/shopping-cart-item.model';
import { CartService } from 'src/app/services/cart.service';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { ServiceCatalog } from 'src/app/models/services/service-catalog';
import { firstValueFrom } from 'rxjs';
import { CompanyInfo } from 'src/app/models/company/company-info.model';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent implements OnInit {

  public idCompany:number=0;
  public idService:number=0;
  public userSelected: number;

  public serviceDetail: ServiceDetail|null;
  public scheduleBlocks: ScheduleBlockViewModel[] = [];

  public selectedDate: string;
  public selectedHour: string;
  
  public today:string;

  CartItems: CartItem[] = [];
  public services:ServiceCatalog[] = [];
  public company:CompanyInfo|null = null;
  
  public currentImageServicePath = '';

  public showLoading: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, private agendaJaClient: AgendaJaHttpClient,
    private cartService: CartService, private toastr: ToastrService, private titleService:Title) { 

      this.cartService.watchCartItems().subscribe((data:CartItem[]) => {
        this.CartItems = data;
        this.ShowScheduleBlocks(this.scheduleBlocks);
      });
  
    }

  ngOnChanges(): void{

  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.idCompany = +params['companyId'];
      this.idService = +params['idService'];

      this.selectedDate = this.getDateInISO_8601();
      this.today = this.getDateInISO_8601();
      this.getService();

      if(this.services.length == 0)
      {
        this.getCompanyServices();
      }

      if(this.company == null){
        this.getCompanyInfo();
      }

      if(this.services.length > 0){
        this.currentImageServicePath = this.services.find(k => k.Id == this.idService)?.Path ?? '';
      }

      window.scrollTo(0, 0);  

      this.showLoading = false;
    });
  } 

  async getService(){
    const serviceDetailResponse = await firstValueFrom(this.agendaJaClient.GetServiceDetails(this.idService));
    this.serviceDetail = serviceDetailResponse;

    if(this.serviceDetail == null){
      this.router.navigate(['/']);
    }

    if(serviceDetailResponse.Employees.length > 0){
      this.userSelected = serviceDetailResponse.Employees[0].IdUser;
      this.GetScheduleBlocks();
    }
    else{
      this.ShowScheduleBlocks([]);
    }

    this.titleService.setTitle("Agenda Já - " + this.serviceDetail?.ServiceName);
  }

  public GetScheduleBlocks(){
   
    this.agendaJaClient.GetScheduleBlocks(this.idService, this.userSelected, this.selectedDate)
      .subscribe(schedules => {
        this.ShowScheduleBlocks(schedules as ScheduleBlockViewModel[]);
      });

  }

  public onDayChange(value:any){
    this.selectedDate = value;
    this.GetScheduleBlocks();
  }

  public ShowScheduleBlocks(schedules:ScheduleBlockViewModel[]){
    if(schedules != null && schedules.length > 0){

      let tempSchedules = schedules.filter(k => k.IsAvailable);
      var items = this.cartService.GetScheduleCartItems();

      tempSchedules.forEach(element => {
        var item = items.find(k => k.IdItem == element.IdService 
                                && k.IdEmployee == this.userSelected
                                && k.Day == this.selectedDate
                                && k.Hour == element.Hour);

        element.Selected = item != null;
      });

      this.scheduleBlocks = tempSchedules;
    }
    else{
      this.scheduleBlocks = [];
    }
  }

  public ScheduleClick(schedule: ScheduleBlockViewModel){
    if(schedule.IsAvailable){
      schedule.Selected = !schedule.Selected; 
      
      if(schedule.Selected){
        this.AddItemToCart(schedule.Hour, schedule);
      }
      else{
        var item = this.cartService.GetScheduleCartItem(this.idService, this.userSelected, this.selectedDate, schedule.Hour);
        if(item != null)
        {
          this.DeleteItemFromCart(item.Id ?? 0);
        }
      }
    }
  }

  public AddCustomHourToCart(){
    this.AddItemToCart(this.selectedHour, null);
  }

  public AddItemToCart(hour:string, schedule:any|null){

    var svDetailImage = this.services.find(k => k.Id == this.idService)?.Path;

    let cartItem: CartItem = {
      Id: null,
      IdItemType: 1, //schedule
      IdItem: this.idService,
      IdCompany: this.idCompany,
      Quantity: 1,
      Day: this.selectedDate,
      Hour: hour,
      IdEmployee: this.userSelected,
      CompanyName: this.serviceDetail?.CompanyName ?? "",
      ServiceName: this.serviceDetail?.ServiceName ?? "",
      Price: this.serviceDetail?.FinalPrice ?? 0,
      CompanyAddress: `${this.company?.Address}, ${this.company?.ZipCode}, ${this.company?.Locality} `,
      OnlinePayment: this.serviceDetail?.OnlinePayment ?? false,
      ServiceLogoPath: svDetailImage ?? ""
    };

    this.agendaJaClient.AddItemToCart(cartItem)
      .subscribe(cartItemResult => {
        this.AddItemToCartResult(cartItem, cartItemResult, schedule);
      });
  }

  public DeleteItemFromCart(cartItemId: number){
    this.agendaJaClient.RemoveItemFromCart(cartItemId)
    .subscribe(cartItemResult => {
      this.cartService.RemoveScheduleCartItem(cartItemId);
    });
  }

  public AddItemToCartResult(cartItem:any, cartItemResult:any, schedule:any){
    if(cartItemResult.IsSuccess)
    {
      cartItem.Id = cartItemResult.Object.Id;
      this.cartService.AddScheduleCartItem(cartItem);

      const elementList = document.querySelectorAll('.shoppingCart');
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });

    }
    else{
      if(schedule != null){
        schedule.Selected = false;
      }

      this.toastr.error(cartItemResult.Message, 'Indisponível', {
        toastClass: "border-red ngx-toastr",
        timeOut: 3000
      });

    }
  }

  toastRef:any;

  public getDateInISO_8601 = (): string => {
    const date = new Date();
    const year = '' + date.getFullYear();
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
  
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  };

  getCompanyServices(){
    this.agendaJaClient.GetServices(this.idCompany)
        .subscribe(servicesResult => {
          this.services = servicesResult;

          this.currentImageServicePath = this.services.find(k => k.Id == this.idService)?.Path ?? '';
        });
  } 

  getCompanyInfo(){
    this.agendaJaClient.GetCompany(this.idCompany)
    .subscribe(companyResult => {
      this.company = companyResult;
    });
  }
  
}
