import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { CompanyInfo } from 'src/app/models/company/company-info.model';
import { ServiceCatalog } from 'src/app/models/services/service-catalog';
import { InstagramEmbed } from 'src/app/models/social/instagram/embed.model';
import { environment } from 'src/environments/environment';
import {Title} from "@angular/platform-browser";
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class CompanyPageComponent implements OnInit {

  public rootPath:string=environment.Images.Root;
  public idCompany:number=0;
  public companyCode:string='';
  public company:CompanyInfo = new CompanyInfo();
  public services:ServiceCatalog[];
  public about: any;
  public instaPosts : InstagramEmbed[] = [];


  constructor(private route: ActivatedRoute,private agendaJaClient: AgendaJaHttpClient, public sanitizer: DomSanitizer
    , private titleService:Title, private router: Router, private activatedRoute: ActivatedRoute
  ) { 

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.idCompany = Number(routeParams.get('companyId'));
    this.companyCode = String(routeParams.get('companyCode'));

    this.getCompanyInfo();
    this.getCompanyServices();
    
    window.scrollTo(0, 0);
  }

  async getCompanyInfo(){

    if(this.idCompany > 0){
      await this.agendaJaClient.GetCompany(this.idCompany)
      .subscribe(companyResult => {
        this.company = companyResult;
        this.titleService.setTitle("Agenda Já - " + this.company.Name);
      });
    }
    else if(this.companyCode != "") {
      this.agendaJaClient.GetCompanyByCode(this.companyCode)
      .subscribe(companyResult => {
        this.company = companyResult;
        this.about = this.sanitizer.bypassSecurityTrustHtml(companyResult.Observations);
        this.titleService.setTitle("Agenda Já - " + this.company.Name);
      });
    }

  }

  getCompanyServices(){
    if(this.idCompany > 0){
      this.agendaJaClient.GetServices(this.idCompany)
          .subscribe(servicesResult => {
            this.services = servicesResult;

            this.loadInstagramEmbed();
          });
    }
    else{
      this.agendaJaClient.GetServicesByCompanyCode(this.companyCode)
          .subscribe(servicesResult => {
            this.services = servicesResult;

            this.loadInstagramEmbed();
          });
    }
  } 

  loadInstagramEmbed(){

    // if(this.idCompany > 0){
    //   this.agendaJaClient.GetInstagramEmbed(this.idCompany)
    //   .subscribe(posts => {
    //     this.instaPosts = posts;
    //   });
    // }
    // else{
    //   if(this.services.length > 0){
    //     this.agendaJaClient.GetInstagramEmbed(this.services[0].IdCompany)
    //     .subscribe(posts => {
    //       this.instaPosts = posts;
    //     });
    //   }
    // }

  } 



}