import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { BaseSession, Session } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-authentication-dialog',
  templateUrl: './authentication-dialog.component.html',
  styleUrls: ['./authentication-dialog.component.scss']
})
export class AuthenticationDialogComponent implements OnInit {

  constructor(private router: Router, private dialogRef: MatDialogRef<AuthenticationDialogComponent>,
    private agendajaClient: AgendaJaHttpClient, private sessionService: SessionService,
    public sidenavService: SidenavService) { }

    
  public UserSession: BaseSession | null = null;
  public username: string;
  public password: string; 
  public showLoading: boolean = false;
  public loginErrorEmail: boolean = false;

  ngOnInit(): void {
  }

  public Login() {
    this.showLoading = true;
    this.agendajaClient.Login(this.username, this.password)
                      .subscribe(session => this.setSession(session));
  }

  public Register() {
    this.dialogRef.close();
    this.router.navigate(['/register']);
  }

  public Recovery() {
    this.dialogRef.close();
    this.sidenavService.toggle();
    this.router.navigate(['/recovery']);
  }

  private setSession(session: Session|null){
    if(session != null && session != undefined){
      this.loginErrorEmail = false;
      this.UserSession = session;
      this.sessionService.StoreLocalSession(session);
      this.dialogRef.close(); 

      if(this.router.url == "/register"){
        this.router.navigate(['/']);        
      }

    }
    else{
      this.loginErrorEmail = true;
    }

    this.showLoading = false;
  }

  public Close() {
    this.dialogRef.close(); 
  }

}
