<app-user-header></app-user-header>


<div class="container-sm" style="min-height: 70vh;">

    <div class="row">
        <div class="col-12 personal-head-title">
            <h4>Carteira</h4>
        </div>
    </div>

    <div class="row" style="max-width: 800px;margin: 0 auto;margin: 0 auto;text-align: center;">
        <div class="col-xs-12">
            <div style="padding: 10px;">
                <span class="wallet-balance-label">Saldo</span>
            </div>
            <div style="padding: 5px;">
                <span class="wallet-balance">{{ UserSession?.Wallet?.Balance | currency:'EUR' }}</span>
            </div>
        </div>      
    </div>

    <div class="row" style="max-width: 800px;margin: 0 auto;margin-top: 20px;">
        <div class="col-xs-12 col-sm-6">
            <div>
                <div class="action-row">
                    <button mat-stroked-button class="action-button" routerLink="/user/refunds" >Pedir Reembolso</button>    
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6">
            <div>
                <div class="action-row">
                    <button mat-flat-button color="primary" class="action-button" (click)="openDialogWallet()">Carregar Carteira</button>    
                </div>
            </div>
        </div>
    </div>

</div>  