<div class="container" style="min-height: 40vh; margin-top: 30px;">
    <h4>Para Homens</h4>

    <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6">

            <div class="row" style="max-width: 800px;margin-top: 20px;">
                <div class="col-6">
                    <img class="img-news" src="https://1.bp.blogspot.com/-B70Sl3pycYA/XeFgL76LNSI/AAAAAAABilc/cPEMZqkUgc4thmGG9EjlelxUF2JJRRVwgCLcBGAsYHQ/s1600/beleza-masculina.jpg"
                    alt="Barbearia"
                    >
                </div>
                <div class="col-6">
                    <h4>Penteados e Moda</h4>
                    <span class="induction">Quem disse que menos é mais estava claramente falando sobre o corte de cabelo raspado. O estilo raspado é uma declaração ousada que tem dominado a cena da moda masculina nos últimos anos. </span>
                </div>
            </div>
        
          </div>
          <div class="col-xs-12 col-sm-6">
            
            <div class="row" style="max-width: 800px;margin-top: 20px;">
                <div class="col-6" >
                    <img class="img-news" src="https://cdn.shopify.com/s/files/1/0249/6102/4078/files/tipos-de-moicano-disfarcado.png" style="height: 170px;"
                    alt="Penteado masculino"
                    >
                </div>
                <div class="col-6">
                    <h4>Corte de cabelo moicano</h4>
                    <span class="induction">Aqui você vai definir qual é o melhor corte de cabelo moicano para o seu estilo.
                        Você pode ser super ousado, deixando apenas uma faixa fina de cabelo no topo, ou pode optar por algo mais discreto, cortando apenas o equivalente a dois dedos nas laterais. Vai do seu gosto.</span>
                        </div>
            </div>
        
          </div>
        </div>
    </div>

</div>