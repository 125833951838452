import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';


export interface PeriodicElement {
  name: string;
  free: boolean;
  plus: boolean;
  mega: boolean;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Gestão de Agenda', free: true, plus: true, mega: true},
  {name: 'Gestão de Serviços', free: true, plus: true, mega: true},
  {name: 'Gestão de Clientes', free: true, plus: true, mega: true},
  {name: 'Pagamentos Online (opcional)', free: false, plus: true, mega: true},
  {name: 'Notificações Email', free: true, plus: true, mega: true},
  {name: 'Notificações SMS', free: false, plus: true, mega: true},
  {name: 'Configuração de horário', free: true, plus: true, mega: true},
  {name: 'Sem limite de funcionários', free: false, plus: true, mega: true},
  {name: 'Sem limite de Serviços', free: false, plus: true, mega: true},
  {name: 'Políticas de cancelamento', free: false, plus: true, mega: true},
  {name: 'Oferta módulo de Faturação', free: false, plus: true, mega: true},
];

@Component({
  selector: 'app-business-page',
  templateUrl: './business-page.component.html',
  styleUrls: ['./business-page.component.scss']
})


export class BusinessPageComponent implements OnInit {

  displayedColumns: string[] = ['name', 'free', 'plus', 'mega'];
  dataSource = ELEMENT_DATA;

  constructor(private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Agenda Já - Empresas");
  }

}
