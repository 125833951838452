<div class="container">

    <h3>Empresas</h3>


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="free">
            <th mat-header-cell *matHeaderCellDef> 
                <span class="header-plan">Grátis</span>
                <br>
                <span class="amount-value">€0</span><span></span>
             </th>

            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.free" class="material-symbols-outlined" style="color: green;">
                    done
                </span>

                <span *ngIf="!element.free" class="material-symbols-outlined" style="color: red;">
                    close
                </span>
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="plus">
            <th mat-header-cell *matHeaderCellDef> 
                <span class="header-plan">Plus</span>
                <br>
                <span class="amount-value">€0.20</span><span class="amount-pertype">/agendamento</span>
            </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.plus" class="material-symbols-outlined" style="color: green;">
                    done
                </span>

                <span *ngIf="!element.plus" class="material-symbols-outlined" style="color: red;">
                    close
                </span>
            </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="mega">
            <th mat-header-cell *matHeaderCellDef> 
                <span class="header-plan">Mega</span>
                <br>
                <span class="amount-value">€50</span><span class="amount-pertype">/mês</span>
            </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.mega" class="material-symbols-outlined" style="color: green;">
                    done
                </span>

                <span *ngIf="!element.mega" class="material-symbols-outlined" style="color: red;">
                    close
                </span> 
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>