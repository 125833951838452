export class ScheduleBlock {
    IdService: number;
    Hour: string;
    HourEnd: string;
    PriceWithoutDiscount: number;
    HasDiscount: boolean;
    DiscountPercentage: number;
    DiscountValue: number;
    PriceWithDiscount: number;
    StorePrice: number;
    IsAvailable: boolean;
    ScheduledByUser: boolean;
    UserHasInterest: boolean;
    TaxPercentage: number;
}

export class ScheduleBlockViewModel extends ScheduleBlock{
    Selected: boolean;
}