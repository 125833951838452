<!-- <div style="height: 60px;"></div>

<p>Página não encontrada! :( </p> -->

<div class="container">
    <div class="content-not-found">

        <div>
            <span class="material-symbols-outlined icon-search">
                search
            </span>    
        </div>
    
        <div class="message">
            <p>A página que procura não foi encontrada!</p>
        </div>

        <div class="actions">
            <button mat-raised-button color="" routerLink="/">Voltar ao Início</button>
        </div>

    </div>    
    
</div>



