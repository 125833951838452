import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { PaymentResult } from 'src/app/models/wallet/pay-result.model';
import { PayWallet } from 'src/app/models/wallet/pay-wallet.module';

@Component({
  selector: 'app-pay-wallet-dialog',
  templateUrl: './pay-wallet-dialog.component.html',
  styleUrls: ['./pay-wallet-dialog.component.scss']
})
export class PayWalletDialogComponent implements OnInit {
  phoneForm: FormGroup;

  public value:number=0;
  public autoAmount: number = 0;
  public paymentType:number = 0;
  public mbwayPhone: string = "";
  public requestStep:number = 1;
  public requestSuccess:boolean;
  public mbResponse: PaymentResult;

  constructor(private agendaJaClient: AgendaJaHttpClient, private dialogRef: MatDialogRef<PayWalletDialogComponent>) {
   }

  ngOnInit(): void {
    this.phoneForm = new FormGroup({
      PhoneNumber:new FormControl('',[Validators.pattern('^\\d{9}$'), Validators.required]),
    });
  }

  payWallet(){
    if(this.value > 500){
      alert("Só pode carregar a sua carteira no máximo com 500€.");
      return;
    }

    let request: PayWallet = new PayWallet(this.value, (this.paymentType == 1 ? "mbw" : "mb"), (this.paymentType == 1 ? this.mbwayPhone : ""));
    this.requestStep = 2;

    this.agendaJaClient.PayWallet(request)
    .subscribe(
      {
        next: (result) => {
          
          if(result.Success){
            this.requestSuccess = true;
            this.requestStep = 3;
            this.mbResponse = result;
          }
          else {
            this.requestSuccess = false;
            this.requestStep = 1;
            alert("Pedimos desculpa, mas não foi possível gerar o pagamento.");
          }

        },
        error: (err: any) => {
          alert("Pedimos desculpa, mas não foi possível gerar o pagamento.");
          this.requestStep = 1;
          this.requestSuccess = false;
        }
      }
    )
  }

  setAmount(amount:number){
    this.value = amount;
    this.autoAmount = amount;
  }

  onChange($event:any){
    this.autoAmount = 0;
  }

  setPaymentType(value:number){
    this.paymentType = value;
  }

  close(){
    this.dialogRef.close(); 
  }

  matcher = new MyErrorStateMatcher();

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}
