<div class="container container-registration">
    
    <div *ngIf="_requesting">
        <mat-spinner style="margin: 0 auto;"></mat-spinner>    
    </div>

    <div class="row"  *ngIf="!_requesting">
        <h3 class="agendaja-title">Resgista-te e agenda já!</h3>

        <!-- <mat-error *ngIf="userAlreadyExists">
            <p>Já existe um utilizador com estes dados. Por favor, faça recuperar password e siga as instruções.</p>
            <div class="action-row">
                <button mat-button color="primary" class="forget-password-button" (click)="Recovery()">Recuperar password</button>
            </div>    
        </mat-error> -->


        <mat-stepper orientation="vertical" [linear]="false" #stepper>
            <mat-step [stepControl]="personalDataFormGroup">
                <form [formGroup]="personalDataFormGroup">
                    <ng-template matStepLabel>Dados Pessoais</ng-template>

                    <mat-form-field class="form-full-width">
                        <mat-label>Género</mat-label>
                        <mat-select formControlName="genreNameCtrl" id="genre" [(ngModel)]="_userData.IdGenre" placeholder="Senhor/Senhora" required>
                            <mat-option value="1">Senhor</mat-option>
                            <mat-option value="2">Senhora</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>

                    <mat-form-field class="form-full-width">
                        <mat-label>Nome</mat-label>
                        <input matInput placeholder="Nome" id="name" formControlName="firstNameCtrl" required
                            [(ngModel)]="_userData.FirstName">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="form-full-width">
                        <mat-label>Apelido</mat-label>
                        <input matInput placeholder="Apelido" formControlName="lastNameCtrl" required
                            [(ngModel)]="_userData.LastName">
                    </mat-form-field>
                    <br>

                    <mat-form-field class="form-full-width">
                        <mat-label>Data Nascimento</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="birthDateCtrl" required
                            [(ngModel)]="_userData.Birthdate" style="width: 100px;">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <br>
                    <br>
                    <mat-form-field class="form-full-width">
                        <mat-label>NIF</mat-label>
                        <input matInput placeholder="NIF" formControlName="taxIdCtrl" required
                            [(ngModel)]="_userData.TaxId">
                    </mat-form-field>

                    <div class="stepper-actions">
                        <button mat-button mat-flat-button color="primary" matStepperNext class="position-right">Seguinte</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="addressFormGroup" label="Morada">
                <form [formGroup]="addressFormGroup">
                    <mat-form-field  class="form-full-width">
                        <mat-label>Morada</mat-label>
                        <input matInput formControlName="addressCtrl" placeholder="Ex. Av. Brasília, Lisboa" required
                            [(ngModel)]="_userData.Address">
                    </mat-form-field>
                    <br>
                    <mat-form-field  class="form-full-width">
                        <mat-label>Código Postal</mat-label>
                        <input matInput formControlName="zipCodeCtrl" placeholder="1400" required
                            [(ngModel)]="_userData.ZipCode">
                    </mat-form-field>
                    <mat-form-field  class="form-full-width">
                        <mat-label>Extensão</mat-label>
                        <input matInput formControlName="zipCodeExtCtrl" placeholder="300" required
                            [(ngModel)]="_userData.ZipCodeExt">
                    </mat-form-field>

                    <div class="stepper-actions">
                        <button mat-button mat-flat-button color="primary" matStepperNext class="position-right">Seguinte</button>
                        <button mat-button color="primary" matStepperPrevious class="position-right">Voltar</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="completeFormGroup" label="Confirmação">
                <form [formGroup]="completeFormGroup">
                    <mat-form-field  class="form-full-width">
                        <mat-label>Telemóvel</mat-label>
                        <input matInput placeholder="Telemóvel" formControlName="phoneCtrl" required
                            [(ngModel)]="_userData.PhoneNumber">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="form-full-width">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" formControlName="emailCtrl" required
                            [(ngModel)]="_userData.Email">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="form-full-width">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" placeholder="Password" formControlName="passwordCtrl" required
                            [(ngModel)]="_userData.Password">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="form-full-width">
                        <mat-label>Confirmar Password</mat-label>
                        <input matInput type="password" placeholder="Confirmação Password"
                            formControlName="passwordConfirmationCtrl" required [(ngModel)]="passwordConfirmation">
                    </mat-form-field>
                    <br>
                    <div>
                        <small class="text-danger" *ngIf="
                                    (
                                        completeFormGroup.get('passwordCtrl')?.dirty || completeFormGroup.get('passwordCtrl')?.touched
                                     || completeFormGroup.get('passwordConfirmation')?.dirty || completeFormGroup.get('passwordConfirmation')?.touched
                                    )
                                    &&
                                    completeFormGroup.hasError('match_error')">
                            As passwords introduzidas não são iguais.
                        </small>
                    </div>

                </form>

                <div class="notificaitons-wrapper">
                    <mat-checkbox [(ngModel)]="_userData.SMSNotification">
                        Quero receber notificações via SMS
                    </mat-checkbox>
                </div>

                <div class="notifications-wrapper">
                    <mat-checkbox [(ngModel)]="_userData.EmailNotification">
                        Quero receber notificações via E-mail</mat-checkbox>
                </div>

                <div class="stepper-actions">
                    <button mat-button mat-flat-button class="complete-registration position-right" (click)="Register()">Confirmar
                        Registo</button>
                        <button mat-button color="primary" matStepperPrevious  class="position-right">Voltar</button>
                    </div>

            </mat-step>
        </mat-stepper>

    </div>
</div>