<div class="container" style="min-height: 70vh; margin-top: 40px;">

    <div style="text-align: center; min-height: 40vh;">
        <div style="height: 100px;">
            <span class="material-symbols-outlined" style="color: #2edc5a;
            scale: 5;">
                check_circle
            </span>            
        </div>
        <div>
            <h4>Checkout efetuado com sucesso!</h4>
            <span>O código do agendamento é: {{code}}</span>
        </div>
    </div>

    <div class="row">
        <button mat-raised-button color="primary" (click)="goto('agenda')">A minha agenda</button>                    
        <button mat-raised-button color="basic" style="margin-top: 10px;" (click)="goto('home')">Início</button>            

    </div>


    <div class="row" style="margin-top: 40px;margin-bottom: 40px;">
        <div class="col-xs-12  col-sm-5 tip-container">
            <h5>O que é o código do agendamento?</h5>
            <span>De modo a confirmar os seus agendamentos nos estabelecimentos, deve facultar o código do agendamento caso seja requisitado.</span>
        </div>
        <div class="col-2">
            
        </div>
        <div class="col-xs-12 col-sm-5 tip-container">
            <h5>Posso cancelar os meus agendamentos?</h5>
            <span>É possivel cancelar os agendamentos a qualquer altura desde que sejam cumpridas as políticas de cancelamento de cada serviço.</span>
        </div>

    </div>
</div>
