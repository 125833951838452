import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { RefundRequest } from 'src/app/models/refunds/refund-request.model';
import { RefundRequestDialogComponent } from '../../dialogs/refund-request-dialog/refund-request-dialog.component';
import { BaseSession } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';
import { RefundRequestMessageInput } from 'src/app/models/refunds/refund-request-mssage-input.model';
import { RefundRequestMessage } from 'src/app/models/refunds/refund-request-message.model';

@Component({
  selector: 'app-user-refunds',
  templateUrl: './user-refunds.component.html',
  styleUrls: ['./user-refunds.component.scss']
})
export class UserRefundsComponent implements OnInit {

  public refundRequests: RefundRequest[] = [];
  public profilePicPath: string|null = '';
  public UserSession: BaseSession | null = null;

  constructor(private agendajaClient: AgendaJaHttpClient, public dialog: MatDialog, private sessionService: SessionService) { }

  ngOnInit(): void {
    this.getRefunds();
    this.UserSession = this.sessionService.Get();
    this.profilePicPath = this.UserSession?.ProfilePicPath ?? '';
  }

  getRefunds(){
    this.agendajaClient.GetRefunds()
    .subscribe({
      next: (refunds:RefundRequest[]) => {
        this.refundRequests = refunds;
      },
      error: (error:any) => {
        this.refundRequests = [];
      },
    });
  }

  createNewRequest(){
    const dialogRef = this.dialog.open(RefundRequestDialogComponent,
    {
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getRefunds();
      }
    });
  }

  sendNewMessage(refund: RefundRequest){

    let request = new RefundRequestMessageInput();
    request.IdRefundRequest = refund.Id;
    request.Message = refund.NewMessageToSend;

    if((request.Message ?? "").trim()?.length > 5)
    {     
      this.agendajaClient.CreateRefundRequestMessage(request)
      .subscribe({
        next: (refundMessage:RefundRequestMessage) => {
          refund.Messages.push(refundMessage);
          refund.NewMessageToSend = '';
        },
        error: (error:any) => {
          alert('Não foi possível enviar a mensagem!');
        },
      })
       
    }

  }
}
