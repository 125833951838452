<div class="head-login-modal">
    <h4>Cancelar Agendamento</h4>
</div>


<div *ngIf="showLoading" class="loading-spinner">
    <mat-spinner style="margin: 0 auto;"></mat-spinner>
</div>

<div *ngIf="cancellationPolicy != null && !showLoading">
    <div>
        <span>A seguinte política de cancelamento será aplicada:</span><br>
        <p style="font-weight: 600;">{{ cancellationPolicy.Description }}</p>
        <p>O restante valor será creditado na sua conta AgendaJá.</p>
    </div>
    <div class="actions">
        <div class="action-button-wrapper">
            <button mat-stroked-button (click)="close()">Voltar</button>
        </div>
        <div class="action-button-wrapper">
            <button mat-raised-button color="primary" (click)="cancel()">Cancelar</button>
        </div>
    </div>
</div>

<div *ngIf="cancellationPolicy == null && !showLoading">
    <div>
        <span>Tem a certeza que quer efetuar o cancelamento?</span><br>
        <p>Não existe qualquer política de cancelamento a ser aplicada.</p>
    </div>
    <div class="actions">
        <div class="action-button-wrapper">
            <button mat-stroked-button>Voltar</button>
        </div>
        <div class="action-button-wrapper">
            <button mat-raised-button color="primary" (click)="cancel()">Cancelar</button>
        </div>
    </div>
</div>

