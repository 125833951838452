
<div>
    <p class="cart-item-header">Carrinho</p>
    <mat-divider></mat-divider>

    <div *ngIf="CartItems.length == 0">
        <p style="margin-top: 10px;">O carrinho está vazio.</p>
    </div>

    <div class="container" *ngIf="CartItems.length > 0">

        <div class="row cart-item" *ngFor="let cartitem of CartItems;">

            <div class="col-10" style="padding: 0;">
                <span class="cart-item-service-name">{{ cartitem.ServiceName }}</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    calendar_today
                </span>    
                <span class="cart-item-service-date">{{ cartitem.Day }}</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    schedule
                </span>    
                <span class="cart-item-service-hour">{{ cartitem.Hour }}h</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    storefront
                </span>    
                <span class="cart-item-service-company">{{ cartitem.CompanyName }}</span>
            </div>

            <div class="col-2" style="position: relative; padding: 0;">
                <span class="cart-item-service-price">{{ cartitem.Price | currency:'EUR' }}</span>
                <br>
                <button mat-icon-button (click)="deleteFromCart(cartitem.Id)" class="remove-cart-item" >
                    <mat-icon style="transform: scale(0.7); color: #7e7e7e">delete</mat-icon>
                </button>
            </div>  
        </div>

        <div class="row total-summary">
            <div class="col-4 total-lable">
                <span>Total</span>
            </div>
            <div class="col-8 total-value">
                <span>{{ GetShoppingCartTotal() | currency:'EUR' }}</span>                
            </div>
        </div>

        <div class="row">
            <button mat-raised-button class="checkout-button" (click)="gotoCheckout()">Checkout</button>
        </div>
    </div>

</div>
