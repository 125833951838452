import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSession } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {

  public UserSession: BaseSession | null = null;
  public selectedMenu: number = 1;

  constructor(private sessionService: SessionService, private router: Router) { }

  ngOnInit(): void {
    this.UserSession = this.sessionService.Get();

    if(this.UserSession == null || this.UserSession.IsAnonymous){
      this.router.navigate(['/']);
    }
  }

  public changeMenu(menu:number){
    this.selectedMenu = menu;
  }

}
