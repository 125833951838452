import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem } from 'src/app/models/shopping-cart/shopping-cart-item.model';
import { CartService } from 'src/app/services/cart.service';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-shopping-cart-badge',
  templateUrl: './shopping-cart-badge.component.html',
  styleUrls: ['./shopping-cart-badge.component.scss']
})
export class ShoppingCartBadgeComponent implements OnInit {

  CartItems: CartItem[] = [];

  constructor(private cartService: CartService, private router: Router,
    public sidenavService: SidenavService) {
    this.cartService.watchCartItems().subscribe((data:CartItem[]) => {
      this.CartItems = data;
    });

  }

  ngOnInit(): void {
    this.CartItems = this.cartService.GetScheduleCartItems();
  }

  public gotoCheckout(){
    this.router.navigate([`/checkout`]);
    this.sidenavService.toggle();
  }

}
