<div class="container" style="min-height: 40vh;margin-top: 30px;">
    <h4>Opinião dos clientes</h4>

    <carousel *ngIf="isMobileLayout" style="height: 175px; margin-top: 40px;">
        <div class="carousel-cell">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/3.jpg" class="img_user"/>
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Marco Moreira</p>
                        <p class="dateOpinion">Jan 2023</p>
                    </div>
                </div>
            
                <div class="col-xs-12 opinion">
                    <p class="opinionText">Ótimos serviços a excelentes preços!</p>
                </div>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/1.png" class="img_user" />
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Adelaide Silva</p>
                        <p class="dateOpinion">Fev 2023</p>
                    </div>
                </div>
                <div class="col-xs-12 opinion">
                    <p class="opinionText">É incrível a facilidade como se agenda!</p>
                </div>
            </div>
        </div>
        <div class="carousel-cell">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/2.jpg" class="img_user" />
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Ana Sousa</p>
                        <p class="dateOpinion">Jan 2023</p>
                    </div>
                </div>
                <div class="col-xs-12 opinion">
                    <p class="opinionText">De mais! Agora consigo fazer as minhas marcações sem ter de ligar inúmeras vezes!</p>
                </div>
            </div>
        </div>
    </carousel>
    
    <div *ngIf="!isMobileLayout" class="row" style="height: 175px; margin-top: 40px;">
        <div class="col-xs-12 col-sm-4">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/3.jpg" class="img_user"/>
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Marco Moreira</p>
                        <p class="dateOpinion">Jan 2023</p>
                    </div>
                </div>
            
                <div class="col-xs-12 opinion">
                    <p class="opinionText">Ótimos serviços a excelentes preços!</p>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-4">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/1.png" class="img_user" />
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Adelaide Silva</p>
                        <p class="dateOpinion">Fev 2023</p>
                    </div>
                </div>
                <div class="col-xs-12 opinion">
                    <p class="opinionText">É incrível a facilidade como se agenda!</p>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-4">
            <div class="opinionWrapper">
                <div class="opinionBlockHeader">
                    <div class="col-xs-2 col-sm-3 opinionUserPic">
                        <img src="../../../assets/images/cuustomersFeedback/2.jpg" class="img_user" />
                    </div>
                    <div class="col-xs-10 col-sm-9 opinionUserInfo">
                        <p class="userName">Ana Sousa</p>
                        <p class="dateOpinion">Jan 2023</p>
                    </div>
                </div>
                <div class="col-xs-12 opinion">
                    <p class="opinionText">De mais! Agora consigo fazer as minhas marcações sem ter de ligar inúmeras vezes!</p>
                </div>
            </div>
        </div>
    </div>
</div>