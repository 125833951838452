import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { firstValueFrom } from 'rxjs';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { CancellationPolicy } from 'src/app/models/services/service-detail.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-schedule-cancellation-dialog',
  templateUrl: './schedule-cancellation-dialog.component.html',
  styleUrls: ['./schedule-cancellation-dialog.component.scss']
})
export class ScheduleCancellationDialogComponent implements OnInit {

  public idSchedule: number;
  public showLoading: boolean = true;
  public cancellationPolicy: CancellationPolicy;

  constructor(private agendajaClient: AgendaJaHttpClient, private dialogRef: MatDialogRef<ScheduleCancellationDialogComponent>,
     private sessionService: SessionService) { }

  ngOnInit(): void {
    this.getScheduleCancellationPolicy();
  }

  public getScheduleCancellationPolicy(){
    this.agendajaClient.GetSchedulesCancellationPolicy(this.idSchedule)
      .subscribe(
        {
          next: (cancellationPolicy) => {
            this.cancellationPolicy = cancellationPolicy;
            this.showLoading = false;
          },
          error: (err: any) => {
            alert('Não foi possivel carregar os seus dados.');
          }
        }
      );
  }

  public cancel(){
    this.showLoading = true;

    this.agendajaClient.CancelSchedule(this.idSchedule)
    .subscribe(
      {
        next: async (cancelResult) => {
          this.showLoading = false;
          if(cancelResult){
            const session = await firstValueFrom(this.agendajaClient.SessionWalletRefresh());     
            this.sessionService.StoreLocalSession(session);  
            this.close();
            alert("Cancelado com sucesso!");
          }
          else{
            alert("Nao foi cancelado!");
          }
        },
        error: (err: any) => {
          alert('Não foi possivel carregar os seus dados.');
        }
      }
    );
  }

  close(){
    this.dialogRef.close(); 
  }
}
