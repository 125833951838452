export class UserInfo {
    FirstName: string;
    LastName: string;
    BirthDate: string;
    TaxId: string;
    Address: string;
    ZipCode: string;
    ZipCodeExt: string;
    PhoneNumber: string;
    Email: string;
    SMSNotification: boolean;
    EmailNotification: boolean;
    IdUserType: number;
    IdGenre: number;
    ProfilePicPath: string;

    constructor(){}
}