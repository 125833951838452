import { Component, Input, OnInit } from '@angular/core';
import { ServiceCatalog } from 'src/app/models/services/service-catalog';

@Component({
  selector: 'app-services-grid',
  templateUrl: './services-grid.component.html',
  styleUrls: ['./services-grid.component.scss']
})
export class ServicesGridComponent implements OnInit {

  @Input() services:ServiceCatalog[]=[];
  public totalItems:string = "0";
  public breakpoint: number;

  constructor() { }

  ngOnInit(): void {
    this.resizeGrid(window.innerWidth);
  }

  onResize(event:any) {
    this.resizeGrid(event.target.innerWidth);
  }

  private resizeGrid(size:number){
    // if(size < 500)
    //   this.breakpoint = 2;
    // else if(size >=  500 && size <= 800)
    //   this.breakpoint = 2;
    // else if(size > 800 && size <= 1000)
    //   this.breakpoint = 3;
    // else
    //   this.breakpoint = 5;

    if(size <= 800)
      this.breakpoint = 2;
    else if(size > 800 && size <= 1000)
      this.breakpoint = 3;
    else
      this.breakpoint = 5;

  }


}
