<div class="container" style="min-height: 30vh; margin-top: 30px;">
    <h4>Beleza</h4>

    <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6">

            <div class="row" style="max-width: 800px;margin-top: 20px;">
                <div class="col-6">
                    <img class="img-news" src="https://res.cloudinary.com/beleza-na-web/image/upload/f_auto,fl_progressive,q_auto:eco/loucas/wordpress/prod/sites/1/2023/12/11160953/blz_conteudo_loucas_2023_dezembro_mitos-e-verdades-sobre-cosmeticos-investigados-por-um-dermatologista-550x250.jpg"
                    alt="Cosmética"
                    >
                </div>
                <div class="col-6">
                    <h4>Cosméticos: Mitos e Verdades</h4>
                    <span class="induction">A dúvida sobre os efeitos dos produtos de skincare, quando usar ou não e se realmente funcionam, ainda é bastante frequente.</span>
                </div>
            </div>
        
          </div>
          <div class="col-xs-12 col-sm-6">
            
            <div class="row" style="max-width: 800px;margin-top: 20px;">
                <div class="col-6" >
                    <img class="img-news" src="https://res.cloudinary.com/beleza-na-web/image/upload/f_auto,fl_progressive,q_auto:eco/loucas/wordpress/prod/sites/1/2022/12/08144028/blz_conteudo_loucas_2023_dezembro_escolha-o-seu-perfume-para-as-festas-de-fim-de-ano.jpg"
                    alt="Beleza e Cabeleireiros"
                    >
                </div>
                <div class="col-6">
                    <h4>Perfumes para festas</h4>
                    <span class="induction">Dezembro é um mês de muitas celebrações, e quem não gosta de caprichar no penteado, na maquiagem e no look, não é mesmo? Mas, nenhuma produção está completa sem o perfume certo para a ocasião! Vem ver as dicas!</span>
                </div>
            </div>
        
          </div>
        </div>
    </div>

</div>