<!-- <div class="footer">
    <div class="row" style="width: 100%;">
        <div class="col-xs-12 col-sm-4 footer-logo">
            <img src="https://agendaja.pt/assets/images/footer/layer-540.png" />
        </div>
        <div class="col-xs-12 col-sm-4 footer-links">
            <div class="footer-links-child" (click)="goTo('home')">
                <span>Início</span>
            </div>
            <div class="footer-links-child footer-links-child-pipe">
                <span></span>
            </div>
            <div class="footer-links-child" (click)="goTo('business')">
                <span>Empresas</span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-4 footer-social">
            <img src="https://agendaja.pt/assets/images/footer/facebook-icon.png" class="Facebook-Icon">
        </div>
    </div>

    <div class="row footer-termslinks" style="width: 100%; padding-bottom: 30px; color: gray;">
        <div class="col-xs-12 col-sm-6" style="text-align: right;">
            <span (click)="goTo('policy')">Políticas de Privacidade</span>
        </div>
        <div class="col-xs-12 col-sm-6">
            <span (click)="goTo('terms')">Termos e Condições</span>            
        </div>
    </div>

</div>
 -->

 <footer  class=" footer text-center text-lg-start bg-light text-muted" >
    <!-- Section: Social media -->
    <section style="background-color: rgba(0, 0, 0, 0.05);"
      class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
    >
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Segue-nos nas redes sociais</span>
      </div>
      <!-- Left -->
  
      <!-- Right -->
      <div>
        <a href="https://www.instagram.com/agenda.ja/" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <!-- <a href="" class="me-4 text-reset">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-google"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-github"></i>
        </a> -->
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->
  
    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              Agenda Já
            </h6>
            <p>
              <button mat-stroked-button color="primary" (click)="installApp()">Instalar App</button>
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Ligações
            </h6>
            <p>
              <a href="https://agendaja.pt" class="text-reset">Agenda Já</a>
            </p>
            <p>
              <a href="https://agendaja.pt/#/business" class="text-reset">Empresas e Prestadores de Serviços</a>
            </p>
            <p>
              <a href="https://agendaja.pt/#/terms" class="text-reset">Termos e Condições</a>
            </p>
            <p>
              <a href="https://agendaja.pt/#/policies" class="text-reset">Política de Privacidade</a>
            </p>
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Contactos
            </h6>
            <p><i class="fas fa-phone me-3"></i> +351 915 222 385</p>
            <p><i class="fas fa-envelop me-3"></i> geral@agendaja.pt</p>
            <div>
              <a aria-label="Chat on WhatsApp" href="https://wa.me/+351915222385" style="margin-left: 15px;margin-top: 10px;">
                <img alt="Chat on WhatsApp" src="../../../assets/images/wazapp_ed.png" width="110px" />
              </a>  
            </div>

          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->
  
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2024 Copyright:
      <a class="text-reset fw-bold" href="https://agendaja.pt">Agenda Já</a>
    </div>
    <!-- Copyright -->
  </footer>