<div class="toolbar" role="banner">
    <div class="row toolbar-inner">
        <div class="col toolbar-header-logo">
            <a href="/">
                <img alt="Agenda Já" src="/assets/images/header/logo_agendaja.jpg" />
            </a>
        </div>
        <div class="col toolbar-header-session no-padding-right">
            <div class="">
                <div class="col-xs-12 col-sm-6 no-padding-right" style="text-align: right;
                float: right;
                margin-right: 20px;">
                    <div class="row no-padding-right">
                        <!-- <app-session></app-session> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
