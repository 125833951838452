import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { startCheckout } from '@easypaypt/checkout-sdk'
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-checkout-pay-page',
  templateUrl: './checkout-pay-page.component.html',
  styleUrls: ['./checkout-pay-page.component.scss']
})
export class CheckoutPayPageComponent implements OnInit {

  public loading: boolean = true;

  constructor(private agendaJaClient: AgendaJaHttpClient, private cartService: CartService, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    const cartItems = this.cartService.GetScheduleCartItems();
    if(cartItems.length == 0)
    {
      this.router.navigate([`/checkout`]);  
    }
    else{
      this.CheckoutExternal();
    }


  }

  public CheckoutExternal(){

    this.agendaJaClient.GetAutoCheckoutSession()
      .subscribe(
      {
          next: (manifest) => {
            let ob = {
              id: manifest.Id,
              session: manifest.Session,
              config: null
            };
            this.loading = false;

            startCheckout(ob, { id: 'easypay-checkout', testing: true
            , onSuccess: this.mySuccessHandler
            , onError: this.myErrorHandler
            , onClose: this.myCloseHandler
            , onPaymentError: this.myPaymentErrorHandler
          });
          },
          error: (err) => {
            alert('Nao foi possível obter o manifesto para checkout!');
          }
        }
      );
      
  }

  myPaymentErrorHandler(error:any){  
    alert('Não foi possível efetuar o pagamento.');
    
  }

  myCloseHandler(){
    //incomplete
    this.router.navigate([`/checkout/success`]);      
  }

  mySuccessHandler(checkoutSuccessInfo:any) {// There was a successful payment interaction// You can react to details in checkoutSuccessInfo
    // this.loading = false;
    alert("mySuccessHandler: "+ JSON.stringify(checkoutSuccessInfo));


  }

  myErrorHandler(checkoutErrorInfo:any) {// There was a successful payment interaction// You can react to details in checkoutSuccessInfo
    // this.loading = false;
     alert("myErrorHandler: ERROR "+ JSON.stringify(checkoutErrorInfo));
  }

}
