import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PwaService } from '../../services/pwa-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router, private pwaService: PwaService) { }

  ngOnInit(): void {
  }

  public goTo(page:string):void{

    switch(page){
      case 'home':
        this.router.navigate(['/']);
        break;
      case 'business':
        this.router.navigate(['/business']);
        break;
      case 'policy':
        this.router.navigate(['/policies']);
        break;
      case 'terms':
        this.router.navigate(['/terms']);
        break;
    }
  }

  installApp(){
    this.pwaService.initPwaPrompt(this.promptEvent);
  }

  private promptEvent: any;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e:any) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    this.promptEvent = e;
  }

}
