export class Employee {
    IdUser: number;
    FirstName: string;
    LastName: string;
    PicPath: string;
}

export class CancellationPolicy {
    Id: number;
    Description: string;
    Value: number;
    IsPercentage: boolean;
    MinutesToService: number;
}

export class ServiceDetail {
    
    IdCompany: number;
    CompanyName: string;
    IdService: number;
    ServiceName: string;
    ServiceDescription: string;
    DurationInMinutes:number;
    Price: number;
    StorePrice: number;
    IVA: number;
    HasDiscount: boolean;
    Discount: number|null;
    IsDiscountPercentage: boolean;
    OnlinePayment: boolean;
    FinalPrice: number;
    DiscountValue: number;
    Employees: Employee[];
    CancellationPolicies: CancellationPolicy[];

    Address: string;

    constructor() {
    }

}
