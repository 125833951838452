<div class="recover-head">
    <h2>Recuperação password</h2>
</div>


<div *ngIf="!showLoading" class="container" style="padding-top: 50px;padding-bottom: 50px;min-height: 60vh;">
    <div *ngIf="isTokenValid">
        <div style="margin-bottom: 10px;">
            <span>Confirmação de recuperação de password:</span>
        </div>
        <form [formGroup]="passwordFormGroup">
            <mat-form-field class="form-full-width">
                <mat-label>Nova password</mat-label>
                <input matInput type="password" placeholder="Nova password" formControlName="passwordCtrl" required
                    [(ngModel)]="newPassword">
            </mat-form-field>
            <br>
            <mat-form-field class="form-full-width">
                <mat-label>Confirmar nova password</mat-label>
                <input matInput type="password" placeholder="Confirmar nova password"
                    formControlName="passwordConfirmationCtrl" required [(ngModel)]="confirmNewPassword">
            </mat-form-field>
            <br>
            <div>
                <small class="text-danger" *ngIf="
                            (
                                passwordFormGroup.get('passwordCtrl')?.dirty || passwordFormGroup.get('passwordCtrl')?.touched
                            || passwordFormGroup.get('passwordConfirmation')?.dirty || passwordFormGroup.get('passwordConfirmation')?.touched
                            )
                            &&
                            passwordFormGroup.hasError('match_error')">
                    As passwords introduzidas não são iguais.
                </small>
            </div>
        </form>
        <div>
            <button mat-raised-button color="primary" [disabled]="!passwordFormGroup.valid" (click)="submitNewPassword()">Recuperar password</button>
        </div>
    </div>

    <div *ngIf="!isTokenValid">
        <div>
            <p>O token fornecido não é válido.</p>
        </div>
        <div>
            <button mat-raised-button color="primary" routerLink="/">Voltar</button>
        </div>
    </div>
</div>  

<div *ngIf="showLoading" class="loading-spinner" style="padding-top: 50px;padding-bottom: 50px;min-height: 60vh;">
    <mat-spinner style="margin: 0 auto; margin-top: 50px;"></mat-spinner>
</div>
