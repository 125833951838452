import { Component, OnInit, ViewChild } from '@angular/core';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { CompanyCard } from 'src/app/models/company/company-card.model';
import { Company } from 'src/app/models/company/company.model';


@Component({
  selector: 'app-companies-grid',
  templateUrl: './companies-grid.component.html',
  styleUrls: ['./companies-grid.component.scss']
})
export class CompaniesGridComponent implements OnInit {

  constructor(private agendaJaClient: AgendaJaHttpClient) { }
 
  public companies:CompanyCard[]=[];
  public totalItems:string = "0";
  public breakpoint: number;

  public showLoading: boolean = false;

  ngOnInit(): void {
    this.getCompanies();
  }

  private getCompanies(){
      this.showLoading = true;
      this.agendaJaClient.GetCompanies()
        .subscribe(companiesResult => {
          this.companies = companiesResult;
          this.totalItems = this.companies.length +'';
          this.resizeGrid(window.innerWidth);
          this.showLoading = false;
        });
  }

  onResize(event:any) {
    this.resizeGrid(event.target.innerWidth);
  }

  private resizeGrid(size:number){
    if(size <= 800)
      this.breakpoint = 2;
    else if(size > 800 && size <= 1000)
      this.breakpoint = 3;
    else
      this.breakpoint = 5;
  }

}
