import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ServiceCard } from 'src/app/models/services/service-card.model'
import { ServiceCatalog } from 'src/app/models/services/service-catalog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {

  @Input() service : ServiceCatalog;
  public rootPath:string=environment.Images.Root;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public getRoundedValue(num: number){
    var val = (Math.round(num * 100) / 100);
    return val.toFixed(2);
  }

  public getPrice(service:any){
    return this.getRoundedValue(service.Price * (1+(service.IVA / 100)));
  }

  public goTo(companyId: number, serviceId: number):void{
      this.router.navigate([`/company/${companyId}/service/${serviceId}`]);
  }
}
