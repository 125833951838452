import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import 'moment/locale/pt';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { UserRegister } from 'src/app/models/user/userregister.model';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { MatDialog } from '@angular/material/dialog';
import { ShowMessageDialogComponent } from 'src/app/components/dialogs/show-message-dialog/show-message-dialog.component';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { debug } from 'console';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, 
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] } 
  ]
})
export class RegisterPageComponent implements OnInit {

  _userData: UserRegister = new UserRegister();
  _requesting: boolean = false;
  userAlreadyExists: boolean = false;

  personalDataFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  completeFormGroup: FormGroup;
  passwordConfirmation: string;

  constructor(private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
    , private agendajaClient: AgendaJaHttpClient
    , public dialog: MatDialog
    , private sessionService: SessionService
    , private router: Router
    , private toastrService: ToastrService) { }

  validateTaxId(
      control: AbstractControl | null
    ): ValidatorFn {
      return () => {
        return this.validaContribuinte(control);
      };
    }

  validaContribuinte(control: AbstractControl | null): any {
      var temErro = 0;
      let contribuinte = control?.value;

      if(contribuinte === undefined){
        return { match_error: 'NIF inválido.' };
      }

      if (
        contribuinte.substr(0, 1) != '1' && // pessoa singular
        contribuinte.substr(0, 1) != '2' && // pessoa singular
        contribuinte.substr(0, 1) != '3' && // pessoa singular
        contribuinte.substr(0, 2) != '45' && // pessoa singular não residente
        contribuinte.substr(0, 1) != '5' && // pessoa colectiva
        contribuinte.substr(0, 1) != '6' && // administração pública
        contribuinte.substr(0, 2) != '70' && // herança indivisa
        contribuinte.substr(0, 2) != '71' && // pessoa colectiva não residente
        contribuinte.substr(0, 2) != '72' && // fundos de investimento
        contribuinte.substr(0, 2) != '77' && // atribuição oficiosa
        contribuinte.substr(0, 2) != '79' && // regime excepcional
        contribuinte.substr(0, 1) != '8' && // empresário em nome individual (extinto)
        contribuinte.substr(0, 2) != '90' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) != '91' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) != '98' && // não residentes
        contribuinte.substr(0, 2) != '99' // sociedades civis
      ) { temErro = 1; }
      let check1 = +contribuinte.substr(0, 1) * 9;
      var check2 = +contribuinte.substr(1, 1) * 8;
      var check3 = +contribuinte.substr(2, 1) * 7;
      var check4 = +contribuinte.substr(3, 1) * 6;
      var check5 = +contribuinte.substr(4, 1) * 5;
      var check6 = +contribuinte.substr(5, 1) * 4;
      var check7 = +contribuinte.substr(6, 1) * 3;
      var check8 = +contribuinte.substr(7, 1) * 2;

      var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      var divisao = total / 11;
      var modulo11 = total - Math.floor(divisao) * 11;
      var comparador = 0;

      if (modulo11 == 1 || modulo11 == 0) {
        comparador = 0;
      } // excepção
      else {
        comparador = 11 - modulo11;
      }

      var ultimoDigito = +contribuinte.substr(8, 1) * 1;
      if (ultimoDigito != comparador) { temErro = 1; }

      if (temErro == 1) {
        return { match_error: 'NIF inválido.' };
      }
      return null;
  }

  validateSession(): void {
    let ses = this.sessionService.Get();
    if (ses != null && ses.IdUser > 0) {
      this.router.navigate(['/']);
    }
  }

  setLocalization(): void {
    this._adapter.setLocale(this._locale);
  }

  public Recovery() {
    this.router.navigate(['/recovery']);
  }

  
  createFormGroups(): void {
    this.personalDataFormGroup = new FormGroup({
      genreNameCtrl: new FormControl(this._userData.IdGenre, [
        Validators.required,
      ]),
      firstNameCtrl: new FormControl(this._userData.FirstName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      lastNameCtrl: new FormControl(this._userData.LastName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      birthDateCtrl: new FormControl(this._userData.Birthdate, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      ]),
      taxIdCtrl: new FormControl(this._userData.TaxId, [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ])
    });

    this.addressFormGroup = new FormGroup({
      addressCtrl: new FormControl(this._userData.Address,
        [Validators.required,
        Validators.nullValidator,
        Validators.minLength(10)]),

      zipCodeCtrl: new FormControl(this._userData.ZipCode,
        [Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)]),

      zipCodeExtCtrl: new FormControl(this._userData.ZipCodeExt,
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)]),
    });

    this.completeFormGroup = new FormGroup({
      phoneCtrl: new FormControl(this._userData.PhoneNumber,
        [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(12)]),
      emailCtrl: new FormControl(this._userData.Email,
        [Validators.required,
        Validators.email]),
      passwordCtrl: new FormControl(this._userData.Password,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18)
        ]),
      passwordConfirmationCtrl: new FormControl(this.passwordConfirmation,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18),
        ])
    });

    this.completeFormGroup.addValidators(
      this.matchValidator(
        this.completeFormGroup.get('passwordCtrl'),
        this.completeFormGroup.get('passwordConfirmationCtrl')
      )
    );
    
    this.personalDataFormGroup.controls["taxIdCtrl"].addValidators(
      this.validateTaxId(
        this.personalDataFormGroup.get('taxIdCtrl')
      )
    );

  }

  ngOnInit(): void {
    this.validateSession();
    this.setLocalization();
    this.createFormGroups();
  }

  matchValidator(
    control: AbstractControl | null,
    controlTwo: AbstractControl | null
  ): ValidatorFn {
    return () => {
      if (control?.value !== controlTwo?.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  public Register() {
    if (this.IsUserDataValid()) {
      this._requesting = true;

      this.agendajaClient.Register(this._userData)
        .subscribe(created => {
          this._requesting = false;
          debugger;
          if (created) {
            //auto login
            this.openDialog();
          }
          else{
            this.userAlreadyExists = true;
            this.toastrService.error('O utilizador já existe. Proceda à recuperação da password.','Registo');
          }
        });
    }
    else{
      this.toastrService.error('Dados inválidos. Por favor, valide a informação inserida.','Registo');      
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ShowMessageDialogComponent, {
      panelClass: ['custom-dialog-container', 'animate__animated', 'animate__slideInLeft'],
      data: { username: this._userData.Email, password: this._userData.Password },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/']);
    });
  }

  private IsUserDataValid() {
    return this.personalDataFormGroup.valid
      && this.addressFormGroup.valid
      && this.completeFormGroup.valid;
  }

}
