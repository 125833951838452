import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { RefundRequestInput } from 'src/app/models/refunds/refund-request-input.model';
import { BaseSession } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-refund-request-dialog',
  templateUrl: './refund-request-dialog.component.html',
  styleUrls: ['./refund-request-dialog.component.scss']
})
export class RefundRequestDialogComponent implements OnInit {

  public UserSession: BaseSession | null = null;
  public refundRequest: RefundRequestInput = new RefundRequestInput();
  
  public refundDataFormGroup: FormGroup;

  constructor(private agendaJaClient: AgendaJaHttpClient, private sessionService: SessionService,  private dialogRef: MatDialogRef<RefundRequestDialogComponent>) { 
    this.sessionService.watchSession().subscribe((data:BaseSession) => {
      this.UserSession = data;
    });
  }

  ngOnInit(): void {
    this.UserSession = this.sessionService.Get();
    this.createFormGroup();
  }

  createFormGroup(): void {
    this.refundDataFormGroup = new FormGroup({
      valueCtrl: new FormControl(this.refundRequest.Amount, [
        Validators.required,
      ]),
      ibanCtrl: new FormControl(this.refundRequest.IBAN, [
        Validators.required,
        Validators.minLength(25),
        Validators.maxLength(25),
      ]),
      messageCtrl: new FormControl(this.refundRequest.Message, [
        Validators.required,
        Validators.minLength(10),
      ])      
    });
  }

  close(created:boolean){
    this.dialogRef.close({ created : created}); 
  }

  requestNewRefund(){
    this.agendaJaClient.CreateRefundRequest(this.refundRequest)
      .subscribe(
        {
          next: (success) => {
            if(success){
              alert("Pedido de remmbolso criado com sucesso!");
              this.close(true);
            }
            else{
              alert("Não foi possível criar o pedido de reembolso! Tente novamente, por favor.");
              this.close(false);
            }           
          },
          error: (err: any) => {
            alert("Não foi possível criar o pedido de reembolso! Tente novamente, por favor.");
            this.close(false);
          }
        }
      )
  }

}