
<mat-spinner *ngIf="showLoading" style="margin: 0 auto;"></mat-spinner>

<div class="row container-grid" *ngIf="!showLoading">
    <mat-grid-list  [cols]="breakpoint" rowHeight="250px" (window:resize)="onResize($event)">
        <mat-grid-tile *ngFor="let company of companies;">
            <app-company-card [company]="company"></app-company-card>
        </mat-grid-tile>
    </mat-grid-list>    
</div>


<mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [length]="totalItems"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page"
>
</mat-paginator>