export class PayWallet { 
    Amount: number;
    Method: string;
    MbwayPhone: string;

    constructor(amount:number, method:string, mbwayphone:string){
        this.Amount = amount;
        this.Method = method;
        this.MbwayPhone = mbwayphone;
    }
}