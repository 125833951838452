<div class="container">

    <h3>Políticas de Privacidade</h3>

    <div class="col-xs-12">


        <span>Por favor leia atentamente esta política de privacidade (“Política de Privacidade”) antes de utilizar esta
            Plataforma.
        </span>

        <ol>
            <li>Quem somos e como nos pode contactar
                A presente Política de Privacidade determina o que acontece aos dados pessoais que nos possa fornecer
                através da Plataforma.
                A Plataforma é gerida pelo VAINEFFECTS, LDA, empresa com sede Rua João Lucio Azevedo nº53 com o capital
                social de 200,00€ o NIF 516270303.
                Para efeitos da legislação em matéria de proteção de dados, somos a entidade responsável pelo tratamento
                das informações pessoais fornecidas e tratadas através da Plataforma (“Responsável pelo Tratamento”).
                Atualmente não somos obrigados a ter um Encarregado de Proteção de Dados mas caso queira entrar em
                contacto connosco, poderá fazê-lo através do nosso formulário disponível na Plataforma.
                Por favor note que a presente Política de Privacidade pode ser alterada e atualizada a qualquer momento,
                sendo a nova versão publicada na Plataforma com a indicação de ter sido atualizada. Caso se tenha
                registado na Plataforma, avisá-lo-emos de eventuais alterações através de E-mail.
            </li>
            <li>
                <p>Que informações recolhemos a seu respeito?</p>
                A Plataforma pode ser visualizada sem que seja necessário disponibilizar ativamente qualquer informação
                pessoal. Todavia, existem áreas que exigem a colocação de informação, sendo necessário que os
                utilizadores forneçam os seus dados pessoais, para que possam usufruir dos serviços disponibilizados.
                Dados pessoais fornecidos pelo Utilizador
                A recolha de dados de identificação do utilizador será efetuada através do preenchimento de um
                formulário de registo On-line, necessário para a criação de uma conta na Plataforma, e ocorrerá de
                acordo com as mais estritas regras de segurança.
                Se decidir contratar Serviços através da Plataforma, ser-lhe-á pedido que disponibilize os seguintes
                dados obrigatórios:
                <ul>
                    <li>o seu nome completo; </li>
                    <li>Data de Nascimento </li>
                    <li>E-mail; </li>
                    <li>morada; </li>
                    <li>número de identificação fiscal;</li>
                    <li>contacto telefónico;</li>
                </ul>

            </li>
            <br />
            <p>
                Após a ativação do seu perfil, receberá um E-mail de confirmação.
                Se assim o entender, poderá completar o seu perfil com informações adicionais.
            </p>
            <br />
            <span>Medidas para menores usarem o AGENDAJÁ com segurança</span>
            O uso da Plataforma AGENDAJÁ é aconselhável para idades igual ou superior a 12 anos. Trabalhamos arduamente
            para ajudar a manter as pessoas em segurança no AGENDAJÁ. Relativamente aos menores, informamos pelos termos
            de segurança todos os cuidados a ter. E relembramos que estão a usar dados específicos numa plataforma e
            para ter atenção e ter uma perfeita verificação das interações com estranhos e Pessoas Autónomas e
            Freelancers de Serviços e outros profissionais de Prestação de Serviços independentes.
            Também protegemos as informações confidenciais do menor ou adulto, como as informações de idade, para que
            não surjam em informações. Além disso, relembramos aos menores de que apenas devem aceitar serviços de
            Pessoas Autónomas e Freelancers de Serviços e outros profissionais de Prestação de Serviços independentes,
            que conheçam e que tenham a perfeita confiança.
            Consoante a idade do menor o tutor, podes ler os termos e as políticas de privacidade e concluir se aceita
            ou não os mesmo termos.
            Independentemente da idade do seu filho, recomendamos que a utilização responsável do AGENDAJÁ, faça parte
            das vossas da tecnologia. Conversa sobre as tuas expectativas, sobre o seu comportamento online e ajuda-o a
            compreender o que é seguro. Certifica-te de que o teu filho compreende os exemplos básicos da segurança na
            Internet:
            <ul>

                <li>Nunca partilhes a tua palavra-passe;</li>
                <li>Pensa e Pergunta bem antes de reservares algo;</li>
                <li>Agenda os Serviços apenas que conheces pessoalmente;</li>
                <li>Denuncia tudo o que for suspeito.</li>
            </ul>

            <p>
                Dados que recolhemos automaticamente
            </p>
            Cada vez que visitar a Plataforma, iremos recolher automaticamente a seguinte informação:
            <ul>
                <li>informação técnica, incluindo o endereço (IP) do seu dispositivo para conectar com a Internet, o
                    sistema operativo e guardaremos ainda a sua informação de login (data de registo, data de última
                    alteração de senha, data de último login com sucesso), o tipo e a versão de Browser utilizado;</li>
                <li>informação sobre a sua visita, incluindo os sítios a partir dos quais acede à Plataforma e os sítios
                    acedidos a partir da Plataforma, data e hora da visita, produtos e serviços visualizados, duração da
                    visita, tempo de resposta das páginas da Plataforma e outros dados relativos à sua interação com a
                    Plataforma.</li>
                <li>Por favor note que o seu uso da Plataforma poderá ser monitorizado para que se possa analisar e
                    interpretar, de forma agregada e anónima, os padrões de utilização da Plataforma, permitindo-nos
                    assim melhorar os níveis de serviço e a satisfação do utilizador. Caso pretenda limitar a informação
                    que recolhemos automaticamente sempre que utilizar a Plataforma, por favor consulte a nossa Política
                    de Cookies.</li>
                Se nos der o seu consentimento expresso, nomeadamente através do carregamento do respetivo botão de
                autorização, podemos recolher ainda dados, sobre as suas preferências e sobre da sua localização nesse
                momento. Esta informação poderá ser útil para poder determinar qual o Serviço mais próximo de si. Nas
                definições da Plataforma, poderá optar entre facultar dados de geo-localização apenas quando utiliza a
                Plataforma ou a todo o momento.
            </ul>
            <li>Quais são as finalidades do tratamento a que os dados pessoais se destinam?</li>
            <ul>
                Utilizaremos as informações que nos fornecer para:
                <li>possibilitar o agendamento do Serviço contratado – nos termos constantes dos Termos e Condições que
                    pode consultar;</li>
                <li>contactá-lo por E-mail, de modo a poder ativar a sua conta e receber avisos e, caso nos dê
                    autorização, para enviarmos informações sobre a Plataforma e as atividades;</li>
                <li>efeitos de Controlo do Serviço contratado;</li>
                <li>transmitir os dados às Pessoas Autónomas e/ou Freelancers de Serviços e/ou outros profissionais de
                    Prestação de Serviços para ela ir ao seu encontro.</li>
            </ul>

            <ul>
                Utilizaremos as informações que recolhemos automaticamente de si para:
                <li>criar informações anónimas e agregadas sobre as características e o comportamento dos utilizadores
                    da Plataforma, inclusivamente para efeitos de segmentação e desenvolvimento de perfis anónimos;</li>
                <li>melhorar o desempenho da Plataforma e personalizar as informações que lhe dirigimos;</li>
                <li>administrar a Plataforma e torná-la mais segura e eficaz;</li>
                <li>determinar qual a Pessoas Autónomas e/ou Freelancers de Serviços e/ou outros profissionais de
                    Prestação de Serviços mais próxima de si.</li>
                <li>determinar qual os serviços que mais se adequam aos seus gostos.</li>
            </ul>

            Tratamos os seus dados pessoais com base no seu consentimento e, quando aplicável, para efeitos da
            celebração e execução de um contrato do qual será parte ao clicar em Aceito os Termos de Privacidade.
            <br />
            <li>Login com ligação ao Facebook ou Nossa Plataforma ou APP</li>

            <ul>
                O Login com ligação ao Facebook ou a nossa Plataforma ou APP (poderá) dar acesso ao a dados como:

                Coisas que tu e as outras pessoas fazem e fornecem.
                <li>Informações e conteúdos que forneces. Recolhemos os conteúdos, comunicações e outras informações que
                    forneces quando utilizas os nossos Produtos e serviços, incluindo quando te registas numa conta,
                    crias ou partilhas conteúdos e envias mensagens ou comunicas com outras pessoas. Isto pode incluir
                    informações presentes nos ou sobre os conteúdos que forneceste (tal como metadados), como a
                    localização de uma foto ou a data em que um ficheiro foi criado. Os nossos sistemas tratam
                    automaticamente os conteúdos e as comunicações que tu e as outras pessoas fornecem para analisar o
                    contexto e o assunto dos mesmos para os fins descritos abaixo.</li>
                <li>Redes e ligações. Recolhemos informações sobre as pessoas, Páginas, contas, Hashtags e grupos a que
                    estás ligado e sobre a forma como interages com os mesmos nos nossos Produtos; por exemplo, as
                    pessoas com quem mais comunicas ou os grupos dos quais fazes parte. Também recolhemos informações de
                    contacto se optares por carregar, sincronizar ou importar as mesmas a partir de um dispositivo (como
                    uma lista de contactos ou um histórico de registo de chamadas ou de SMS), as quais utilizamos para
                    ajudar-te a ti e a outros utilizadores a encontrar pessoas que possam conhecer e para os fins
                    indicados abaixo.</li>
                <li>A tua utilização. Recolhemos informações sobre a forma como utilizas os nossos Produtos e Serviços,
                    como os tipos de conteúdos que vês ou com os quais interages; as funcionalidades que utilizas; as
                    ações que efetuas; as pessoas ou as contas com as quais interages; e o tempo, a frequência e a
                    duração das tuas atividades. Por exemplo, registamos quando utilizas e quando utilizaste pela última
                    vez os nossos Produtos, bem como as publicações, os vídeos e outros conteúdos que vês nos nossos
                    Produtos. Também recolhemos informações sobre a forma como utilizas as funcionalidades.</li>
                <li>Informações sobre transações efetuadas nos nossos Produtos. Se utilizares os nossos Produtos e
                    Serviços para fazer compras ou para outros interesses, recolhemos informações sobre a compra ou
                    transação. Isto inclui as informações de pagamento, como o número do teu cartão de crédito ou
                    débito, e outras informações do cartão; outras informações de conta e de autenticação; e detalhes de
                    faturação, envio e contacto.</li>
                <li>Coisas que outras pessoas fazem e as informações que fornecem sobre ti. Também recebemos e
                    analisamos conteúdos, comunicações e informações que as outras pessoas fornecem quando utilizam os
                    nossos Produtos. Isto pode incluir informações sobre ti, como quando outras pessoas partilham ou
                    comentam uma foto tua, te enviam uma mensagem ou carregam, sincronizam ou importam as tuas
                    informações de contacto.</li>
            </ul>

            Informações do Dispositivo
            Conforme descrito abaixo, recolhemos informações de e sobre os computadores, telemóveis, TVs associadas e
            outros dispositivos ligados à Internet que utilizas e que estão integrados com os nossos Produtos e
            Serviços, e combinamos estas informações nos diferentes dispositivos que utilizas. Por exemplo, utilizamos
            as informações recolhidas sobre a tua utilização dos nossos Produtos no teu telemóvel para personalizarmos
            da melhor forma os conteúdos incluindo anúncios ou as funcionalidades que vês quando utilizas os nossos
            Produtos e serviços noutro dispositivo (como o teu portátil ou tablet) ou para medir se efetuaste uma ação
            em resposta a um anúncio que te apresentámos no teu telemóvel num dispositivo diferente.
            <ul>
                As informações que obtemos destes dispositivos incluem:
                <li>Atributos dos dispositivos: informações como o sistema operativo, versões do software e hardware,
                    nível de bateria, força do sinal, espaço de armazenamento disponível, tipo de browser, nomes e tipos
                    de apps e de ficheiros, e plug-ins;</li>
                <li>Operações no dispositivo: informações sobre os comportamentos e as operações efetuadas no
                    dispositivo, como se a janela está em primeiro ou segundo plano, ou os movimentos do rato (que podem
                    ajudar a distinguir humanos de bots);</li>
                <li>Identificadores: identificadores únicos, identificações do dispositivo e outros identificadores,
                    como de jogos, apps ou contas que utilizas, e identificações de dispositivos da família (ou outros
                    identificadores únicos dos associados ao mesmo dispositivo ou conta);</li>
                <li>Sinais do dispositivo: sinais de Bluetooth e informações sobre torres de rede móvel, beacons e
                    pontos de acesso Wi-Fi nas proximidades;</li>
                <li>Dados das definições do dispositivo: informações que nos permites receber através das definições do
                    dispositivo que ativas, como o acesso ao teu GPS, à localização, à câmara ou às fotos;</li>
                <li>Redes e ligações: informações como o nome da tua operadora móvel ou fornecedor de serviços de
                    Internet (FSI), idioma, fuso horário, número de telemóvel, endereço IP, velocidade da ligação e, em
                    alguns casos, informações sobre outros dispositivos nas proximidades ou na tua rede.</li>
                <li>Dados de cookies: dados de cookies armazenados no teu dispositivo, incluindo definições e
                    identificações de cookies.</li>
            </ul>

            <li>Os seus direitos</li>
            Nos termos da legislação em vigor, terá o direito de se opor, em qualquer momento e de forma gratuita, ao
            tratamento dos seus dados pessoais para efeitos de Marketing directo ou qualquer outra forma de prospeção.
            Tem ainda o direito de retirar o seu consentimento em qualquer altura, sem que isso comprometa a licitude do
            tratamento que tenhamos feito com base no consentimento previamente dado.
            Tem ainda o direito de ser informado antes de os dados pessoais serem comunicados pela primeira vez a
            terceiros para fins de Marketing directo ou utilizados por conta de terceiros, e de lhe ser expressamente
            facultado o direito de se opor, sem despesas, a tais comunicações ou utilizações.
            Caso pretenda que lhe seja facultado o acesso às informações pessoais que detemos a seu respeito, queira por
            favor remeter-nos um pedido nesse sentido, usando para o efeito as funcionalidades na sua área pessoal da
            Plataforma.
            Alternativamente, poderá solicitar essas informações através do formulário da Plataforma. A prestação destas
            informações é gratuita.
            Se porventura alguma das informações que detenhamos a seu respeito estiver incorreta ou não for exata,
            poderá proceder à sua correção alterando a informação constante da sua conta.

            Poderá ainda contactar-nos para se opor ao tratamento dos seus dados pessoais, requerer que sejam apagados
            ou que haja uma limitação do tratamento dos mesmos, bem como solicitar o direito à portabilidade dos dados
            pessoais nos termos e dentro dos limites estabelecidos na legislação em vigor. Tem ainda o direito de
            solicitar a notificação de terceiros sobre a retificação, apagamento ou limitação do tratamento dos seus
            dados (.
            Fazemos notar ainda que terá o direito de apresentar uma reclamação a uma autoridade de controlo que, em
            Portugal, será a Comissão Nacional da Protecção de Dados (www.cnpd.pt).

            <li>Correspondência</li>
            Utilizaremos o seu endereço de E-mail para lhe enviarmos um E-mail confirmação do seu registo. Utilizaremos
            ainda o seu endereço de E-mail para comunicar consigo no âmbito de eventuais serviços que venha a contratar
            através da Plataforma.
            Gostaríamos de enviar-lhe E-mail e/ou SMS com informações sobre os nossos serviços, com notícias sobre as
            nossas atividades e sobre quaisquer promoções ou ofertas que venhamos a lançar.
            Caso prefira que não lhe enviemos estes E-mail e/ou SMS, poderá em qualquer momento optar por não
            recebê-los, bastando para o efeito:
            retirar o seu consentimento através de funcionalidades na sua área pessoal; ou
            contactar-nos em qualquer momento através do nosso formulário disponível na plataforma.

            <li>Segurança e qualidade da informação</li>
            É nosso objetivo garantir a qualidade e integridade da informação fornecida pelos utilizadores da
            Plataforma, tendo, neste sentido, sido implementadas as medidas necessárias, tanto ao nível tecnológico como
            organizacional, de forma a mantermos a informação segura, precisa, atualizada e completa.
            Para garantir a máxima segurança, nas áreas da Plataforma onde são recolhidos os dados pessoais dos
            utilizadores, a sua transmissão é encriptada.

            <li>Com quem partilhamos os seus dados?</li>
            Os seus dados pessoais serão transferidos única e exclusivamente Pessoas Autónomas e/ou Freelancers de
            Serviços e/ou outros profissionais de Prestação de Serviços que contratar através da Plataforma. De resto,
            não serão transferidos a terceiros, salvo se(i)tivermos a sua autorização expressa,(ii)os terceiros nos
            apoiarem, ao abrigo de um contrato, no fornecimento dos nossos produtos ou na prestação dos nossos serviços
            a si,(iii)tal for exigido nos termos da lei,(iv)para executar os nossos termos de utilização, direitos ou
            titularidade ou (v)em ligação com a venda de um negócio, da nossa sociedade ou dos seus ativos ao abrigo de
            obrigações de confidencialidade.
            Os trabalhadores e consultores do AGENDAJÁ terão acesso aos seus dados pessoais para efeitos da gestão da
            Plataforma, estando, no entanto, abrangidos por uma obrigação de confidencialidade relativamente aos dados
            aos quais tenham acesso no âmbito das operações da respetiva base informática.
            Os trabalhadores e consultores estão devidamente informados da importância do cumprimento desse dever legal
            de sigilo, sendo responsáveis pelo cumprimento dessa obrigação de confidencialidade.
            Mediante decisão judicial, poderemos ter a obrigação de divulgar a identidade do utilizador.

            <li>Onde armazenamos os seus dados pessoais e durante quanto tempo?</li>
            Os dados que recolhemos de si serão armazenados em servidores localizados.
            O período de tempo durante o qual os dados são armazenados e conservados varia de acordo com a finalidade
            para a qual a informação é tratada. Conservaremos os seus dados pessoais enquanto for utilizador ativo da
            Plataforma.
            Por favor note que existem requisitos legais que obrigam a conservar os dados por um período de tempo mínimo
            (como por exemplo dados relativos a faturação). Assim, e sempre que não exista uma exigência legal
            especifica, os dados serão armazenados e conservados apenas pelo período mínimo necessário para as
            finalidades que motivaram a sua recolha ou o seu posterior tratamento ou, pelo período de tempo autorizado
            pela Comissão Nacional de Proteção de Dados, findo o qual os mesmos serão eliminados.

            <li>Medidas técnicas e organizativas e segurança do tratamento</li>
            Toda a informação que recebermos da sua parte será armazenada em servidores seguros e implementaremos
            medidas técnicas e organizativas adequadas e necessárias para que os seus dados pessoais sejam protegidos a
            um nível de segurança adequado que garanta designadamente a confidencialidade e a integridade dos dados e
            que previna a destruição, perda e alterações acidentais ou ilícitas ou, ainda, a divulgação ou acesso não
            autorizados de dados.
            Quaisquer dados de pagamento serão encriptados utilizando tecnologia SSL.
            Nos casos em que tenhamos fornecido um código de acesso à Plataforma, reconhece e aceita que é sua
            responsabilidade manter esse código secreto e confidencial. Jamais solicitaremos que partilhe esse código
            com outras pessoas.
            Não obstante as medidas implementadas para proteger os seus dados, deverá estar ciente de que a transmissão
            de informação através da Internet ou outras redes abertas nunca é totalmente segura, havendo o risco de os
            seus dados serem vistos e utilizados por terceiros não autorizados. Somos obrigados a alertar todos os que
            navegam na Internet que devem adotar medidas adicionais de segurança designadamente, assegurar que utiliza
            um PC e um Browser actualizado em termos de Patches de segurança adequadamente configurados, com Firewall
            ativa, antivírus e anti-Spyware e, certificar-se da autenticidade dos sites que visita na Internet, devendo
            evitar web-sites em cuja reputação não confie.
            Por favor adapte as definições da sua conta na Plataforma para corresponderem aos tratamentos que autoriza.

            <li>Links para outros sites</li>
            A Plataforma poderá conter Links para outros sítios de Internet ou para outras aplicações, incluindo dos
            nossos parceiros. Caso aceda a um desses Links, por favor note que cada um terá a sua própria política de
            privacidade e não nos responsabilizamos por essas políticas.
            Por favor leia as políticas de privacidade de outros sítios de Internet antes de submeter dados pessoais
            através dos mesmos.


            <li>teus direitos fornecidos ao abrigo do RGPD?</li>
            Ao abrigo do Regulamento Geral sobre a Proteção de Dados, tens o direito de aceder, retificar, transferir e
            apagar os teus dados. Também tens o direito de te opores e restringir determinado tratamento dos teus dados.
            Isto inclui:
            <li>O direito de oposição ao nosso tratamento dos teus dados para efeitos de marketing direto, o qual podes
                exercer através da ligação "cancelar a subscrição" em tais comunicações de marketing; e</li>
            <li>O direito de oposição ao nosso tratamento dos teus dados quando estivermos a exercer funções de
                interesse público ou para prosseguirmos os nossos interesses legítimos ou os interesses legítimos de
                terceiros.</li>

            <li>Contactos</li>
            Para quaisquer informações adicionais ou para exercer os seus direitos, pode contactar-nos através do nosso
            formulário disponível em www.AGENDAJÁ.pt.


        </ol>
    </div>
</div>