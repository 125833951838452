<div class="container"> 
    <div class="row">
        <div class="container-payment">
            <div *ngIf="loading">
                <mat-spinner style="margin: 0 auto;"></mat-spinner>                
            </div>
            <div id="easypay-checkout" class="easypay-checkout"></div>
        </div>
    </div>
</div>



