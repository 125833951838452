import { DeclarationListEmitMode } from "@angular/compiler";
import { Wallet } from "../wallet/wallet.module";

export class BaseSession {
    IdUser: number;
    UserName: string;
    Name: string;
    Token: string;
    IdUserType: number;
    MobilePhoneNumber: string;
    Email: string;
    HasSMSNotification: boolean;
    HasEmailNotification: boolean;
    ProfilePicPath: string;
    ExternalUserId: string;

    Wallet: Wallet;

    IsAnonymous: boolean;
 
    constructor() {
        this.IsAnonymous = this.IdUser == 0;
    }

}

export class Session extends BaseSession {
    StartDate: Date;
}
