import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SessionComponent } from './components/session/session.component';
import localePt from '@angular/common/locales/pt';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { HomeCarouselComponent } from './components/home-carousel/home-carousel.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompaniesGridComponent } from './components/companies-grid/companies-grid.component';
import { CompanyCardComponent } from './components/company-card/company-card.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { CustomPaginator } from './functions/CustomMatPaginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { ServicePageComponent } from './pages/service-page/service-page.component';
import { ServicesGridComponent } from './components/services-grid/services-grid.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthenticationDialogComponent } from './components/dialogs/authentication-dialog/authentication-dialog.component';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ShowMessageDialogComponent } from './components/dialogs/show-message-dialog/show-message-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { CustomersFeedbackComponent } from './components/customers-feedback/customers-feedback.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IvyCarouselModule } from 'carousel-angular';
import { BusinessPageComponent } from './pages/business-page/business-page.component';
import { MatTableModule } from '@angular/material/table';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { ShoppingCartBadgeComponent } from './components/shopping-cart/shopping-cart-badge/shopping-cart-badge.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart/shopping-cart.component';
import {MatIconModule} from '@angular/material/icon';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { BeautyNewsComponent } from './components/news/beauty-news/beauty-news.component';
import { MensBeautyNewsComponent } from './components/news/mens-beauty-news/mens-beauty-news.component';
import { CheckoutErrorComponent } from './pages/checkout/checkout-error/checkout-error.component';
import { CheckoutSuccessComponent } from './pages/checkout/checkout-success/checkout-success.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
import { UserAgendaComponent } from './components/profile/user-agenda/user-agenda.component';
import { UserWalletComponent } from './components/profile/user-wallet/user-wallet.component';
import { UserHistoryComponent } from './components/profile/user-history/user-history.component';
import { UserRefundsComponent } from './components/profile/user-refunds/user-refunds.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserHeaderComponent } from './components/profile/user-header/user-header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CheckoutPayPageComponent } from './pages/checkout/checkout-pay-page/checkout-pay-page.component';
import { ScheduleCancellationDialogComponent } from './components/dialogs/schedule-cancellation-dialog/schedule-cancellation-dialog.component';
import { PayWalletDialogComponent } from './components/dialogs/pay-wallet-dialog/pay-wallet-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RefundRequestDialogComponent } from './components/dialogs/refund-request-dialog/refund-request-dialog.component';
import { PasswordRecoveryPageComponent } from './pages/password-recovery-page/password-recovery-page.component';
import { PasswordRecoveryConfirmPageComponent } from './pages/password-recovery-confirm-page/password-recovery-confirm-page.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PromptComponent } from './components/prompt/prompt.component';
import { PwaService } from './services/pwa-service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { ToastrModule } from 'ngx-toastr';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { BusinessLandingPageComponent } from './pages/business-landing-page/business-landing-page.component';

registerLocaleData(localePt, 'pt');

// const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'agendaja.pt' 
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: '#125bb9'
    },
    button: {
      background: 'rgb(1 134 243)',
      text: '#fff'      
    }
  },
  type: 'info',
  content: {
    allow: 'Entendi',
    deny: 'Recusar',
    message: 'Este site utiliza cookies para fornecer uma melhor experiência.',
    link: '',
    header: 'Cookies',
    policy: 'Políticas de Cookies',
    dismiss: "Entendi!", 
  },
  autoOpen: true
};

@NgModule({
  declarations: [
    AppComponent,
    SessionComponent,
    CompanyPageComponent,
    NotfoundPageComponent,
    TopBarComponent,
    HomePageComponent,
    HomeCarouselComponent,
    CompaniesGridComponent,
    CompanyCardComponent,
    ServiceCardComponent,
    ServicePageComponent,
    ServicesGridComponent,
    FooterComponent,
    AuthenticationDialogComponent,
    RegisterPageComponent,
    ShowMessageDialogComponent,
    CustomersFeedbackComponent,
    BusinessPageComponent,
    PolicyPageComponent,
    TermsPageComponent,
    ShoppingCartBadgeComponent,
    ShoppingCartComponent,
    CheckoutPageComponent,
    BeautyNewsComponent,
    MensBeautyNewsComponent,
    CheckoutSuccessComponent,
    CheckoutErrorComponent,
    UserPageComponent,
    UserProfileComponent,
    UserAgendaComponent,
    UserWalletComponent,
    UserHistoryComponent,
    UserRefundsComponent,
    UserHeaderComponent,
    CheckoutPayPageComponent,
    ScheduleCancellationDialogComponent,
    PayWalletDialogComponent,
    RefundRequestDialogComponent,
    PasswordRecoveryPageComponent,
    PasswordRecoveryConfirmPageComponent,
    PromptComponent,
    BusinessLandingPageComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    MatGridListModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    // BrowserAnimationsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule, MatInputModule, MatProgressSpinnerModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatSidenavModule,
    IvyCarouselModule,
    MatTableModule,
    MatIconModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatTabsModule,
    MatExpansionModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ToastrModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    // {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


