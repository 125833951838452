<div class="container example-form">
    <h4>Pedir Reembolso</h4>

    <p>Saldo: <b>{{ UserSession?.Wallet?.Balance | currency:'EUR' }}</b></p>

    <div>
        <form [formGroup]="refundDataFormGroup">
        
            <mat-form-field class="">
                <mat-label>Valor a reembolsar (€)</mat-label>
                <input matInput type="number" min="1" max="{{UserSession?.Wallet?.Balance}}" placeholder="Valor a reembolsar" id="value" formControlName="valueCtrl" required
                    [(ngModel)]="refundRequest.Amount">
            </mat-form-field>
            <br>
            <mat-form-field class="form-full-width">
                <mat-label>IBAN</mat-label>
                <input matInput placeholder="IBAN" id="iban" formControlName="ibanCtrl" required
                    [(ngModel)]="refundRequest.IBAN">
            </mat-form-field>
            <br>
            <mat-form-field class="form-full-width">
                <mat-label>Motivo</mat-label>
                <textarea matInput id="message" formControlName="messageCtrl" required
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"
                    [(ngModel)]="refundRequest.Message"></textarea>
            </mat-form-field>

        </form>

        <div class="row wrapper-actions">
            <div class="col-12 col-sm-6 action-wrapper">
                <button mat-raised-button class="full-width" (click)="close(false)">Cancelar</button>
            </div>
            <div class="col-12 col-sm-6 action-wrapper">
                <button mat-raised-button class="full-width" color="primary"
                (click)="requestNewRefund()"
                [disabled]="!refundDataFormGroup.valid">Pedir reembolso</button>
            </div>
        </div>
    </div>

</div>  