import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { debug } from 'console';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { PasswordChange } from 'src/app/models/user/password-change.model';
import { UserInfo } from 'src/app/models/user/user-info.model';
import { UserRegister } from 'src/app/models/user/userregister.model';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-PT' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] }
  ]
})
export class UserProfileComponent implements OnInit {

  public _requesting: boolean = false;
  public _requestingPasswordChange: boolean = false;

  public personalDataFormGroup: FormGroup;
  public addressFormGroup: FormGroup;
  public completeFormGroup: FormGroup;
  public passwordChageFormGroup: FormGroup;
  public usardataSaved: boolean = false;
  public userDataIsNotCorrect: boolean = false;
  public userData: UserInfo = new UserInfo();

  public birthDate: string;

  public passwordChageData: PasswordChange = new PasswordChange();
  public confirmPassword: string;
  public passwordSuccessfullyUpdated: boolean;
  public passwordNotUpadted: boolean;
  public passwordUpdatedErrorMessage: string;

  constructor(private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private sessionService: SessionService,
    private agendajaClient: AgendaJaHttpClient,
    private cartService: CartService, private router: Router) { }

  ngOnInit(): void {
    this.setLocalization();
    this.createFormGroups();

    this.agendajaClient.GetUser()
      .subscribe(
        {
          next: (user: any) => {
            this.userData = user;
            this.birthDate = user.BirthDate;
          },
          error: (err: any) => {
            alert('Não foi possivel carregar os seus dados.');
          }
        });
  }

  setLocalization(): void {
    this._adapter.setLocale(this._locale);
  }

  public createFormGroups(): void {
    this.personalDataFormGroup = new FormGroup({
      genreNameCtrl: new FormControl(this.userData.IdGenre, [
        Validators.required,
      ]),
      firstNameCtrl: new FormControl(this.userData.FirstName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      lastNameCtrl: new FormControl(this.userData.LastName, [
        Validators.required,
        Validators.minLength(2),
      ]),
      birthDateCtrl: new FormControl(this.birthDate, [
        Validators.required
      ]),
      taxIdCtrl: new FormControl(this.userData.TaxId, [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9),
      ])
    });

    this.addressFormGroup = new FormGroup({
      addressCtrl: new FormControl(this.userData.Address,
        [Validators.required,
        Validators.nullValidator,
        Validators.minLength(10)]),

      zipCodeCtrl: new FormControl(this.userData.ZipCode,
        [Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)]),

      zipCodeExtCtrl: new FormControl(this.userData.ZipCodeExt,
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)]),
    });

    this.completeFormGroup = new FormGroup({
      phoneCtrl: new FormControl(this.userData.PhoneNumber,
        [Validators.required,
        Validators.minLength(9),
        Validators.maxLength(12)]),
      emailCtrl: new FormControl(this.userData.Email,
        [Validators.required,
        Validators.email])
    });

    this.passwordChageFormGroup = new FormGroup({
      currentPasswordCtrl: new FormControl(this.passwordChageData.OldPassword,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18)]),
      newPasswordCtrl: new FormControl(this.passwordChageData.NewPassword,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18)]),
      confirmNewPasswordCtrl: new FormControl(this.passwordChageData.NewPassword,
        [Validators.required,
        Validators.minLength(6),
        Validators.maxLength(18)]),
    });

    this.passwordChageFormGroup.addValidators(
      this.matchValidator(
        this.passwordChageFormGroup.get('newPasswordCtrl'),
        this.passwordChageFormGroup.get('confirmNewPasswordCtrl')
      )
    )
  }

  matchValidator(
    control: AbstractControl | null,
    controlTwo: AbstractControl | null
  ): ValidatorFn {
    return () => {
      if (control?.value !== controlTwo?.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }

  public SaveProfile() {

    if (this.IsUserDataValid()) {
      this._requesting = true;
      this.userData.BirthDate = this.birthDate;

      this.agendajaClient.UpdateUser(this.userData)
        .subscribe(updated => {
          this._requesting = false;
          this.usardataSaved = true;
        });
    }
    else {
      this.userDataIsNotCorrect = true;
    }
  }

  private IsUserDataValid() {
    return this.personalDataFormGroup.valid
      && this.addressFormGroup.valid
      && this.completeFormGroup.valid;
  }

  public changePassword(){    

    this.passwordSuccessfullyUpdated = false;
    this.passwordNotUpadted = false;

    if(this.passwordChageFormGroup.valid){
      this._requestingPasswordChange = true;

      this.agendajaClient.UpdatePassword(this.passwordChageData)
      .subscribe(
        {
          next: (result: any) => {
            this._requestingPasswordChange = false;
            this.passwordSuccessfullyUpdated = true;
            this.resetChangePasswordForm();
          },
          error: (err: any) => {
            this._requestingPasswordChange = false;
            this.passwordNotUpadted = true;

            if (err.error == 2) {
              this.passwordUpdatedErrorMessage = "A password atual não coincide.";
              this.passwordChageData.OldPassword = "";
            }
            else{
              this.passwordUpdatedErrorMessage = "Não foi possível alterar a password";
            }
            this.resetChangePasswordForm();
          }
        });
    }

  }

  private resetChangePasswordForm(){
    this.passwordChageData = new PasswordChange();
    this.confirmPassword = '';
    this.passwordChageFormGroup.reset();
  }

  public acceptedRemove: boolean;
  public RemoveAccount(){
    var result = this.agendajaClient.RemoveAccount()
    if(result){
      this.sessionService.Clear();
      this.cartService.ClearCart();
      this.router.navigate(['/']);  
    }
  }

}
