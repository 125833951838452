<app-user-header></app-user-header>


<div class="container" style="min-height: 70vh;">

    <div class="row">
        <div class="col-12 personal-head-title">
            <h4>Reembolsos</h4>
        </div>
    </div>

    <div class="row">

        <div class="head-actions">
            <button mat-stroked-button class="action-button" (click)="createNewRequest()">Pedir Reembolso</button>   
        </div>

        <div style="margin-top: 15px; margin-bottom: 15px;">
            <div *ngIf="refundRequests.length == 0">
                <p>Não existem pedidos de reembolso.</p>
            </div>

            <mat-accordion *ngFor="let refund of refundRequests;" class="example-headers-align" multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="status"
                                [ngClass]="{'pending': refund.IdStatus == 1, 'approved' : refund.IdStatus == 2, 'rejected' : refund.IdStatus == 3 }">
                                <span *ngIf="refund.IdStatus == 1">Pendente</span>
                                <span *ngIf="refund.IdStatus == 2">Aceite</span>
                                <span *ngIf="refund.IdStatus == 3">Negado</span>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>

                            <div class="row">
                                <div class="col-8">
                                    <div style="display: inline-block;">
                                        <span>{{ refund.Amount | currency:"EUR" }}</span>
                                    </div>
                                </div>

                                <div class="col-4">
                                    <div style="display: inline-block;">
                                        <span>{{ refund.DateCreate | date:'dd/MM/YYYY' }}</span>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 100%;">
                                <div style="display: inline-block;float: right;">
                                    <span *ngIf="refund.IdStatus == 1" class="material-symbols-outlined" style="float: right;">pending</span>
                                    <span *ngIf="refund.IdStatus == 2" class="material-symbols-outlined" style="float: right;color: green;">done</span>    
                                    <span *ngIf="refund.IdStatus == 3" class="material-symbols-outlined" style="float: right;color: red;">close</span>    
                                </div>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                
                    <div>
                        <div>
                            <span>Pedido de reembolso para a conta: {{refund.IBAN}}</span>
                        </div>

                        <div *ngFor="let message of refund.Messages;">
                            <div class="conversation-message"
                                [ngClass]="{'userMessage': message.IdUserCreate == UserSession?.IdUser
                                            ,'systemMessage': message.IdUserCreate != UserSession?.IdUser
                                            }">
                                <div class="message-head">
                                    <img class="user-profile" src="{{ profilePicPath }}" style="width: 20px;border-radius: 15px;">
                                    <span style="margin-left: 10px;">{{ message.UserNameCreateMessage }}</span>    
                                </div>
                                <div class="message">
                                    <span>{{ message.Message }}</span>
                                </div>
                                <div class="footer">
                                    <span>Enviada: {{ message.DateCreated | date:'dd/MM/YYYY HH:mm' }}h</span>   
                                    <br>
                                    <span *ngIf="!message.WasRead">Não tratado.</span>
                                    <span *ngIf="message.WasRead">Visto em {{ message.DateWasRead| date:'dd/MM/YYYY HH:mm' }}h</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="wrap-send-msg" *ngIf="refund.IdStatus == 1">
                        <div class="sendNewMessage">
                            <mat-form-field class="form-full-width">
                                <mat-label>Mensagem</mat-label>
                                <textarea matInput id="message" required
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10"
                                    [(ngModel)]="refund.NewMessageToSend"
                                    ></textarea>
                            </mat-form-field>

                            <div class="btn-send-msg">
                                <button mat-icon-button color="primary" 
                                    aria-label="Example icon button with a home icon"
                                    (click)="sendNewMessage(refund)"
                                    >
                                    <span style="margin-right: 10px;">Enviar</span>
                                    <mat-icon>send</mat-icon>
                                </button>                           
                            </div>

                        </div>
                    </div>

                </mat-expansion-panel>           
            </mat-accordion>

        </div>

    </div>

</div>  

