
<div class="checkout-head">
    <h2>Checkout</h2>
    <span>Por favor, confirme que todos os dados estão corretos.</span>
</div>

<div class="container">
    
    <div class="cart-items" *ngIf="!cartIsEmpty">
        <div class="row cart-item" *ngFor="let cartitem of CartItems;">
            
            <div class="col-2 img-checkout-class-wrapper">
                <img src="{{cartitem.ServiceLogoPath}}" class="img-checkout-class">
            </div>

            <div class="col-8">
                <span class="cart-item-service-name" routerLink="/company/{{ cartitem.IdCompany }}/service/{{ cartitem.IdItem }}">{{ cartitem.ServiceName }}</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    calendar_today
                </span>    
                <span class="cart-item-service-detail">{{ cartitem.Day }}</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    schedule
                </span>    
                <span class="cart-item-service-detail">{{ cartitem.Hour }}h</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    storefront
                </span>    
                <span class="cart-item-service-detail company-name" routerLink="/company/{{ cartitem.IdCompany }}">{{ cartitem.CompanyName }}</span>
                <br>
                <span class="material-symbols-outlined icon-header">
                    location_on
                </span>
                <span class="cart-item-local">{{ cartitem.CompanyAddress }}</span>
                <br>
                <div>
                    <span class="material-symbols-outlined icon-header">
                        credit_card
                    </span>
                    <span *ngIf="cartitem.OnlinePayment" class="online-payment"> Pagamento Online</span>
                    <span *ngIf="!cartitem.OnlinePayment"  class="offline-payment"> Pagamento em loja</span>
                </div>
            
            </div>

            <div class="col-2" style="position: relative; text-align: right;">
                <span class="cart-item-service-price">{{ cartitem.Price | currency:'EUR' }}</span>
                <br>
                <button mat-icon-button class="remove-cart-item" (click)="deleteFromCart(cartitem.Id)">
                    <mat-icon style="transform: scale(0.7); color: #7e7e7e">delete</mat-icon>
                </button>
            </div>  

        </div>

        <div class="row total-summary">
            <div class="col-4 total-lable">
                <span>Total</span>
            </div>
            <div class="col-8 total-value">
                <span style="margin-right: 20px;">{{ GetShoppingCartTotal() | currency:'EUR' }}</span>                
            </div>
        </div>

        <div class="row" *ngIf="IsUserAuthenticated()" style="margin-top: 40px;">

            <div class="">
                <mat-checkbox [(ngModel)]="termsAgree">
                    <span> Declaro que li e concordo os Termos e Condições do Agenda Já.</span>
                </mat-checkbox>
            </div>

            <div class="">
                <mat-checkbox [(ngModel)]="policiesAgree">
                    <span> Declaro que li e concordo com as Políticas de Cancelamento dos serviços que estou a agendar.</span>
                </mat-checkbox>
            </div>
<!-- 
            <button mat-raised-button class="checkout-button" [disabled]="!termsAgree || !policiesAgree"  (click)="Checkout()" >Reservar</button>

            <br>
            <button mat-raised-button class="checkout-button" routerLink="/checkout/pay">Pagar diretamente</button> -->

        </div>

        <div class="row" *ngIf="IsUserAuthenticated()">
            <button mat-raised-button class="checkout-button" [disabled]="!termsAgree || !policiesAgree"  (click)="Checkout()">Checkout</button>
        </div>

        <!-- <div class="row" style="margin-top: 20px;" *ngIf="IsUserAuthenticated()">
            <div class="col-xs-12 col-sm-6" (click)="Checkout()" >
                <div class="row checkout-wallet-wrapper" [ngClass]="{'inactive-payment': (userSession?.Wallet?.Balance ?? 0) < GetShoppingCartTotal() }">
                        <div class="col-2">
                            <div class="icon-wrapper">
                                <span class="material-symbols-outlined icon-wallet">
                                    account_balance_wallet
                                </span>
                            </div> 
                        </div>
                        <div class="col-10">
                            <span class="title">Carteira AgendaJá</span>
                            <br>
                            <span class="subtitle">Saldo: <span class="sub-money">{{ userSession?.Wallet?.Balance | currency:'EUR'  }} </span></span>  
                        </div>  
                        <div class="col-12" class="no-balance-warn" *ngIf="(userSession?.Wallet?.Balance ?? 0) < GetShoppingCartTotal()">
                            <span>Sem saldo disponível.</span>
                        </div>                     
                </div>
            </div>

            < <div class="col-xs-12 col-sm-6 "*ngIf="GetShoppingCartTotal() > 0" (click)="CheckoutExternal()">
                <div class="row checkout-wallet-wrapper">
                    <div class="col-2">
                        <div class="icon-wrapper">
                            <span class="material-symbols-outlined icon-cash">
                                credit_card
                            </span>
                        </div>
                    </div>
                    <div class="col-10">
                        <span class="title">Pagar Online</span>
                        <br>
                        <span class="subtitle">Easypay checkout</span>  
                       </div>
                </div>
            </div> 

        </div> -->


        <div class="row no-auth" *ngIf="!IsUserAuthenticated()">
            <!-- <button mat-raised-button class="checkout-button" >Checkout</button> -->
            <span>Tens de fazer login para continuar.</span>
        </div>

        
        <div *ngIf="checkoutError" class="alert alert-danger checkout-error" role="alert">
            <span>{{ checkoutMessage }}</span>
        </div>
    
    </div>


    <div class="cart-items" *ngIf="cartIsEmpty">
        <h4>Ups! Parece que tem o carrinho vazio!</h4>
        <span>Adicione agendamentos ao seu carrinho para proceder com o checkout!</span>

        <br>

        <button mat-raised-button color="primary" (click)="goto('home')" style="margin-top: 20px;width: 100%;">
            Voltar para o Início
        </button>                    


    </div>

</div>
