import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';

@Component({
  selector: 'app-business-landing-page',
  templateUrl: './business-landing-page.component.html',
  styleUrls: ['./business-landing-page.component.scss']
})
export class BusinessLandingPageComponent implements OnInit {

  contactNameControl = new FormControl();
  contactPhoneControl = new FormControl();
  contactReasonControl = new FormControl();

  constructor(private agendajaClient: AgendaJaHttpClient,private router: Router,private toastr: ToastrService) { }


  ngOnInit(): void {
  }

  public contactMe(){
    var name = this.contactNameControl.value;
    var contact = this.contactPhoneControl.value+'';
    var reason = this.contactReasonControl.value;
    
    if(contact.trim().length == 0)
      return;

    this.agendajaClient.SendContactRequest(name, contact, reason)
            .subscribe(result => {
              this.toastr.success("Entraremos em contacto contigo o mais rápido possível. Obrigado!","Pedido de contacto enviado!");
              this.contactNameControl.setValue('');
              this.contactPhoneControl.setValue('');
              this.contactReasonControl.setValue('');
            });
  }


}
