import { Component, OnInit } from '@angular/core';
import {NgbConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customers-feedback',
  templateUrl: './customers-feedback.component.html',
  styleUrls: ['./customers-feedback.component.scss']
})
export class CustomersFeedbackComponent implements OnInit {

  isMobileLayout: boolean;

  constructor() { }

  ngOnInit(): void {

    this.isMobileLayout = this.getIsMobile();

     // Checks if screen size is less than 1024 pixels
     window.onresize = () => this.isMobileLayout = window.innerWidth <= 991;
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 992;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }

}
