<style>
  :host {
    font-family: "Montserrat";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<link rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />



<mat-toolbar class="mat-elevation-z8" style="background-color: white; height: 80px;">
  

  <div style="display: inline-block;width: 100%;height: 100px;background-color: white;">
    <app-top-bar></app-top-bar>  
  </div>

  <div style="display: inline-block;background-color: white;">
    <button
      mat-icon-button
      *ngIf="sidenav.mode === 'over'"
      (click)="sidenav.toggle()">
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>
  </div>

</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav position="end" #sidenav="matSidenav" class="mat-elevation-z8">
    <app-shopping-cart-badge></app-shopping-cart-badge>
    <app-session></app-session>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-footer></app-footer>