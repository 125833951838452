<div *ngIf="data.mobileType === 'android'" color="primary">
    <div class="close" (click)="close()">
        <span>✖</span>
    </div>
    <div  class="android-prompt">
        <div class="row" style="margin: 10px;">
            <span>Para uma melhor experiência, instale a aplicação AgendaJá!</span>
            <br>
        </div>
        <div class="row" style="margin: 10px;">
            <button div-button (click)="installPwa()" class="service-button" style="">
                Instalar
            </button>      
        </div>
    </div>
</div>
<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
    <div class="close" (click)="close()">
        <span>✖</span>
    </div>
    
    <div>Para instalar esta aplicação no seu dispositivo, aceda ao botão de Menu 
        <img src="../../../assets/ios/ios-menu-btn.png" style="width: 25px;"> 
        e depois clique em "Adicionar à Tela de Início"
        <img src="../../../assets/ios/ios-add-btn.png" style="width: 25px;">

    </div>
    <div>
        
    </div>
</div>