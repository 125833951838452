<div class="layer"></div>
<ngb-carousel>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper slider-img"
				style="background-image: url('/assets/images/carousel/barbearias.jpg');"
				alt="Agendamento Barbeiro">
		</div>
		<div class="carousel-caption">
			<h3>Barbearias</h3>
			<p>Encontra o barbeiro mais perto de ti!</p>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper slider-img"
				style="background-image: url('/assets/images/carousel/cabeleireiros.jpg');"
				alt="Agendamento Cabeleireiros">
		</div>
		<div class="carousel-caption">
			<h3>Cabeleireiros</h3>
			<p>Agenda Já com a tua Cabeleireira!</p>
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper slider-img" 
			style="background-image: url('/assets/images/carousel/atividades-desportivas.jpg');"
			alt="Agendamento Desporto">
		</div>
		<div class="carousel-caption">
			<h3>Atividades Desportivas</h3>
			<p>Põe os teus amigos à prova e Agenda Já uma atividade!</p>
		</div>
	</ng-template>

</ngb-carousel>