import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AgendaJaHttpClient } from '../http-clients/agendaja/agendaja.apiclient';
import { BaseSession, Session } from '../models/session/session.model';
import { Wallet } from '../models/wallet/wallet.module';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
    readonly _sessionKey :string = "AGENDAJA_SESSION";

    private userSessionSubject= new Subject<BaseSession>();

    constructor(private _localStorageService: LocalStorageService){
    }

    watchSession(): Observable<BaseSession> {
      return this.userSessionSubject.asObservable();
    }

    public Get() : BaseSession | null{
        var sessionObject = this._localStorageService.getObject(this._sessionKey) as Session;
        if(sessionObject != null){
            // if(this.IsValidSession(sessionObject))
            // {
                var session = sessionObject as BaseSession;
                this.userSessionSubject.next(session);
                return session;
            // }
            // else{
            //     this.userSessionSubject.next({} as BaseSession);
            //     this.Clear();
            // }
        }
        return null;
    }

    public UpdateProfilePicture(path:string){
        let sess = this.Get() as Session;
        sess.ProfilePicPath = path;
        this.StoreLocalSession(sess);
    }

    public IsValidSession(sessionObject:Session){
        let dateOne = new Date(sessionObject.StartDate);
        let dateTwo = new Date();
        var difference = Math.floor((dateTwo.getTime() - dateOne.getTime()) / 60000);
        if(difference > 5 || sessionObject.IdUser <= 0)
        {
            return false;
        }
        return true;
    }

    public GetSessionToken() : string{
        var sessionObject = this._localStorageService.getObject(this._sessionKey);
        if(sessionObject != null){
            var session = sessionObject as BaseSession;
            return session.Token;
        }
        return "";
    }

    public GetSessionWallet() : Wallet | null{
        var sessionObject = this._localStorageService.getObject(this._sessionKey);
        if(sessionObject != null){
            var session = sessionObject as BaseSession;
            return session.Wallet;
        }
        return null;
    }
    
    public StoreLocalSession(session: Session){
        if(session == null){
            return;
        }

        if(!session.StartDate){
            session.StartDate = new Date();
        }
        this._localStorageService.setObject(this._sessionKey, session);
        if(session.IdUser > 0){
            this.userSessionSubject.next(session);
        }
    }

    public Clear(){
        this._localStorageService.remove(this._sessionKey);
        this.userSessionSubject.next({} as BaseSession);
    }
   
    public IsUserAuthenticated(){
        var sessionObject = this._localStorageService.getObject(this._sessionKey) as Session;        
        return sessionObject != null && sessionObject.IdUser > 0;
    }
    
}