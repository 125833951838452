import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beauty-news',
  templateUrl: './beauty-news.component.html',
  styleUrls: ['./beauty-news.component.scss']
})
export class BeautyNewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
