import { Component, OnInit } from '@angular/core';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { BaseSession, Session } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';
import {MatDialog} from '@angular/material/dialog';
import { AuthenticationDialogComponent } from '../dialogs/authentication-dialog/authentication-dialog.component';
import { firstValueFrom } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  public UserSession: BaseSession | null = null;
  public profilePicPath: string = "";
  
  constructor(private sessionService: SessionService, public dialog: MatDialog
    , private agendaJaClient: AgendaJaHttpClient, private cartService: CartService
    , private router: Router, public sidenavService: SidenavService) {

      this.sessionService.watchSession().subscribe((data:BaseSession) => {
        this.UserSession = data;
        this.profilePicPath = this.UserSession.ProfilePicPath;
      });
    }

  ngOnInit(): void {
    this.UserSession = this.sessionService.Get();
  }

  openDialog() {
    const dialogRef = this.dialog.open(AuthenticationDialogComponent,
      {autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public async TryLogin(){
    this.openDialog();
  }

  public async LogOut(){
    this.sessionService.Clear();
    this.cartService.ClearCart();
    this.UserSession = null;

    const session = await firstValueFrom(this.agendaJaClient.GetAnonymousSession());
    this.sessionService.StoreLocalSession(session);

    this.toggleSidenav();

    this.router.navigate(['/']);  
  }

  public toggleSidenav() {
    this.sidenavService.toggle();
  }

  public navigateTo(path: string){
    this.toggleSidenav();
    this.router.navigate([path]);  
  }

}
