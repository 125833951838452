import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { CartItem } from 'src/app/models/shopping-cart/shopping-cart-item.model';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  CartItems: CartItem[] = [];

  constructor(private cartService: CartService, private agendaJaClient: AgendaJaHttpClient
    , private router: Router) {
    this.cartService.watchCartItems().subscribe((data:CartItem[]) => {
      this.CartItems = data;
    });
  }

  ngOnInit(): void {
    this.CartItems = this.cartService.GetScheduleCartItems();
  }

  public deleteFromCart(cartItemId: number|null){
    if(cartItemId != null){
      this.agendaJaClient.RemoveItemFromCart(cartItemId)
        .subscribe(cartItemResult => {
          this.cartService.RemoveScheduleCartItem(cartItemId);
        });
    }
  }

  public gotoCheckout(){
    this.router.navigate([`/checkout`]);
  }

  public GetShoppingCartTotal():number{
    let total: number = 0;
    this.CartItems.forEach(el => {
      total += el.Price;
    });
    return total;    
  }

}
