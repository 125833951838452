import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { BaseSession } from 'src/app/models/session/session.model';
import { CartItem } from 'src/app/models/shopping-cart/shopping-cart-item.model';
import { CartService } from 'src/app/services/cart.service';
import { SessionService } from 'src/app/services/session.service';


@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {

  CartItems: CartItem[] = [];
  public termsAgree: boolean = false;
  public policiesAgree: boolean= false;
  public cartIsEmpty: boolean = false;

  public checkoutError: boolean = false;
  public checkoutMessage: string = "";

  public userSession: BaseSession | null;

  constructor(private cartService: CartService, private agendaJaClient: AgendaJaHttpClient
    , private router: Router, private sessionService: SessionService, private titleService:Title) {

    this.cartService.watchCartItems().subscribe((data:CartItem[]) => {
      this.CartItems = data;
    });
    this.sessionService.watchSession().subscribe((data:BaseSession) => {
      this.userSession = data;
    });
  }

  ngOnInit(): void {
    this.CartItems = this.cartService.GetScheduleCartItems();
    if(this.CartItems.length == 0)
    {
      this.cartIsEmpty = true;
    }

    this.userSession = this.sessionService.Get();
  }

  public GetShoppingCartTotal():number{
    let total: number = 0;

    var onlinePaymentItems = this.CartItems.filter(it => it.OnlinePayment);

    onlinePaymentItems.forEach(el => {
      total += el.Price;
    });
    return total;    
  }

  public IsUserAuthenticated(){
    return this.sessionService.IsUserAuthenticated();
  } 

  public deleteFromCart(cartItemId: number|null){
    if(cartItemId != null){
      this.agendaJaClient.RemoveItemFromCart(cartItemId)
        .subscribe(cartItemResult => {
          this.cartService.RemoveScheduleCartItem(cartItemId);

          if(this.CartItems.length == 0)
          {
            this.cartIsEmpty = true;
          }
      
        });
    }
  }

  public Checkout(){
    this.checkoutError = false;
    
    if(!this.termsAgree || !this.policiesAgree){
      this.checkoutMessage = "Deve aceitar os termos e condiçoes assim como as políticas de cancelamento para continuar.";
      this.checkoutError = true;
      return;
    }

    this.agendaJaClient.Checkout()
      .subscribe(
        {
          next: async (checkoutResult:any) => {
            if(checkoutResult.Item2 > 0) {
              this.cartService.ClearCart();
              const session = await firstValueFrom(this.agendaJaClient.SessionRefresh());     
              this.sessionService.StoreLocalSession(session);  
              this.router.navigate([`/checkout/success/${checkoutResult.Item1}`]);  
            }
          },
          error: (err:any) => {
            this.checkoutError = true;

            if(err.error.Item2 == -2){
              this.checkoutMessage = "O saldo em carteira não é suficiente para efetuar o checkout.";
            }
            else{
              this.checkoutMessage = err.error;
            }
          }
    });
  }
  
  public CheckoutExternal(){
    this.router.navigate(['/checkout/pay']);
  }

  public goto(page:string){
    switch(page){
      case "home":
        this.router.navigate(['/']);
        break;
    }
  }

}
