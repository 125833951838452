<!-- routerLink="/company/{{service.IdCompany}}/service/{{service.Id}}" -->
<div class="card" (click)="goTo(service.IdCompany, service.Id)">
    <div (click)="goTo(service.IdCompany, service.Id)">
        <img class="service-image" src="{{service.Path}}" />
    </div>
    <div class="service-info" (click)="goTo(service.IdCompany, service.Id)">
        <div class="service-name">{{service.ServiceName}}</div>
        <div *ngIf="service.Price > 0" class="service-price">{{ getPrice(service) }}€</div>
        <div *ngIf="service.IsOnlinePayment" class="service-store-price-warn">Pagamento online</div>
        <div *ngIf="service.StorePrice > 0" class="service-store-price">{{ getRoundedValue(service.StorePrice) }}€</div>
        <div *ngIf="!service.IsOnlinePayment" class="service-store-price-warn">Pagamento em loja</div>
    </div>
    <input type="button" class="service-button" value="Agenda Já" (click)="goTo(service.IdCompany, service.Id)">
    <!-- <div class="service-district">{{service.StorePrice}}</div> -->
</div>

