<app-user-header></app-user-header>

<div class="container">

    <mat-tab-group>
        <mat-tab label="Dados Pessoais">
            <div class="tab-head">
                <h4>Alterar Dados Pessoais</h4>
            </div>

            <div class="container container-registration">
                <div *ngIf="_requesting">
                    <mat-spinner style="margin: 0 auto;"></mat-spinner>
                </div>

                <div class="row" *ngIf="!_requesting">
                    <mat-stepper orientation="vertical" [linear]="false" #stepper>
                        <mat-step [stepControl]="personalDataFormGroup">
                            <form [formGroup]="personalDataFormGroup">
                                <ng-template matStepLabel>Dados Pessoais</ng-template>

                                <mat-form-field class="form-full-width">
                                    <mat-label>Género</mat-label>
                                    <mat-select formControlName="genreNameCtrl" id="genre"
                                        [(ngModel)]="userData.IdGenre" [value]="userData.IdGenre"
                                        placeholder="Senhor/Senhora" required>
                                        <mat-option [value]="1">Senhor</mat-option>
                                        <mat-option value="2">Senhora</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br>

                                <mat-form-field class="form-full-width">
                                    <mat-label>Nome</mat-label>
                                    <input matInput placeholder="Nome" id="name" formControlName="firstNameCtrl"
                                        required [(ngModel)]="userData.FirstName">
                                </mat-form-field>
                                <br>
                                <mat-form-field class="form-full-width">
                                    <mat-label>Apelido</mat-label>
                                    <input matInput placeholder="Apelido" formControlName="lastNameCtrl" required
                                        [(ngModel)]="userData.LastName">
                                </mat-form-field>
                                <br>

                                <mat-form-field class="form-full-width">
                                    <mat-label>Data Nascimento</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="birthDateCtrl" required
                                        [(ngModel)]="birthDate" style="width: 100px;">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                                <br>
                                <br>
                                <mat-form-field class="form-full-width">
                                    <mat-label>NIF</mat-label>
                                    <input matInput placeholder="NIF" formControlName="taxIdCtrl" required
                                        [(ngModel)]="userData.TaxId">
                                </mat-form-field>

                                <div class="stepper-actions">
                                    <button mat-button mat-flat-button color="primary" matStepperNext
                                        class="position-right">Seguinte</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="addressFormGroup" label="Morada">
                            <form [formGroup]="addressFormGroup">
                                <mat-form-field class="form-full-width">
                                    <mat-label>Morada</mat-label>
                                    <input matInput formControlName="addressCtrl" placeholder="Ex. Av. Brasília, Lisboa"
                                        required [(ngModel)]="userData.Address">
                                </mat-form-field>
                                <br>
                                <mat-form-field class="form-full-width">
                                    <mat-label>Código Postal</mat-label>
                                    <input matInput formControlName="zipCodeCtrl" placeholder="1400" required
                                        [(ngModel)]="userData.ZipCode">
                                </mat-form-field>
                                <mat-form-field class="form-full-width">
                                    <mat-label>Extensão</mat-label>
                                    <input matInput formControlName="zipCodeExtCtrl" placeholder="300" required
                                        [(ngModel)]="userData.ZipCodeExt">
                                </mat-form-field>

                                <div class="stepper-actions">
                                    <button mat-button mat-flat-button color="primary" matStepperNext
                                        class="position-right">Seguinte</button>
                                    <button mat-button color="primary" matStepperPrevious
                                        class="position-right">Voltar</button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="completeFormGroup" label="Confirmação">
                            <form [formGroup]="completeFormGroup">
                                <mat-form-field class="form-full-width">
                                    <mat-label>Telemóvel</mat-label>
                                    <input matInput placeholder="Telemóvel" formControlName="phoneCtrl" required
                                        [(ngModel)]="userData.PhoneNumber">
                                </mat-form-field>
                                <br>
                                <mat-form-field class="form-full-width">
                                    <mat-label>E-mail</mat-label>
                                    <input matInput placeholder="E-mail" formControlName="emailCtrl" required
                                        [(ngModel)]="userData.Email">
                                </mat-form-field>
                                <br>

                            </form>

                            <div class="notificaitons-wrapper">
                                <mat-checkbox [(ngModel)]="userData.SMSNotification">
                                    Quero receber notificações via SMS
                                </mat-checkbox>
                            </div>

                            <div class="notifications-wrapper">
                                <mat-checkbox [(ngModel)]="userData.EmailNotification">
                                    Quero receber notificações via E-mail</mat-checkbox>
                            </div>

                            <div class="stepper-actions">
                                <button mat-button mat-flat-button class="complete-profile-update position-right"
                                    (click)="SaveProfile()">
                                    Atualizar
                                </button>
                                <button mat-button color="primary" matStepperPrevious
                                    class="position-right">Voltar</button>
                            </div>

                        </mat-step>
                    </mat-stepper>

                </div>

                <div class="row">
                    <div *ngIf="usardataSaved" class="alert alert-success checkout-success" role="alert">
                        <span>Dados guardados com sucesso!</span>
                    </div>
                </div>

                <div class="row">
                    <div *ngIf="userDataIsNotCorrect" class="alert alert-danger checkout-error" role="alert">
                        <span>Existem dados incorretos.</span>
                    </div>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="Alterar Password">
            <div class="tab-head">
                <h4>Alterar Password</h4>
            </div>

            <div *ngIf="_requestingPasswordChange">
                <mat-spinner style="margin: 0 auto;"></mat-spinner>
            </div>


            <div *ngIf="!_requestingPasswordChange" class="password-change-container container">
                <form [formGroup]="passwordChageFormGroup">
                    <mat-form-field class="example-full-width">
                        <mat-label>Password atual</mat-label>
                        <input matInput placeholder="Password atual" type="password"
                            formControlName="currentPasswordCtrl"
                           [(ngModel)]="passwordChageData.OldPassword">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="example-full-width">
                        <mat-label>Nova password</mat-label>
                        <input matInput placeholder="Nova password" type="password"
                            formControlName="newPasswordCtrl"
                            [(ngModel)]="passwordChageData.NewPassword">
                    </mat-form-field>
                    <br>
                    <mat-form-field class="example-full-width">
                        <mat-label>Confirmar password</mat-label>
                        <input matInput placeholder="Confirmar password" type="password"
                        formControlName="confirmNewPasswordCtrl"
                        [(ngModel)]="confirmPassword">
                    </mat-form-field>
                    <br>
                    <div>
                        <small class="text-danger" *ngIf="
                                    (
                                        passwordChageFormGroup.get('newPasswordCtrl')?.dirty || passwordChageFormGroup.get('newPasswordCtrl')?.touched
                                     || passwordChageFormGroup.get('confirmNewPasswordCtrl')?.dirty || passwordChageFormGroup.get('confirmNewPasswordCtrl')?.touched
                                    )
                                    &&
                                    passwordChageFormGroup.hasError('match_error')">
                            As passwords introduzidas não são iguais.
                        </small>
                    </div>

                    <div class="password-actions">
                        <button mat-button mat-flat-button class="complete-profile-update position-right"
                        [disabled]="!this.passwordChageFormGroup.valid"
                        (click)="changePassword()">
                            Alterar Password
                        </button>
                    </div>
                </form>
                <br>
                <div class="row">
                    <div *ngIf="passwordSuccessfullyUpdated" class="alert alert-success checkout-success" role="alert">
                        <span>Password alterada com sucesso!</span>
                    </div>
                </div>

                <div class="row">
                    <div *ngIf="passwordNotUpadted" class="alert alert-danger checkout-error" role="alert">
                        <span>{{ passwordUpdatedErrorMessage }}</span>
                    </div>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="RGPD">
            <div class="tab-head">
                <h4>RGPD</h4>
                <p>Segundo a lei do RGPD em vigor, o utilizador tem o direito à remoção dos dados pessoais da plataforma.</p>
                <p>Caso queira usufruir desse seu direito, deve proceder com a exclusão da sua conta através da ação disponibilizada abaixo.</p>
                <p>Ao remover a sua conta, deve ter em conta que:</p>
                <ul>
                    <li>Os seus dados pessoais serão removidos</li>
                    <li>Se tiver agendamentos efetuados eles permanecerão ativos, no entanto, o prestador de serviço não conseguirá identificar que o agendamento pertence a sí</li>
                    <li>Se tiver algum valor monetário em voucher na sua conta, solicite primeiramente o reembolso, caso contrário não será possível efetuar o reembolso.</li>
                </ul>
                <br>
                <div>
                    <mat-checkbox [(ngModel)]="acceptedRemove">Confirmo que estou consciente da minha vontade, li e aceito os termos e condições</mat-checkbox>
                    <br><br>
                    <button [disabled]="!acceptedRemove" mat-flat-button color="warn" class="login-button" (click)="RemoveAccount()">Remover a minha conta</button>    
                </div>
                <br>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>