import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements OnInit {

  public code:string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.code = String(routeParams.get('code'));
  }

  public goto(page:string){
    switch(page){
      case "home":
        this.router.navigate(['/']);
        break;
      case "agenda":
          this.router.navigate(['/user/agenda']);
          break;
      }
  }

}
