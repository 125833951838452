import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';
import { BaseSession } from 'src/app/models/session/session.model';
import { SessionService } from 'src/app/services/session.service';
import { PayWalletDialogComponent } from '../../dialogs/pay-wallet-dialog/pay-wallet-dialog.component';

@Component({
  selector: 'app-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.scss']
})
export class UserWalletComponent implements OnInit {
  public UserSession: BaseSession | null = null;

  constructor(private sessionService: SessionService, private agendaJaClient: AgendaJaHttpClient, public dialog: MatDialog) { 
    this.sessionService.watchSession().subscribe((data:BaseSession) => {
      this.UserSession = data;
    });
  }

  ngOnInit(): void {

  }

  openDialogWallet(){
    const dialogRef = this.dialog.open(PayWalletDialogComponent,
      {autoFocus: false
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
