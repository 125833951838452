<div class="container">
    <h3>Termos de Serviço</h3>

    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>Por favor leia atentamente estes termos e condi&ccedil;&otilde;es
            (&ldquo;Termos e Condi&ccedil;&otilde;es&rdquo;) antes de utilizar a plataforma eletr&oacute;nica
            (&ldquo;Plataforma&rdquo;) e solicitar servi&ccedil;os atrav&eacute;s da mesma. A utiliza&ccedil;&atilde;o
            da Plataforma para solicitar servi&ccedil;os ser&aacute; entendida como uma aceita&ccedil;&atilde;o integral
            dos presentes Termos e Condi&ccedil;&otilde;es.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>1. Termos e
                Condi&ccedil;&otilde;es</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.1. Estes Termos e Condi&ccedil;&otilde;es fornecem-lhe as regras de
            utiliza&ccedil;&atilde;o da nossa Plataforma, a qual poder&aacute; ser acedida atrav&eacute;s
            de&nbsp;</span><a href="http://www.AgendaJa.pt/"><span
                style='font-family:"Special Elite";color:windowtext;text-decoration:none;'>www.AgendaJa.pt</span></a><span
            style='font-family:"Special Elite";'>&nbsp;ou atrav&eacute;s da aplica&ccedil;&atilde;o dispon&iacute;vel na
            App Store da Apple ou no Google Play.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.2. Os presentes Termos e Condi&ccedil;&otilde;es cont&ecirc;m ainda
            a informa&ccedil;&atilde;o pr&eacute;-contratual obrigat&oacute;ria nos termos da lei
            aplic&aacute;vel.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.3. A contrata&ccedil;&atilde;o atrav&eacute;s da Plataforma
            ser&aacute; uma contrata&ccedil;&atilde;o eletr&oacute;nica, nos termos do disposto nos artigos 26.&ordm; do
            Decreto-Lei n.&ordm; 7/2004, de 7 de Janeiro (na sua reda&ccedil;&atilde;o actual), e 3.&ordm; do
            Decreto-Lei n.&ordm; 290-D/99, de 2 de Agosto, e cumpre o disposto no Decreto-Lei n.&ordm; 24/2014, de 14 de
            Fevereiro.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>Para solicitar servi&ccedil;os atrav&eacute;s da Plataforma,
            ter&aacute; de clicar num bot&atilde;o de aceita&ccedil;&atilde;o dos presentes Termos e
            Condi&ccedil;&otilde;es. Caso n&atilde;o concorde com a celebra&ccedil;&atilde;o de contratos por meios
            eletr&oacute;nicos, n&atilde;o dever&aacute; utilizar a Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.4. Na Plataforma, o Utilizador tem &agrave; sua
            disposi&ccedil;&atilde;o uma funcionalidade que lhe permite imprimir ou armazenar as
            informa&ccedil;&otilde;es sobre os Servi&ccedil;os e os Termos e Condi&ccedil;&otilde;es. Recomendamos que
            guarde uma c&oacute;pia destes Termos e Condi&ccedil;&otilde;es num suporte para que possa
            consult&aacute;-los sempre que desejar.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.5. O AGENDAJ&Aacute; pode a qualquer momento alterar os Termos e
            Condi&ccedil;&otilde;es. Sempre que estes Termos e Condi&ccedil;&otilde;es forem alterados, poder&aacute;
            visualizar quando aceder &agrave; Plataforma em Termos e Condi&ccedil;&otilde;es.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>1.6. Se as altera&ccedil;&otilde;es aos Termos e
            Condi&ccedil;&otilde;es forem consideradas pelo AGENDAJ&Aacute; particularmente significativas, ser&aacute;
            enviado um e-mail a informar da altera&ccedil;&atilde;o a todos os utilizadores registados na
            Plataforma.</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: decimal;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>Cada vez que utilizar a Plataforma por favor verifique os
                    Termos e Condi&ccedil;&otilde;es e assegure que os compreende.</span></li>
        </ol>
    </div>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>2. Quem somos e
                como nos pode contactar</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>2.1. A Plataforma &eacute; gerida pela VAINEFFECTS, LDA, com sede na
            Rua Jo&atilde;o Lucio Azevedo n&ordm;53 , com o capital social de 200,00&euro; e com o NIF 516270303.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>2.2. A VAINEFFECTS &eacute; a empresa respons&aacute;vel pelo
            AGENDAJ&Aacute;, uma empresa que se dedica, &agrave; presta&ccedil;&atilde;o de servi&ccedil;os de
            intermedia&ccedil;&atilde;o e Agendamento de Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
            outros profissionais de Presta&ccedil;&atilde;o de todo o tipo de Servi&ccedil;os.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>3. Outras
                pol&iacute;ticas que podem ser-lhe aplic&aacute;veis</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>3.1. Estes Termos e Condi&ccedil;&otilde;es fazem refer&ecirc;ncia a
            outras pol&iacute;ticas que est&atilde;o. incorporadas nestes Termos e Condi&ccedil;&otilde;es por
            remiss&atilde;o, nomeadamente:</span></p>
    <ul class="decimal_type" style="list-style-type: undefined;margin-left:0cmundefined;">
        <li><span style='font-family:"Special Elite";color:black;'>A Pol&iacute;tica de Privacidade do AGENDAJ&Aacute;
                est&aacute; dispon&iacute;vel em http://agendaja.local/policies/terms.aspx que estabelece a forma como
                recolhemos dados pessoais sobre os utilizadores e como tratamos as informa&ccedil;&otilde;es que nos
                fornece.</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>A Pol&iacute;tica de Cookies do AGENDAJ&Aacute;
                dispon&iacute;vel em http://agendaja.local/policies/terms.aspx que esclarece os Cookies que instalamos
                no seu dispositivo, para que servem.</span></li>
    </ul>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>3.2. Quando aceitar os presentes Termos e Condi&ccedil;&otilde;es,
            reconhece perfeitamente que leu e concordou com a Pol&iacute;tica de Privacidade e a Pol&iacute;tica de
            Cookies da Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>4.
                Descri&ccedil;&atilde;o dos Servi&ccedil;os</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>4.1. A Plataforma permite a conex&atilde;o entre Pessoas
            Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
            Servi&ccedil;os, atuando o AGENDAJ&Aacute; como agente de intermedia&ccedil;&atilde;o. S&atilde;o
            &ldquo;Utilizadores/Clientes&rdquo; as pessoas que procuram obter a presta&ccedil;&atilde;o de
            servi&ccedil;os, a serem prestados por Pessoas Aut&oacute;nomas e/ou Freelancers de Servi&ccedil;os e/ou
            outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os, que procuram prestar todo o tipo de
            servi&ccedil;os d&ecirc;s de beleza a repara&ccedil;&otilde;es (incluindo de cabeleireiro, mecanicos,
            aluguer de campos para futebol e muito outros tipos de servi&ccedil;os).</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>O Utilizador reconhece e aceita que o AGENDAJ&Aacute;
                    n&atilde;o presta diretamente os servi&ccedil;os, mas apenas gere uma plataforma que possibilita a
                    marca&ccedil;&atilde;o e obten&ccedil;&atilde;o desses servi&ccedil;os, os quais ser&atilde;o
                    prestados por Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                    Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES. Assim Sendo <span
                        style="color:#111111;">Na qualidade de fornecedor da Plataforma do AGENDAJ&Aacute;, o
                        AGENDAJ&Aacute; n&atilde;o possui, cria, vende, revende, fornece, controla, gere, oferece,
                        disponibiliza ou presta quaisquer Servi&ccedil;os, nem o AGENDAJ&Aacute; &eacute; um organizador
                        ou revendedor de servi&ccedil;os. As Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
                        outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES s&atilde;o o
                        &uacute;nico respons&aacute;vel pelos seus An&uacute;ncios e Servi&ccedil;os e
                        Fatura&ccedil;&otilde;es. Ao efetuarem ou aceitarem uma marca&ccedil;&atilde;o, o UTILIZADOR e
                        As Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                        Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES est&atilde;o a celebrar um acordo
                        diretamente entre si. O AGENDAJ&Aacute; n&atilde;o &eacute; e n&atilde;o ser&aacute; parte
                        contratante ou participar&aacute; de alguma forma em qualquer rela&ccedil;&atilde;o contratual
                        entre os mesmos. O AGENDAJ&Aacute; n&atilde;o atua na qualidade de agente de um Utilizador ou
                        das Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                        Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES.</span></span></li>
        </ol>
    </div>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>4.3. O AGENDAJ&Aacute; verifica cada <span
                style="color:#111111;">UTILIZADOR e Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES</span> na Plataforma. No
            entanto, o AGENDAJ&Aacute; n&atilde;o &eacute; respons&aacute;vel por eventuais declara&ccedil;&otilde;es
            falsas ou documentos falsos fornecidos, pelo que n&atilde;o garante a fidedignidade das
            informa&ccedil;&otilde;es. <span style="color:#111111;">A verifica&ccedil;&atilde;o do utilizador na
                Internet &eacute; dif&iacute;cil, pelo que n&atilde;o assumimos qualquer responsabilidade pela
                confirma&ccedil;&atilde;o da identidade de qualquer UTILIZADOR e Pessoas Aut&oacute;nomas e Freelancers
                de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES.
                N&atilde;o obstante o que precede, para efeitos de transpar&ecirc;ncia e preven&ccedil;&atilde;o de
                fraude e de acordo com o permitido pela legisla&ccedil;&atilde;o aplic&aacute;vel, poderemos, embora
                n&atilde;o estejamos obrigados a pedir aos UTILIZADORES e Pessoas Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES que
                facultem uma forma de identifica&ccedil;&atilde;o emitida pelo governo ou outra informa&ccedil;&atilde;o
                ou que procedam a verifica&ccedil;&otilde;es complementares concebidas para ajudar a verificar as
                entidades ou os antecedentes, verificar os utilizadores e as Pessoas Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES nas
                bases de dados de terceiros ou outras fontes e solicitar relat&oacute;rios dos prestadores de
                servi&ccedil;os, nos casos em que dispomos de informa&ccedil;&atilde;o suficiente para identificar um
                utilizadores e as Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                Presta&ccedil;&atilde;o de Servi&ccedil;os INDEPENDENTES, obter relat&oacute;rios de registos
                p&uacute;blicos de condena&ccedil;&otilde;es penais ou registos de crimes sexuais, ou uma vers&atilde;o
                equivalente dos antecedentes ou verifica&ccedil;&otilde;es de crimes sexuais registados no seu local de
                jurisdi&ccedil;&atilde;o (se dispon&iacute;veis)</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>4.4. Apesar de entrevistar <span style="color:#111111;">as Pessoas
                Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
                Servi&ccedil;os INDEPENDENTES</span> registadas na Plataforma, o AGENDAJ&Aacute; n&atilde;o &eacute;
            respons&aacute;vel, n&atilde;o tem controlo, nem presta qualquer declara&ccedil;&atilde;o ou garantia sobre
            a presta&ccedil;&atilde;o desses servi&ccedil;os por parte de <span style="color:#111111;">utilizadores e as
                Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span>, bem como sobre a qualidade,
            pontualidade, legalidade ou qualquer outro aspeto relacionado com os Servi&ccedil;os.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>4.5. Os Utilizadores t&ecirc;m a possibilidade de Pr&eacute;-avaliar
            a qualidade dos Servi&ccedil;os prestados pela Plataforma. Mas o AGENDAJ&Aacute; n&atilde;o garante a sua
            qualidade.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>O Utilizador reconhece e aceita que usar&aacute; os Servi&ccedil;os
            d<span style="color:#111111;">as Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span> por sua conta e risco.
            Ao interagir com <span style="color:#111111;">as Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
                outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span>, deve agir como
            em qualquer outra situa&ccedil;&atilde;o em que n&atilde;o conhece a outra pessoa, ou seja, atuar com
            prud&ecirc;ncia e bom senso e tomar as medidas que considerar necess&aacute;rias para proteger a sua
            seguran&ccedil;a e bens pessoais.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>5. Requisitos de
                acesso &agrave; Plataforma Utilizador e Empresas</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>5.1. O acesso &agrave; Plataforma requer a disponibilidade de um
            dispositivo com liga&ccedil;&atilde;o &agrave; Internet para assegurar a interoperabilidade dos
            conte&uacute;dos digitais da Plataforma. <strong><span style="color:#111111;">O uso da Plataforma
                    AGENDAJ&Aacute; &eacute; aconselh&aacute;vel para idades igual ou superior a 12
                    anos.</span></strong></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>5.2. Os Servi&ccedil;os s&oacute; podem ser solicitados e agendados
            atrav&eacute;s da Plataforma por pessoas singulares ou coletivas.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>5.3. O AGENDAJ&Aacute; n&atilde;o verifica a capacidade
            jur&iacute;dica dos Utilizadores. O AGENDAJ&Aacute; n&atilde;o se responsabiliza por qualquer
            consequ&ecirc;ncia resultante da falta de capacidade jur&iacute;dica do Utilizador e d<span
                style="color:#111111;">as Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:#111111;'>5.4 As Pessoas Aut&oacute;nomas e Freelancers de
            Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes aderem a
            Plataforma AGENDAJ&Aacute; por meio de uma subscri&ccedil;&atilde;o e percentagem Mensal Base acordada e
            Negociada entre ambos.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <strong><span style='font-family:"Special Elite";'>Medidas para menores usarem o AGENDAJ&Aacute; com
                seguran&ccedil;a</span></strong></p>
    <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
            style='font-family:"Special Elite";color:#111111;'>O uso da Plataforma AGENDAJ&Aacute; &eacute;
            aconselh&aacute;vel para idades igual ou superior a 12 anos. Trabalhamos arduamente para ajudar a manter as
            pessoas em seguran&ccedil;a no AGENDAJ&Aacute;. Relativamente aos menores, informamos pelos termos de
            seguran&ccedil;a todos os cuidados a ter. E relembramos que est&atilde;o a usar dados espec&iacute;ficos
            numa plataforma e para ter aten&ccedil;&atilde;o e ter uma perfeita verifica&ccedil;&atilde;o das
            intera&ccedil;&otilde;es com estranhos e &nbsp; Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
            outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes.</span></p>
    <p
        style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:0cm;'>
        <span style='font-family:"Special Elite";color:#111111;'>Tamb&eacute;m protegemos as informa&ccedil;&otilde;es
            confidenciais do menor ou adulto, como as informa&ccedil;&otilde;es de idade, para que n&atilde;o surjam em
            informa&ccedil;&otilde;es. Al&eacute;m disso, relembramos aos menores de que apenas devem aceitar
            servi&ccedil;os de Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
            Presta&ccedil;&atilde;o de Servi&ccedil;os independentes, que conhe&ccedil;am e que tenham a perfeita
            confian&ccedil;a.</span></p>
    <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                style='font-family:"Special Elite";color:#111111;'>Consoante a idade do menor o tutor, podes ler os
                termos e as pol&iacute;ticas de privacidade e concluir se aceita ou n&atilde;o os mesmo
                termos.</span></strong></p>
    <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><strong><span
                style='font-family:"Special Elite";color:#111111;'>Independentemente da idade do seu filho, recomendamos
                que a utiliza&ccedil;&atilde;o respons&aacute;vel do AGENDAJ&Aacute;, fa&ccedil;a parte das vossas da
                tecnologia. Conversa sobre as tuas expectativas, sobre o seu comportamento online e ajuda-o a
                compreender o que &eacute; seguro. Certifica-te de que o teu filho compreende os exemplos b&aacute;sicos
                da seguran&ccedil;a na Internet:</span></strong></p>
    <ul style="list-style-type: undefined;margin-left:0cmundefined;">
        <li><span style='font-family:"Special Elite";color:#111111;'>Nunca partilhes a tua palavra-passe;</span></li>
        <li><span style='font-family:"Special Elite";color:#111111;'>Pensa e Pergunta bem antes de reservares
                algo;</span></li>
        <li><span style='font-family:"Special Elite";color:#111111;'>Agenda os Servi&ccedil;os apenas que conheces
                pessoalmente;</span></li>
        <li><span style='font-family:"Special Elite";color:#111111;'>Denuncia tudo o que for suspeito.</span></li>
    </ul>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>6.
                Cria&ccedil;&atilde;o de Contas na Plataforma</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>6.1. De forma a requisitar Servi&ccedil;os atrav&eacute;s da
            Plataforma AGENDAJ&Aacute;, o Utilizador poder&aacute; optar por criar uma conta na Plataforma. A
            cria&ccedil;&atilde;o de uma conta &eacute; gratuita. O registo na Plataforma encontra-se univocamente
            associado ao endere&ccedil;o de e-mail fornecido pelo Utilizador ou &agrave; conta FaceBook&reg; que o
            Utilizador utiliza para efetuar o registo.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>6.2. A cada Utilizador apenas pode corresponder um registo. O
            AGENDAJ&Aacute; poder&aacute; cancelar qualquer registo subsequente efetuado pelo mesmo Utilizador.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>6.3. Para criar uma conta na Plataforma, o Utilizador dever&aacute;
            seguir os passos obrigat&oacute;rios indicados automaticamente na Plataforma.<br>&nbsp;O Utilizador pode,
            por sua livre iniciativa, adicionar dados complementares ao seu registo, como, por exemplo, fotografias,
            data de nascimento, sexo, dados banc&aacute;rios e informa&ccedil;&atilde;o para feitos de
            fatura&ccedil;&atilde;o.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>6.4. Ap&oacute;s o registo, o Utilizador passa a ser titular de um
            c&oacute;digo de acesso &agrave; sua conta (Login e Password), sendo a conta do Utilizador pessoal e
            intransmiss&iacute;vel. O titular da conta &eacute; o &uacute;nico respons&aacute;vel pelas
            a&ccedil;&otilde;es efetuadas com o seu registo.</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>O Utilizador pode, a qualquer momento, solicitar o cancelamento
                    da sua conta na Plataforma. O AGENDAJ&Aacute; tamb&eacute;m pode, a qualquer momento, cancelar ou
                    eliminar, de forma tempor&aacute;ria ou permanente, a conta do Utilizador se este: (i) n&atilde;o
                    cumprir os presentes Termos e Condi&ccedil;&otilde;es; (ii) ceder a sua posi&ccedil;&atilde;o
                    contratual a terceiros sem a pr&eacute;via autoriza&ccedil;&atilde;o por escrito do AGENDAJ&Aacute;
                    e/ou (iii) atuar de forma a acarretar preju&iacute;zos para O AGENDJ&Aacute; ou <span
                        style="color:#111111;">utilizadores ou as Pessoas Aut&oacute;nomas e Freelancers de
                        Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                        independentes</span>.</span></li>
        </ol>
    </div>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:#111111;'>6.6 As Pessoas Aut&oacute;nomas e Freelancers de
            Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes aderem a
            Plataforma AGENDAJ&Aacute; por meio de uma subscri&ccedil;&atilde;o e percentagem Mensal Base acordada e
            Negociada entre ambos.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>7.
                Requisi&ccedil;&atilde;o de Servi&ccedil;os</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.1.Ap&oacute;s a cria&ccedil;&atilde;o de uma conta na Plataforma, o
            Utilizador ter&aacute; acesso &agrave;s seguintes informa&ccedil;&otilde;es:</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>a) Caracter&iacute;sticas essenciais dos Servi&ccedil;os
            dispon&iacute;veis para efeitos de contrata&ccedil;&atilde;o;<br> b) Pre&ccedil;o total, incluindo taxas e
            impostos de cada Servi&ccedil;o dispon&iacute;vel;<br> c) Identifica&ccedil;&atilde;o sucinta das<span
                style="color:#111111;">&nbsp;Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span> registadas na
            Plataforma, os Servi&ccedil;os que cada uma presta e as zonas geogr&aacute;ficas abrangidas;<br> d)
            Disponibilidade de cada<span style="color:#111111;">&nbsp;Pessoa Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span>.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>c) Carregamento da Carteira de Voucher de Finalidade M&uacute;ltipla
            (</span><span style='font-family:"Arial\, Helvetica\, sans-serif";color:#222222;'>N&Atilde;O SUJEITO A IVA
            AO ABRIGO DO ART&ordm;7&ordm; N&ordm;14 DO CIVA</span><span style='font-family:"Special Elite";'>&nbsp;),
            para utilizar em diferentes servi&ccedil;os, com diferentes PRE&Ccedil;OS e respetivos IVA.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.2. Durante o procedimento de requisi&ccedil;&atilde;o de
            Servi&ccedil;os ser&aacute; facultada informa&ccedil;&atilde;o pr&eacute;-contratual que o Utilizador deve
            conhecer, pelo que devem ser lidos cuidadosamente, podendo o Utilizador, em caso de d&uacute;vida, contactar
            o AGENDAJ&Aacute;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.3. O Utilizador s&oacute; poder&aacute; requisitar um
            Servi&ccedil;o atrav&eacute;s da Plataforma se aceitar os Termos e Condi&ccedil;&otilde;es, assinalando
            obrigatoriamente o campo &ldquo;Declaro saber que a requisi&ccedil;&atilde;o de Servi&ccedil;os nesta
            Plataforma se encontra sujeita aos Termos e Condi&ccedil;&otilde;es aqui enunciados, os quais li, compreendi
            e aceito&rdquo;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.6. Para finalizar a requisi&ccedil;&atilde;o de Servi&ccedil;os, o
            Utilizador dever&aacute; rever cuidadosamente a informa&ccedil;&atilde;o sobre o Servi&ccedil;o que pretende
            contratar e, se estiver tudo em conformidade, ativar o bot&atilde;o &ldquo;AGENDAR&rdquo; e
            &nbsp;ser&aacute; feito a Confirma&ccedil;&atilde;o do agendamento do Servi&ccedil;o com
            Obriga&ccedil;&atilde;o de Pagar, reconhecendo que essa a&ccedil;&atilde;o implica uma
            obriga&ccedil;&atilde;o de pagamento.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.7. Ap&oacute;s a requisi&ccedil;&atilde;o dos Servi&ccedil;os
            ser&aacute; enviado para o endere&ccedil;o de correio eletr&oacute;nico disponibilizado pelo Utilizador um
            E-MAIL a confirmar a rece&ccedil;&atilde;o da solicita&ccedil;&atilde;o do Servi&ccedil;o pretendido, bem
            como c&oacute;pia dos Termos e Condi&ccedil;&otilde;es.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.8. A Confirma&ccedil;&atilde;o de Marca&ccedil;&atilde;o &eacute;
            na Hora do Agendamento e ser&aacute; debitado (cativo) o valor da sua Sua Carteira (Voucher de Finalidade
            M&uacute;ltipla) de Cliente, mas s&oacute; ser&aacute; retirado (debitado) o valor final e respetivas ordens
            legais como IVA quando tudo for confirmado automaticamente pelo o AGENDAJ&Aacute; no dia da Reserva.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.9. Com a Confirma&ccedil;&atilde;o de Marca&ccedil;&atilde;o fica
            celebrado o contrato de presta&ccedil;&atilde;o de servi&ccedil;os entre o Utilizador e<span
                style="color:#111111;">&nbsp;as Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>7.10. O Utilizador compromete-se a n&atilde;o requisitar nenhum
            Servi&ccedil;o com qualquer <span style="color:#111111;">Pessoa Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span> por outra via.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>Na eventualidade de existir algum problema com o agendamento ou
                    a disponibilidade das<span style="color:#111111;">&nbsp;Pessoas Aut&oacute;nomas e Freelancers de
                        Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                        independentes</span>, o Utilizador ser&aacute; imediatamente contactado pela
                    AGENDAJ&Aacute;.</span></li>
        </ol>
    </div>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>8. Pre&ccedil;o
                Utilizador e Empresas</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>8.1. A utiliza&ccedil;&atilde;o da Plataforma &eacute;
            gratuita.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>8.2. Os pre&ccedil;os dos Servi&ccedil;os indicados na Plataforma
            j&aacute; incluem o IVA &agrave; taxa legal aplic&aacute;vel definidos pelas <span
                style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais
                de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span>.</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>Os pre&ccedil;os da presta&ccedil;&atilde;o de Servi&ccedil;os
                    s&atilde;o fixados em euros ou outra moeda desse Pa&iacute;s e os pagamentos devem ser realizados
                    nessas moedas. Os pre&ccedil;os indicados na Plataforma poder&atilde;o alterar-se a qualquer
                    momento, pelo que cabe ao Utilizador verificar o pre&ccedil;o do Servi&ccedil;o antes de cada ato de
                    contrata&ccedil;&atilde;o.</span></li>
        </ol>
    </div>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>8.4. Os Pre&ccedil;os para <span style="color:#111111;">As Pessoas
                Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
                Servi&ccedil;os independentes aderem a Plataforma AGENDAJ&Aacute; por meio de uma
                subscri&ccedil;&atilde;o e percentagem Mensal Base acordada e Negociada entre ambos.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>9. Meios de
                Pagamento Aceites</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.1. A Plataforma coloca &agrave; disposi&ccedil;&atilde;o do
            Utilizador os seguintes meios de pagamento:</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>a) Carteira Cliente de Voucher de Finalidade M&uacute;ltipla
            (</span><span style='font-family:"Arial\, Helvetica\, sans-serif";color:#222222;'>N&Atilde;O SUJEITO A IVA
            AO ABRIGO DO ART&ordm;7&ordm; N&ordm;14 DO CIVA</span><span
            style='font-family:"Special Elite";'>&nbsp;);</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.2. O processamento de pagamentos/carregamento da Carteira Cliente
            de Voucher de Finalidade M&uacute;ltipla (</span><span
            style='font-family:"Arial\, Helvetica\, sans-serif";color:#222222;'>N&Atilde;O SUJEITO A IVA AO ABRIGO DO
            ART&ordm;7&ordm; N&ordm;14 DO CIVA)</span> <span style='font-family:"Special Elite";'>, em conex&atilde;o
            com o uso da Plataforma, est&aacute; sujeito aos Termos e Condi&ccedil;&otilde;es e &agrave; Pol&iacute;tica
            de Privacidade do emissor do cart&atilde;o de cr&eacute;dito do Utilizador ou por Meio de Entidade e
            Referencia.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.3. O Utilizador &eacute; respons&aacute;vel pelo pagamento do
            Servi&ccedil;o requisitado. O AGENDAJ&Aacute; ir&aacute; reter ou estar&aacute; em Cativo o pagamento do
            Servi&ccedil;o atrav&eacute;s do cart&atilde;o de cr&eacute;dito do Utilizador, ou carregamento da sua
            carteira de cliente de Voucher de Finalidade M&uacute;ltipla &nbsp;at&eacute; o Servi&ccedil;o ser
            prestado.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.4. O AGENDAJ&Aacute; reserva para si o direito, mas n&atilde;o a
            obriga&ccedil;&atilde;o de &agrave; sua livre discricionariedade suspender o pagamento de um Servi&ccedil;o
            ou proceder &agrave; restitui&ccedil;&atilde;o do pre&ccedil;o pago.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.5. Salvo estipula&ccedil;&atilde;o em contr&aacute;rio ou
            disposi&ccedil;&atilde;o legal imperativa que estabele&ccedil;a algo diferente, todas as quantias pagas
            n&atilde;o s&atilde;o reembols&aacute;veis na totalidade se n&atilde;o desmarcadas at&eacute; 24Horas antes
            do servi&ccedil;o, ser&aacute; reembolsado uma parte.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>9.6. Salvo estipula&ccedil;&atilde;o em contr&aacute;rio ou
            disposi&ccedil;&atilde;o legal imperativa que estabele&ccedil;a algo diferente, todas as quantias pagas
            n&atilde;o s&atilde;o reembols&aacute;veis na sua totalidade se marcadas nas ultimas 24Horas antes do
            servi&ccedil;o, n&atilde;o sendo poss&iacute;vel cancelar ou reagendar e n&atilde;o sendo poss&iacute;vel
            comparecer, perdera valor da marca&ccedil;&atilde;o.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>10.
                Factura&ccedil;&atilde;o</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>10.1. O AGENDAJ&Aacute; apenas &eacute; respons&aacute;vel pela SUA
            fatura&ccedil;&atilde;o e pela fatura&ccedil;&atilde;o entre o AGENDAJ&Aacute; e as <span
                style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros Profissionais
                de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes. O uso da Plataforma Magnifinance serve para
                meio de fatura&ccedil;&atilde;o entre Empresas e Pessoas Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros Profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>No caso de Carteira de Cliente de Voucher de Finalidade
            M&uacute;ltipla (</span><span style='font-family:"Arial\, Helvetica\, sans-serif";color:#222222;'>N&Atilde;O
            SUJEITO A IVA AO ABRIGO DO ART&ordm;7&ordm; N&ordm;14 DO CIVA</span> )<span
            style='font-family:"Special Elite";'>&nbsp;ap&oacute;s o agendamento dos Servi&ccedil;os, o valor ficara
            cativo na sua conta e a fatura ser&aacute; emitir&aacute; na hora que ira usufruir do seu servi&ccedil;o com
            o IVA atualizado desse mesmo servi&ccedil;o. Assim que for confirmado que usufrui o servi&ccedil;o esse
            valor ser&aacute; retirado em definitivo da sua &ldquo;Carteira de Cliente de Voucher de Finalidade
            M&uacute;ltipla&rdquo;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.2. Enquanto&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;&eacute; completamente o respons&aacute;vel por
            conhecer as suas obriga&ccedil;&otilde;es de informar, cobrar, enviar ou incluir nas suas Taxas qualquer IVA
            ou outros impostos indiretos sobre vendas de produtos e servi&ccedil;os e taxas, impostos, sobre o
            rendimento aplic&aacute;veis (&ldquo;Impostos&rdquo;). Usando a Plataforma Magnifinance todo o sistema de
            fatura&ccedil;&atilde;o estar&aacute; conectado entre &nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes o Utilizador e o
            AGENDAJ&Aacute;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.3 As normas fiscais podem obrigar o AGENDAJ&Aacute; a
            recolher informa&ccedil;&atilde;o fiscal adequadas das&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;ou a reter os pagamentos que lhes s&atilde;o
            efetuados ou ambos. Se uma&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoa
            Aut&oacute;noma e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
            Servi&ccedil;os independentes</span><span style='font-family:"Special Elite";color:black;'>&nbsp;n&atilde;o
            nos facultar a documenta&ccedil;&atilde;o necess&aacute;ria ao abrigo da lei aplic&aacute;vel (p. ex., uma
            identifica&ccedil;&atilde;o fiscal) para o cumprimento da nossa obriga&ccedil;&atilde;o (se for o caso),
            reservar-nos o direito de reter valores at&eacute; ao montante fiscalmente relevante, at&eacute; &agrave;
            respetiva resolu&ccedil;&atilde;o.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.4. O utilizador compreende que qualquer organismo,
            departamento e/ou autoridade governamental competente (&quot;Autoridade Fiscal&quot;) da &aacute;rea em que
            o seu Alojamento encontra localizado poder&aacute; exigir que aos Utilizadores/Clientes ou&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;sejam cobrados impostos aplic&aacute;veis e que os
            mesmos sejam entregues &agrave; respetiva Autoridade Fiscal. As leis podem variar em
            jurisdi&ccedil;&otilde;es diferentes, mas poder&aacute; ser exigido que estes Impostos sejam cobrados sobre
            os rendimentos.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.5. Os Utilizadores e as&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;aceitam e s&atilde;o completamente os
            respons&aacute;veis por conhecer as suas obriga&ccedil;&otilde;es de informar, cobrar, enviar ou incluir nas
            suas Taxas qualquer IVA ou outros impostos indiretos sobre vendas de produtos e servi&ccedil;os e taxas,
            impostos, sobre o rendimento aplic&aacute;veis (&ldquo;Impostos&rdquo;). Podendo o AGENDAJ&Aacute;, se
            obrigado, solicitar-lhes montantes adicionais, caso os Impostos n&atilde;o sejam cobrados e/ou enviados
            sejam insuficientes para cumprimento das suas obriga&ccedil;&otilde;es junto da Autoridade Fiscal.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.6. O utilizador e&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes s&atilde;o o</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;&uacute;nico respons&aacute;vel pelo cumprimento de
            todas as leis, normas, regulamentos e obriga&ccedil;&otilde;es Fiscais aplic&aacute;veis &agrave; sua
            utiliza&ccedil;&atilde;o da Plataforma AGENDAJ&Aacute;. No &acirc;mbito da sua utiliza&ccedil;&atilde;o da
            Plataforma, o utilizador e&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas
            Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
            Servi&ccedil;os independentes&nbsp;</span><span style='font-family:"Special Elite";color:black;'>declara que
            n&atilde;o ir&aacute; e n&atilde;o ajudar&aacute; ou permitir&aacute; a terceiros:</span></p>
    <ol class="decimal_type" style="list-style-type: undefined;margin-left:26px;">
        <li><span style='font-family:"Special Elite";color:black;'>violar ou contornar qualquer legisla&ccedil;&atilde;o
                ou regulamentos aplic&aacute;veis, contratos com terceiros, direitos de terceiros ou os nossos
                Termos,Pol&iacute;ticas ou&nbsp;</span><a href="https://www.airbnb.pt/standards"><span
                    style='font-family:"Special Elite";color:black;text-decoration:none;'>Normas</span></a><span
                style='font-family:"Special Elite";color:black;'>;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar a Plataforma AGENDAJ&Aacute; ou o
                Conte&uacute;do Coletivo para quaisquer fins comerciais ou outros, que n&atilde;o os expressamente
                permitidos por estes Termos, ou de uma forma que sugira falsamente a aprova&ccedil;&atilde;o ou
                parceria, ou de alguma forma induza os outros em erro, no que diz respeito &agrave;
                afilia&ccedil;&atilde;o do utilizador ou&nbsp;</span><span
                style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de
                Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span><span style='font-family:"Special Elite";color:black;'>;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>copiar, armazenar ou de algum modo aceder ou utilizar
                qualquer informa&ccedil;&atilde;o, incluindo informa&ccedil;&atilde;o pessoalmente identific&aacute;vel
                sobre qualquer outro Membro, inclu&iacute;da na Plataforma, de uma forma contr&aacute;ria
                &agrave;&nbsp;</span><a href="https://www.airbnb.pt/terms/privacy_policy"><span
                    style='font-family:"Special Elite";color:black;text-decoration:none;'>Pol&iacute;tica de
                    Privacidade</span></a><span style='font-family:"Special Elite";color:black;'>, ou que de alguma
                forma constitua uma viola&ccedil;&atilde;o dos direitos de privacidade dos Utilizadores
                ou&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e
                Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span><span style='font-family:"Special Elite";color:black;'>&nbsp;ou de terceiros;</span>
        </li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar a Plataforma no &acirc;mbito da
                distribui&ccedil;&atilde;o de mensagens comerciais n&atilde;o solicitadas (&quot;spam&quot;);</span>
        </li>
        <li><span style='font-family:"Special Elite";color:black;'>disponibilizar o servi&ccedil;o, na qual n&atilde;o
                &eacute; propriet&aacute;rio ou que n&atilde;o est&aacute; autorizado a disponibilizar atrav&eacute;s da
                Plataforma da AGENDAJ&Aacute;;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>reservar qualquer servi&ccedil;o, a menos que o
                AGENDAJ&Aacute; expressamente o autorize a tal;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>contactar outro utilizador ou&nbsp;</span><span
                style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais
                de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
                style='font-family:"Special Elite";color:black;'>&nbsp;para qualquer outro fim que n&atilde;o o de
                colocar uma quest&atilde;o relacionada com a sua pr&oacute;pria reserva, An&uacute;ncio ou a
                utiliza&ccedil;&atilde;o da Plataforma pelo utilizador em quest&atilde;o, incluindo, sem car&aacute;ter
                de restri&ccedil;&atilde;o, recrutar ou de outro modo convidar qualquer outro utilizador a aderir a
                servi&ccedil;os, aplica&ccedil;&otilde;es ou s&iacute;tios Web de terceiros, sem o nosso consentimento
                pr&eacute;vio por escrito;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar a Plataforma AGENDAJ&Aacute; para solicitar,
                efetuar ou aceitar uma reserva fora da Plataforma, para contornar quaisquer Taxas de Servi&ccedil;o ou
                por qualquer outro motivo;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>solicitar, aceitar ou efetuar qualquer pagamento a
                t&iacute;tulo de Taxas fora da Plataforma do AGENDAJ&Aacute;. Se o fizer, o utilizador e
                as&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e
                Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span><span style='font-family:"Special Elite";color:black;'>&nbsp;assume todos os riscos
                e responsabilidade por tal pagamento.</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>discriminar ou assediar algu&eacute;m com base na
                ra&ccedil;a, nacionalidade, religi&atilde;o, sexo, identidade sexual, defici&ecirc;ncia f&iacute;sica ou
                mental, condi&ccedil;&atilde;o m&eacute;dica, estado civil, idade ou orienta&ccedil;&atilde;o sexual, ou
                de alguma forma participar em qualquer comportamento violento, nocivo, abusivo ou perturbador;</span>
        </li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar indevidamente ou abusar de quaisquer
                An&uacute;ncios ou servi&ccedil;os associados a plataforma;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar, divulgar, espelhar ou enquadrar a
                Plataforma do AGENDAJ&Aacute; ou o Conte&uacute;do Coletivo, ou qualquer elemento individual na
                Plataforma, o nome ou qualquer marca registada, log&oacute;tipo ou outra informa&ccedil;&atilde;o
                privada, ou a apresenta&ccedil;&atilde;o e design de qualquer p&aacute;gina ou formul&aacute;rio
                inclu&iacute;do numa p&aacute;gina na Plataforma do AGENDAJ&Aacute;, sem o consentimento expresso por
                escrito;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>diluir, manchar ou de alguma forma prejudicar a marca
                da AGENDAJ&Aacute;, incluindo pela utiliza&ccedil;&atilde;o n&atilde;o autorizada do Conte&uacute;do
                Coletivo, registo e/ou utiliza&ccedil;&atilde;o de nomes derivados em nomes de dom&iacute;nios,
                designa&ccedil;&otilde;es comerciais, marcas registadas ou outros identificadores de fonte, que imitam
                de perto ou que pela sua semelhan&ccedil;a com os dom&iacute;nios, marcas registadas, lemas, campanhas
                promocionais ou Conte&uacute;do Coletivo podem causar confus&atilde;o;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>utilizar quaisquer rob&ocirc;s, spiders, crawlers,
                scrapers ou outros meios ou processos autom&aacute;ticos para aceder, recolher dados ou outro
                conte&uacute;do, ou de outro modo interagir com a Plataforma AGENDAJ&Aacute;, para qualquer fim;</span>
        </li>
        <li><span style='font-family:"Special Elite";color:black;'>evitar, ignorar, eliminar, desativar, prejudicar,
                descodificar ou de alguma forma tentar contornar qualquer medida tecnol&oacute;gica implementada pelo
                AGENDAJ&Aacute;, por qualquer um dos seus fornecedores ou outro terceiro;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>tentar decifrar, descompactar, desmontar ou aplicar
                engenharia inversa em qualquer software utilizado para disponibilizar a Plataforma da
                AGENDAJ&Aacute;;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>tomar alguma medida que danifique ou prejudique, ou
                possa danificar ou prejudicar o desempenho ou correto funcionamento da Plataforma da
                AGENDAJ&Aacute;;</span></li>
        <li><span style='font-family:"Special Elite";color:black;'>exportar, reexportar, importar ou transferir a
                Aplica&ccedil;&atilde;o, exceto conforme autorizado pela legisla&ccedil;&atilde;o, pelas leis de
                controlo de exporta&ccedil;&atilde;o da sua jurisdi&ccedil;&atilde;o e qualquer outra
                legisla&ccedil;&atilde;o aplic&aacute;vel; ou violar ou infringir os direitos de qualquer pessoa ou de
                outro modo prejudicar algu&eacute;m.</span></li>
    </ol>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.7. O Utilizador reconhece que o AGENDAJ&Aacute;
            n&atilde;o tem qualquer obriga&ccedil;&atilde;o geral de monitorizar o Conte&uacute;do de utilizador
            ou&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e
            Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
            independentes</span><span style='font-family:"Special Elite";color:black;'>, nem de procurar ativamente
            factos ou circunst&acirc;ncias que indiquem atividade ilegal, mas que tem o direito de analisar, impedir o
            acesso a qualquer Conte&uacute;do do utilizador ou&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes&nbsp;</span><span
            style='font-family:"Special Elite";color:black;'>ou de o editar, para: (i) operar, proteger e melhorar a
            seguran&ccedil;a da Plataforma (incluindo, sem car&aacute;cter de restri&ccedil;&atilde;o, para efeitos de
            preven&ccedil;&atilde;o de fraude, avalia&ccedil;&atilde;o de riscos, investiga&ccedil;&atilde;o e apoio ao
            cliente); (ii) garantir o cumprimento destes Termos pelos utilizadores ou&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>; (iii) cumprir a legisla&ccedil;&atilde;o aplic&aacute;vel
            ou uma ordem ou exig&ecirc;ncia de um tribunal, de uma autoridade de aplica&ccedil;&atilde;o da lei ou de
            outra entidade administrativa ou &oacute;rg&atilde;o governamental; (iv) responder a Conte&uacute;do de
            utilizadores ou&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas
            Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
            Servi&ccedil;os independentes</span><span style='font-family:"Special Elite";color:black;'>&nbsp;que
            determine ser prejudicial ou censur&aacute;vel; ou (v) conforme estabelecido nestes Termos. Os Membros
            aceitam cooperar e apoiar o AGENDAJ&Aacute; de boa-f&eacute;, e fornecer as informa&ccedil;&otilde;es e a
            tomar as medidas que sejam razoavelmente solicitadas pelo AGENDAJ&Aacute;, no &acirc;mbito de qualquer
            investiga&ccedil;&atilde;o realizada pelo o AGENDAJ&Aacute; ou por um seu representante, referente &agrave;
            utiliza&ccedil;&atilde;o ou utiliza&ccedil;&atilde;o abusiva da Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>10.8. Se suspeitar que algum utilizador
            ou&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e
            Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
            independentes</span><span style='font-family:"Special Elite";color:black;'>&nbsp;com o qual interage, online
            ou pessoalmente, est&aacute; a agir ou agiu inadequadamente, incluindo, sem car&aacute;ter de
            restri&ccedil;&atilde;o, algu&eacute;m que: (i) participa em comportamentos ofensivos, violentos ou
            sexualmente inadequados; (ii) suspeita esteja a roub&aacute;-lo; ou (iii) participa em qualquer conduta
            perturbadora, o utilizador deve de imediato denunci&aacute;-lo &agrave;s autoridades competentes e, em
            seguida, ao AGENDAJ&Aacute;, entrando em contacto connosco e indicando a sua esquadra de pol&iacute;cia e o
            n&uacute;mero do relat&oacute;rio policial (se dispon&iacute;vel). Voc&ecirc; concorda que qualquer
            den&uacute;ncia que fizer n&atilde;o nos obriga a tomar qualquer medida (para al&eacute;m do exigido por
            lei, se for o caso).</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";color:black;font-weight:normal;'>11.
                Pol&iacute;tica de Cancelamento e Reembolso</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>11.1. O Utilizador pode cancelar uma
            Confirma&ccedil;&atilde;o de Marca&ccedil;&atilde;o na plataforma</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>11.2. O cancelamento deve ser feito com uma
            anteced&ecirc;ncia m&iacute;nima de 24 horas em rela&ccedil;&atilde;o &agrave; hora indicada na
            Confirma&ccedil;&atilde;o de Marca&ccedil;&atilde;o. Nestes casos, o Utilizador tem direito a um reembolso
            integral do valor que tiver pago ou consoante o acordado entre o Utilizador e &nbsp;&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>11.3. Se o cancelamento for feito com um aviso
            pr&eacute;vio inferior a 24 horas, o agendamento fica sem efeito mas o AGENDAJ&Aacute; ira cobrar uma taxa
            de cancelamento equivalente a uma percentagem definida pelas&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;pelos do custo do Servi&ccedil;o que tinha sido
            contratado, devido ao trabalho administrativo e custos incorridos tanto pelo AGENDAJ&Aacute; como pelos o
            prestador dos servi&ccedil;os.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>11.4. Na eventualidade de, por alguma raz&atilde;o de
            &uacute;ltima hora (acidente, doen&ccedil;a, etc.), a&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoa Aut&oacute;noma e Freelancers de Servi&ccedil;os e
            outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;selecionada n&atilde;o puder comparecer para realizar
            o Servi&ccedil;o na data e hora agendadas, o AGENDAJ&Aacute; entrar&aacute; de imediato em contacto com o
            Utilizador no sentido de reagendar o Servi&ccedil;o para outra data ou hora, ou reembolso. .</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>12.
                Promo&ccedil;&otilde;es</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>O AGENDAJ&Aacute; pode de tempos a tempos promover certas
            oportunidades promocionais, sorteios e concursos para Utilizadores. Todas estas a&ccedil;&otilde;es
            promocionais s&atilde;o realizadas apenas e somente pelo o AGENDAJ&Aacute; mas em concord&acirc;ncia com as
            <span style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros
                profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span>, podendo ser ativados,
            modificados ou removidos a todo o tempo pelo AGENDAJ&Aacute; sem qualquer notifica&ccedil;&atilde;o
            pr&eacute;via.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>13.
                Modifica&ccedil;&atilde;o da Plataforma</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>13.1. O AGENDAJ&Aacute; pode atualizar e modificar a Plataforma em
            qualquer momento para refletir modifica&ccedil;&otilde;es ao Servi&ccedil;o, necessidades dos Utilizadores e
            as nossas prioridades comerciais. Estas altera&ccedil;&otilde;es n&atilde;o ficam dependentes de
            autoriza&ccedil;&atilde;o nem de notifica&ccedil;&atilde;o aos Utilizadores.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>13.2. O AGENDAJ&Aacute; pode alterar o URL do site. Ao fazer essa
            altera&ccedil;&atilde;o, durante um per&iacute;odo razo&aacute;vel, os Utilizadores ser&atilde;o
            reencaminhados automaticamente para o novo URL. Esta altera&ccedil;&atilde;o n&atilde;o fica dependente de
            autoriza&ccedil;&atilde;o dos Utilizadores.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>14. Suspender ou
                fechar a Plataforma</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>14.1. Sem preju&iacute;zo das obriga&ccedil;&otilde;es do
            AGENDAJ&Aacute; para com os Utilizadores, o AGENDAJ&Aacute; n&atilde;o garante que a Plataforma ou os seus
            conte&uacute;dos se encontrem permanentemente dispon&iacute;veis. Podendo suspender ou cancelar o acesso
            &agrave; Plataforma por raz&otilde;es comerciais ou operacionais.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>14.2. Sempre que poss&iacute;vel e antes de operacionalizar qualquer
            suspens&atilde;o ou cancelamento, o AGENDAJ&Aacute; ira informar os Utilizadores registados.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>Salvo circunst&acirc;ncias de for&ccedil;a maior fora do controlo do
            AGENDAJ&Aacute;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>14.3. O AGENDAJ&Aacute; pode ainda terminar ou suspender o direito de
            uso da Plataforma por qualquer Utilizador, por toda e qualquer raz&atilde;o enviando previamente um E-mail a
            informar dessa suspens&atilde;o ou cessa&ccedil;&atilde;o de utiliza&ccedil;&atilde;o da Plataforma.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>14.4. Caso o AGENDAJ&Aacute; termine ou suspenda o direito de uso da
            Plataforma por qualquer Utilizador, este fica proibido de criar qualquer nova conta, no seu nome, com um
            nome falso ou emprestado, assim como em representa&ccedil;&atilde;o de outra pessoa.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>15.
                Seguran&ccedil;a</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>15.1. Os Utilizadores s&atilde;o respons&aacute;veis por assegurar
            que os seus sistemas inform&aacute;ticos disp&otilde;em das especifica&ccedil;&otilde;es t&eacute;cnicas
            necess&aacute;rias ao acesso e utiliza&ccedil;&atilde;o da Plataforma e que disp&otilde;em de programas de
            prote&ccedil;&atilde;o e antiv&iacute;rus adequados.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>15.2. Nos casos em que o Utilizador se tenha registado e o
            AGENDAJ&Aacute; lhe tenha fornecido um c&oacute;digo de acesso &agrave; sua conta, o Utilizador reconhece e
            aceita que &eacute; sua responsabilidade manter essas senhas secretas e confidenciais. O AGENDAJ&Aacute;
            jamais solicitar&aacute; que partilhe essas senhas com outras pessoas.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>15.3. O Utilizador deve utilizar unicamente o c&oacute;digo de acesso
            de que for titular, assumindo assim plena responsabilidade pelas a&ccedil;&otilde;es efetuadas com esse
            mesmo registo, ainda que por terceiros, com ou sem a sua autoriza&ccedil;&atilde;o expressa.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>15.4. Se o Utilizador souber ou desconfiar que algu&eacute;m teve
            acesso aos c&oacute;digos de acesso &agrave; sua conta, deve informar o AGENDAJ&Aacute; de imediato
            atrav&eacute;s do formul&aacute;rio de contacto dispon&iacute;vel na Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>15.5. Informamos que os dados Utilizador poder&atilde;o ser
            partilhados com &nbsp;<span style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
                e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes, ao efetuar agendar
                um servi&ccedil;o.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>16.
                Informa&ccedil;&otilde;es sobre os Servi&ccedil;os</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>16.1. O AGENDAJ&Aacute; realiza todos os esfor&ccedil;os para que a
            informa&ccedil;&atilde;o sobre os Servi&ccedil;os prestados apresentada na Plataforma seja completa, precisa
            e correta.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>16.2. As imagens fotogr&aacute;ficas colocadas na Plataforma dos
            Servi&ccedil;os prestados no passado e dos produtos utilizados no &acirc;mbito da presta&ccedil;&atilde;o
            desses mesmos Servi&ccedil;os s&atilde;o meramente ilustrativas. O AGENDAJ&Aacute; n&atilde;o garante nem
            ser&aacute; respons&aacute;vel caso os resultados dos Servi&ccedil;os n&atilde;o correspondam &agrave;s
            imagens disponibilizadas ou &agrave;s expectativas do Utilizador.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>16.3. Para obter informa&ccedil;&atilde;o adicional sobre os
            Servi&ccedil;os prestados e os produtos utilizados no &acirc;mbito da presta&ccedil;&atilde;o de
            Servi&ccedil;os, o Utilizador dever&aacute; contactar diretamente as <span style="color:#111111;">Pessoas
                Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de
                Servi&ccedil;os independentes.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>17.
                Indeminiza&ccedil;&otilde;es</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>17.1. O Utilizador concorda por este meio indemnizar, defender e
            isentar de responsabilidade do AGENDAJ&Aacute;, os seus gerentes, empregados, agentes, mandat&aacute;rios,
            prestadores de servi&ccedil;os, subsidiarias ou afiliadas de toda e contra qualquer
            reclama&ccedil;&atilde;o, perda, despesa, exig&ecirc;ncia de responsabilidades, incluindo despesas legais e
            custos incorridos, em conex&atilde;o com qualquer conte&uacute;do submetido pelo Utilizador ou submetido
            atrav&eacute;s da sua conta para a Plataforma, incluindo, mas n&atilde;o limitado &agrave;
            viola&ccedil;&atilde;o de terceiros, ou por ser ilegal ou ileg&iacute;timo.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>18.2. O AGENDAJ&Aacute; reserva-se o direito, em seus casos e a seu
            custo, de assumir a exclusiva defesa e controlo sobre qualquer mat&eacute;ria sujeita a
            indemniza&ccedil;&atilde;o do Utilizador. O Utilizador n&atilde;o poder&aacute;, em qualquer caso, celebrar
            um acordo judicial ou pr&eacute;-judicial sem o consentimento por escrito do AGENDAJ&Aacute;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>&nbsp;</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>19. Seguro
                Profissional</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>As <span style="color:#111111;">Pessoas Aut&oacute;nomas e
                Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
                independentes</span> que se registem na Plataforma devem ser obrigadas a demonstrar terem contratado um
            seguro de responsabilidade profissional. No entanto, o AGENDAJ&Aacute; n&atilde;o garante que as <span
                style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais
                de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes,</span> contratada pelo Utilizador
            ter&aacute; um seguro v&aacute;lido, pelo cabe ao Utilizador deve pedir essa informa&ccedil;&atilde;o
            diretamente as <span style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
                outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span> antes dos
            servi&ccedil;os serem prestados. Relembrando que o AGENDAJ&Aacute; &eacute; a liga&ccedil;&atilde;o entre
            Utilizador e as <span style="color:#111111;">Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e
                outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes, logo a
                obriga&ccedil;&atilde;o de todos os meios legais como &ldquo;Seguros&rdquo; depende sempre e s&oacute;
                das Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os e outros profissionais de
                Presta&ccedil;&atilde;o de Servi&ccedil;os independentes.</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>20. Propriedade
                Intelectual</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>20.1. O Utilizador declara e reconhece expressamente que, salvo
            indica&ccedil;&atilde;o em contr&aacute;rio, os conte&uacute;dos, design, fotografias, software, know-how,
            nomes de dom&iacute;nio, denomina&ccedil;&otilde;es comerciais, log&oacute;tipos, marcas, desenhos e modelos
            e quaisquer outros direitos de propriedade intelectual, registados ou n&atilde;o, existentes ou utilizados
            na Plataforma ou relacionados com a sua explora&ccedil;&atilde;o, s&atilde;o propriedade exclusiva do
            AGENDAJ&Aacute;.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>20.2. A marca AGENDAJ&Aacute; encontra-se registada. &Eacute;
            completamente proibida a utiliza&ccedil;&atilde;o da nossa marca sem a nossa aprova&ccedil;&atilde;o.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>20.3. As marcas de produtos eventualmente promovidos atrav&eacute;s
            da Plataforma pertencem &agrave;s respetivas empresas respons&aacute;veis por esses produtos.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>20.4. Caso o AGENDAJ&Aacute; venha a colocar imagens de Utilizadores
            na Plataforma para efeitos promocionais ou outros, ser&aacute; solicitada uma autoriza&ccedil;&atilde;o
            espec&iacute;fica a cada Utilizador para esse efeito, comprometendo-se, no entanto, cada Utilizador, a
            colaborar nesse sentido.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>21. For&ccedil;a
                Maior</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.1. Nenhuma das partes ser&aacute; respons&aacute;vel perante a
            outra pelo n&atilde;o cumprimento das suas obriga&ccedil;&otilde;es, se esse n&atilde;o cumprimento tiver
            sido causado ou resultar de um evento de for&ccedil;a maior tal como definido abaixo.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.2. For&ccedil;a maior significar&aacute; um dos eventos seguintes
            que impe&ccedil;a qualquer das partes de cumprir as suas obriga&ccedil;&otilde;es, esteja fora do seu
            controlo e n&atilde;o possa ser evitado por um contraente diligente:</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>a) Atos de guerra ou terrorismo, revolu&ccedil;&otilde;es, tumultos,
            bloqueios, insurrei&ccedil;&atilde;o e levantamentos civis;<br> b) Atos de sabotagem e vandalismo;<br> c)
            Terramotos, tuf&otilde;es, tornados e ciclones, tempestades, fogos, cheias, ou outras
            condi&ccedil;&otilde;es clim&aacute;ticas ou ambientais extremas, meteoritos, ondas de choque causadas por
            engenhos a&eacute;reos, explos&otilde;es, contamina&ccedil;&atilde;o qu&iacute;mica ou radioativa;<br> d)
            Qualquer ato de autoridade p&uacute;blica (legislativo, administrativo, judicial ou outro), desde que
            n&atilde;o imput&aacute;vel &agrave; parte faltosa;<br>&nbsp;e) Qualquer outro evento que as partes
            reconhe&ccedil;am por acordo como sendo de for&ccedil;a maior.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.3. A parte faltosa dever&aacute;, de imediato notificar a parte
            n&atilde;o faltosa da ocorr&ecirc;ncia do evento de for&ccedil;a maior e fornecer provas razo&aacute;veis
            dessa ocorr&ecirc;ncia.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.4. A parte faltosa tomar&aacute; as medidas necess&aacute;rias
            para limitar ou restringir os efeitos adversos para o Contrato do evento de for&ccedil;a maior.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.5. No caso de se verificar um evento de for&ccedil;a maior a parte
            faltosa n&atilde;o poder&aacute; reclamar o reembolso de quaisquer despesas em que tenha incorrido.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>21.6. No caso das referidas causas n&atilde;o poderem ser, por
            qualquer raz&atilde;o, remediadas no prazo de 15 (quinze) dias a contar da data da ocorr&ecirc;ncia,
            qualquer das partes ter&aacute; o direito de se desvincular do Contrato e nenhuma das partes ter&aacute;
            qualquer direito ou ac&ccedil;&atilde;o contra a outra.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>22. V&iacute;rus
                inform&aacute;ticos</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>22.1. O AGENDAJ&Aacute; n&atilde;o garante que a Plataforma se
            encontre protegida ou livre de erros (&ldquo;bugs&rdquo;), falhas ou v&iacute;rus (incluindo
            &ldquo;exploits&rdquo; e &ldquo;malware&rdquo;).</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>22.2. Os Utilizadores s&atilde;o respons&aacute;veis por configurar
            os seus equipamentos tecnol&oacute;gicos e utilizar programas de prote&ccedil;&atilde;o contra
            v&iacute;rus.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>22.3. Os Utilizadores encontram-se proibidos de utilizar a Plataforma
            de modo desadequado e introduzir conscientemente v&iacute;rus nos mesmos ou quaisquer outros materiais com
            potencial para danificar o site, a Plataforma ou os conte&uacute;dos dos mesmos.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>22.4. Os Utilizadores encontram-se proibidos de procurar obter acesso
            n&atilde;o autorizado &agrave; Plataforma, ao servidor onde a Plataforma se encontra alojada e a qualquer
            equipamento ou base de dados conectada com a Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>23. Invalidade
                parcial</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>Caso alguma das cl&aacute;usulas destes Termos e
            Condi&ccedil;&otilde;es venha a ser julgada nula ou vier a ser anulada, tal n&atilde;o afetar&aacute; a
            validade das restantes cl&aacute;usulas nem a validade dos restantes Termos e Condi&ccedil;&otilde;es, que
            se considerar&atilde;o automaticamente reduzidos nos termos do artigo 292.&ordm; do C&oacute;digo
            Civil.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>24. N&atilde;o
                exerc&iacute;cio</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>O n&atilde;o exerc&iacute;cio, ou o exerc&iacute;cio tardio ou
            parcial, de qualquer direito que nos assista ao abrigo destes Termos e Condi&ccedil;&otilde;es, em nenhum
            caso poder&aacute; significar a ren&uacute;ncia a esse direito, ou acarretar a sua caducidade, pelo que o
            mesmo se manter&aacute; v&aacute;lido e eficaz n&atilde;o obstante o seu n&atilde;o exerc&iacute;cio.</span>
    </p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>25.
                Comunica&ccedil;&otilde;es</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>25.1. As comunica&ccedil;&otilde;es relativas aos presentes Termos e
            Condi&ccedil;&otilde;es que nos sejam dirigidas devem ser feitas por escrito para a nossa morada publicada
            nestes Termos e Condi&ccedil;&otilde;es ou atrav&eacute;s do nosso formul&aacute;rio dispon&iacute;vel na
            Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>25.2. As comunica&ccedil;&otilde;es escritas considerar-se-&atilde;o
            realizadas na data da respetiva rece&ccedil;&atilde;o da carta, do formul&aacute;rio preenchido ou email, ou
            se forem recebidas ap&oacute;s as 18 horas de Lisboa, no primeiro dia &uacute;til seguinte.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>25.3. Para efeitos de realiza&ccedil;&atilde;o de
            cita&ccedil;&atilde;o no &acirc;mbito de a&ccedil;&atilde;o judicial destinada ao cumprimento de
            obriga&ccedil;&otilde;es emergentes de contrato celebrado ao abrigo destes Termos e Condi&ccedil;&otilde;es.
            Ficam convencionadas as seguintes moradas_______________</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>26. Lei
                aplic&aacute;vel e foro</span></span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>26.1. A lei aplic&aacute;vel &agrave; Plataforma e aos Termos e
            Condi&ccedil;&otilde;es &eacute; a lei portuguesa.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>26.2. Em caso de lit&iacute;gio que n&atilde;o tenha conseguido
            resolver diretamente com o AGENDAJ&Aacute;, o Utilizador, enquanto consumidor, pode recorrer &agrave;
            plataforma europeia de resolu&ccedil;&atilde;o de lit&iacute;gios em linha, acess&iacute;vel no
            site&nbsp;</span><a href="https://ec.europa.eu/consumers/odr"><span
                style="color:windowtext;text-decoration:none;">https://ec.europa.eu/consumers/odr</span></a><span
            style='font-family:"Special Elite";color:#E83048;'>&nbsp;</span><span
            style='font-family:"Special Elite";'>ou ao Centros de Arbitragem de Conflitos de Consumo, acess&iacute;vel
            no site&nbsp;</span><a href="https://www.cniacc.pt/"><span
                style='font-family:"Special Elite";color:#E83048;text-decoration:none;'>https://www.cniacc.pt</span></a><span
            style='font-family:"Special Elite";'>.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";'>26.3. Caso prefira, poder&aacute; igualmente recorrer aos tribunais
            portugueses para dirigir quaisquer lit&iacute;gios relacionados com a utiliza&ccedil;&atilde;o da Plataforma
            e com os presentes Termos e Condi&ccedil;&otilde;es.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        &nbsp;</p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style="font-weight:bold;"><span style='font-family:"Special Elite";font-weight:normal;'>27.
                Sugest&otilde;es</span></span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";'>Quando o Utilizador pretender apresentar alguma sugest&atilde;o
                    ou reclama&ccedil;&atilde;o, designadamente quanto ao Servi&ccedil;o prestado atrav&eacute;s da
                    Plataforma, dever&aacute; enviar para <a href="mailto:administrador@agendaja.pt"><span
                            style="color:windowtext;">administrador@agendaja.pt</span></a> ou preencher o
                    formul&aacute;rio na plataforma.</span></li>
        </ol>
    </div>
    <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</p>
    <h4
        style='margin-top:12.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>28. Disposi&ccedil;&otilde;es Gerais</span></h4>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>28.1 Salvo se complementados por termos e
            condi&ccedil;&otilde;es, pol&iacute;ticas, orienta&ccedil;&otilde;es ou normas adicionais, estes Termos
            constituem o Contrato integral entre o utilizador ou&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes</span><span
            style='font-family:"Special Elite";color:black;'>&nbsp;, no que diz respeito ao seu objeto, substituindo
            todos e quaisquer entendimentos ou acordos verbais ou escritos anteriores entre o AGENDAJ&Aacute; e
            utilizador ou&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas
            e Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
            independentes</span><span style='font-family:"Special Elite";color:black;'>, no que diz respeito ao acesso e
            utiliza&ccedil;&atilde;o da Plataforma.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>28.2 O presente Contrato ou a sua
            utiliza&ccedil;&atilde;o da Plataforma n&atilde;o estabelecem uma rela&ccedil;&atilde;o de empresa comum,
            parceria, emprego ou outro entre o utilizador e&nbsp;</span><span
            style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e Freelancers de Servi&ccedil;os
            e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os independentes e o
            AGENDAJ&Aacute;</span><span style='font-family:"Special Elite";color:black;'>.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>22.3 Estes Termos n&atilde;o pretendem conferir e
            n&atilde;o conferem quaisquer direitos ou recursos a qualquer pessoa que n&atilde;o as partes.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>22.4 Se alguma disposi&ccedil;&atilde;o nestes termos for
            considerada inv&aacute;lida ou inaplic&aacute;vel, a disposi&ccedil;&atilde;o em quest&atilde;o ser&aacute;
            suprimida e n&atilde;o afetar&aacute; a validade e aplicabilidade das restantes
            disposi&ccedil;&otilde;es.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>22.5 A n&atilde;o aplica&ccedil;&atilde;o do
            AGENDAJ&Aacute; de qualquer direito ou disposi&ccedil;&atilde;o prevista nestes Termos n&atilde;o constitui
            uma ren&uacute;ncia a tal direito ou disposi&ccedil;&atilde;o, a menos que tal seja reconhecido e aceite
            pelo AGENDAJ&Aacute;, por escrito. Salvo disposi&ccedil;&atilde;o expressa nestes Termos, a
            aplica&ccedil;&atilde;o por qualquer das partes de qualquer uma das suas vias de recurso, previstas nos
            presentes Termos, n&atilde;o prejudica o recurso &agrave;s restantes vias de recurso previstas nestes Termos
            ou de alguma forma permitidas por lei.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>22.6 O utilizador n&atilde;o pode ceder, transferir ou
            delegar este Contrato e os seus direitos e obriga&ccedil;&otilde;es, ora previstos, sem o consentimento
            pr&eacute;vio por escrito do AGENDAJ&Aacute;. O AGENDAJ&Aacute; pode, sem qualquer restri&ccedil;&atilde;o,
            ceder, transferir ou delegar este Contrato e quaisquer direitos e obriga&ccedil;&otilde;es ora previstos, a
            seu crit&eacute;rio exclusivo, mediante aviso pr&eacute;vio com 30 dias de anteced&ecirc;ncia. O direito que
            assiste ao Utilizador de rescindir este Contrato em qualquer momento n&atilde;o &eacute; afetado.</span></p>
    <p
        style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <span style='font-family:"Special Elite";color:black;'>22.7 Salvo disposi&ccedil;&atilde;o em contr&aacute;rio,
            quaisquer notifica&ccedil;&otilde;es ou outras comunica&ccedil;&otilde;es aos utilizadores
            e&nbsp;</span><span style='font-family:"Special Elite";color:#111111;'>Pessoas Aut&oacute;nomas e
            Freelancers de Servi&ccedil;os e outros profissionais de Presta&ccedil;&atilde;o de Servi&ccedil;os
            independentes</span><span style='font-family:"Special Elite";color:black;'>, autorizadas ou exigidas ao
            abrigo deste Contrato, ser&atilde;o fornecidas eletronicamente e enviadas pelo AGENDAJ&Aacute; &nbsp;por
            e-mail, notifica&ccedil;&atilde;o na Plataforma ou servi&ccedil;o de mensagens.</span></p>
    <div style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'>
        <ol style="margin-bottom:0cm;list-style-type: undefined;">
            <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;'><span
                    style='font-family:"Special Elite";color:black;'>Caso tenha alguma quest&atilde;o relativamente a
                    estes Termos, dever&aacute; enviar-nos um e-mail Para: <a
                        href="mailto:administrador@agendaja.pt"><span
                            style="color:black;text-decoration:none;">administrador@agendaja.pt</span></a></span></li>
        </ol>
    </div>

</div>