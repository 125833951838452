import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { access } from 'fs';
import { AgendaJaHttpClient } from 'src/app/http-clients/agendaja/agendaja.apiclient';

@Component({
  selector: 'app-password-recovery-page',
  templateUrl: './password-recovery-page.component.html',
  styleUrls: ['./password-recovery-page.component.scss']
})
export class PasswordRecoveryPageComponent implements OnInit {

  emailFormGroup: FormGroup;
  public isEmailValid:boolean = false;
  public email:string;
  public sentSuccess:boolean=false;

  constructor(private agendajaClient: AgendaJaHttpClient) { }

  ngOnInit(): void {
    this.createFormGroup();
  }

  recoverPassword(){
    this.agendajaClient.RecoverPassword(this.email)
      .subscribe({
        next: (success) => {
          this.sentSuccess = true;
          this.email = "";
          this.emailFormGroup.reset();
        },
        error: () => {
          this.sentSuccess = false;
          this.email = "";
          this.emailFormGroup.reset();
        }
      });
  }

  createFormGroup(){
    this.emailFormGroup = new FormGroup({
      emailCtrl: new FormControl(this.email,
        [Validators.required,
        Validators.email])
    });
  }

}
