
<div style="display: flex; overflow: hidden; max-width: 800px;" class="row sucess-register">
    <mat-card class="register-side-left col-xs-12 col-md-6" style="flex: 1 1 auto;">

        <div>
            <div class="content" style="text-align: center; margin-top: 40px;">
                <h4>Bem-vindo ao Agenda Já!</h4>
                <p>Estaremos ao dispor para o ajudar! </p>
            </div> 
            
            <div>
                <button mat-button color="primary"
                class="btn-continue"
                (click)="continue()"
                >Continuar</button>
                
            </div>
        </div>
    </mat-card>

    <mat-card class="register-side  col-xs-12 col-md-6" style="flex: 1 1 auto;">
        <div class="image" >
         <img src="../../../../assets/images/register/sorriso2.png"  >     
        </div>    
    </mat-card>
</div>

