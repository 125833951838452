import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { ServicePageComponent } from './pages/service-page/service-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { BusinessPageComponent } from './pages/business-page/business-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { CheckoutErrorComponent } from './pages/checkout/checkout-error/checkout-error.component';
import { CheckoutSuccessComponent } from './pages/checkout/checkout-success/checkout-success.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UserAgendaComponent } from './components/profile/user-agenda/user-agenda.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
import { UserWalletComponent } from './components/profile/user-wallet/user-wallet.component';
import { UserHistoryComponent } from './components/profile/user-history/user-history.component';
import { UserRefundsComponent } from './components/profile/user-refunds/user-refunds.component';
import { CheckoutPayPageComponent } from './pages/checkout/checkout-pay-page/checkout-pay-page.component';
import { AuthGuard } from './guards/auth.guard';
import { PasswordRecoveryPageComponent } from './pages/password-recovery-page/password-recovery-page.component';
import { PasswordRecoveryConfirmPageComponent } from './pages/password-recovery-confirm-page/password-recovery-confirm-page.component';
import { BusinessLandingPageComponent } from './pages/business-landing-page/business-landing-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, data: { title: 'Agendamentos online' } },
  { path: '',   redirectTo: '', pathMatch: 'full' }, // redirect 
  { path: 'policies', component: PolicyPageComponent, data : { title : 'Agenda Já - Políticas de Privacidade' } },
  { path: 'terms', component: TermsPageComponent, data : { title : 'Agenda Já - Termos e Condições' } },
  { path: 'register', component: RegisterPageComponent, data : { title : 'Agenda Já - Registar' } },
  { path: 'business', component: BusinessLandingPageComponent, data : { title : 'Agenda Já - Empresas' } },
  { path: 'checkout', component: CheckoutPageComponent, data : { title : 'Agenda Já - Checkout' } },
  { path: 'checkout/success/:code', component: CheckoutSuccessComponent },
  { path: 'checkout/error', component: CheckoutErrorComponent },
  { path: 'checkout/pay', component: CheckoutPayPageComponent },
  { path: 'recovery', component: PasswordRecoveryPageComponent, data : { title : 'Agenda Já - Recuperar acesso' } },
  { path: 'recovery/confirm/:code', component: PasswordRecoveryConfirmPageComponent },
  { path: 'user', component: UserPageComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Utilizador' }},
  { path: 'user/profile', component: UserProfileComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Perfil' } },
  { path: 'user/agenda', component: UserAgendaComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Agenda' } },
  { path: 'user/wallet', component: UserWalletComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Carteira' } },
  { path: 'user/history', component: UserHistoryComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Histórico' } },
  { path: 'user/refunds', component: UserRefundsComponent,  canActivate: [AuthGuard], data : { title : 'Agenda Já - Reembolsos' } },
  { path: 'company/:companyId', component: CompanyPageComponent },
  { path: 'company/:companyId/service/:idService', component: ServicePageComponent },
  { path: 'business-landing', component: BusinessLandingPageComponent, data: { title: 'Agendamentos online Empresas' } },
  { path: ':companyCode', component: CompanyPageComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
