<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Agenda Já - Business</title>
    <!-- <link rel="stylesheet" href="assets/vendors/fontawesome-free/css/all.min.css">
    <link rel="stylesheet" href="assets/css/style.css"> -->
</head>

<body>
    <header class="foi-header landing-header">
        <div class="container">
            <!-- <nav class="navbar navbar-expand-lg navbar-light foi-navbar">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/images/logo.svg" alt="FOI">
                </a>
                <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="collapsibleNavId">
                    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li class="nav-item active">
                            <a class="nav-link" href="index.html">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="about.html">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="features.html">Features</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a>
                            <div class="dropdown-menu" aria-labelledby="pagesMenu">
                                <a class="dropdown-item" href="blog.html">Blog</a>
                                <a class="dropdown-item" href="login.html">Login</a>
                                <a class="dropdown-item" href="register.html">Register</a>
                                <a class="dropdown-item" href="faq.html">FAQ</a>
                                <a class="dropdown-item" href="404.html">404</a>
                                <a class="dropdown-item" href="careers.html">Careers</a>
                                <a class="dropdown-item" href="blog-single.html">Single blog</a>
                                <a class="dropdown-item" href="privacy-policy.html">Privacy policy</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="contact.html">contact</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav mt-2 mt-lg-0">
                        <li class="nav-item mr-2 mb-3 mb-lg-0">
                            <a class="btn btn-secondary" href="register.html">Sign up</a>
                        </li>
                        <li class="nav-item">
                            <a class="btn btn-secondary" href="login.html">Login</a>
                        </li>
                    </ul>
                </div>
            </nav> -->
            <div class="header-content">
                <div class="row">
                    <div class="col-md-6">
                        <h1>Agenda Já</h1>
                        <h1>Agendamentos Online</h1>
                        <p class="text-dark">Queremos ajudar-te a gerir a tua agenda e a monetizar as tuas horas de trabalho.</p>
                        <p class="text-dark">Vamos lá reduzir as não presenças sem aviso?</p>
                        <p class="text-dark"></p>
                        <!-- <button class="btn btn-primary mb-4">Get Started</button> -->
                        <!-- <div class="my-2">
                            <p class="header-app-download-title">GET OUR MOBILE APP</p>
                        </div> -->
                        <div>
                            <button class="btn btn-app-download mr-2"><img src="../../../assets/images/header/logo_agendaja.jpg" alt="App store"></button>
                            <!-- <button class="btn btn-app-download"><img src="../../../assets/images/header/logo_agendaja.jpg" alt="play store"></button> -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src="../../../assets/landing-page-assets/agja/agendaja_smartphone.png" alt="app" width="388px" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section class="py-5 mb-5">
        <div class="container">
            <h2 class="section-title">Funcionalidades</h2>
            <div class="row">
                <div class="col-lg-4 mb-4 mb-lg-0">
                    <h5>Agendamento Online</h5>
                    <p class="text-dark">Podes gerir as tuas marcações via backoffice, ou deixar que os clientes utilizem a app e façam a marcação.</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                    <h5>Pagamento Online e em Loja</h5>
                    <p class="text-dark">Podes usar o pagamento online ou deixar que os clientes paguem o serviço no momento de execução do serviço.</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <h5>Gestão de Funcionários</h5>
                    <p class="text-dark">Podes gerir o teu horário de atendimento e o dos teus funcionários.</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                    <h5>Lembretes</h5>
                    <p class="text-dark">Aviso automático das marcações aos teus clientes via Email e SMS.</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <h5>Notificações</h5>
                    <p class="text-dark">Recebe notificações no teu smartphone ou computador sempre que uma nova marcação é efetuada pelos clientes.</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                    <h5>... e muito mais!</h5>
                    <p class="text-dark">Fazemos demontração do nosso software de agendamento. Vem conhecer-nos!</p>
                    <!-- <p class="mb-5"><a href="#!" class="text-primary mb-5">Find out More</a></p> -->
                </div>

                <!-- <div class="col-lg-4">
                    <h6 class="text-gray font-os font-weight-semibold">Trusted by the world's best</h6>
                    <div id="landingClientCarousel" class="carousel slide landing-client-carousel" data-ride="carousel">
                        <div class="carousel-inner" role="listbox">
                            <div class="carousel-item active">
                                <div class="d-flex flex-wrap justify-content-center">
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/slack.svg" alt="Slack" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/spotify.svg" alt="Spotify" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/paypal.svg" alt="Paypal" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/amazon.svg" alt="Amazon" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/google.svg" alt="Google" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/samsung.svg" alt="Samsung" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="d-flex flex-wrap justify-content-center">
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/slack.svg" alt="Slack" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/spotify.svg" alt="Spotify" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/paypal.svg" alt="Paypal" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/amazon.svg" alt="Amazon" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/google.svg" alt="Google" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/samsung.svg" alt="Samsung" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="d-flex flex-wrap justify-content-center">
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/slack.svg" alt="Slack" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/spotify.svg" alt="Spotify" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/paypal.svg" alt="Paypal" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/amazon.svg" alt="Amazon" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/google.svg" alt="Google" class="img-fluid">
                                    </div>
                                    <div class="clients-logo">
                                        <img src="assets/images/clients/samsung.svg" alt="Samsung" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ol class="carousel-indicators">
                            <li data-target="#landingClientCarousel" data-slide-to="0" class="active"></li>
                            <li data-target="#landingClientCarousel" data-slide-to="1"></li>
                            <li data-target="#landingClientCarousel" data-slide-to="2"></li>
                        </ol>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- <section class="py-5 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                    <img src="assets/images/app_2.png" alt="special offers" class="img-fluid" width="492px">
                </div>
                <div class="col-md-6">
                    <h2 class="section-title">Get special offers on the things you love</h2>
                    <p class="mb-5">He has led a remarkable campaign, defying the traditional mainstream parties courtesy of his En Marche! movement. For many, however, the campaign has become less about backing Macron and instead about voting against Le Pen, the National Front candidate.</p>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Essentials</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Premium</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="media landing-feature">
                                <span class="landing-feature-icon"></span>
                                <div class="media-body">
                                    <h5>Standard</h5>
                                    <p>All the basics for businesses that are just getting started.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="py-5 mb-5 plans-section">
        <div class="container" >
            <h2>Escolhe o plano certo para o teu negócio</h2>
            <p class="text-muted mb-5">Se tiveres dúvidas, fala conosco e ajustamos o plano às tuas necessidades</p>
            <div class="row">
                <div class="col-lg-6 mb-6">
                    <div class="card pricing-card border-warning">
                        <div class="card-body">
                            <h3 class="mb-1">Plus</h3>
                            <h3 class="mb-1 text-warning">0.20€</h3>
                            <p class="payment-period">por agendamento</p>
                            <p class="mb-4">Ideal para prestadores de serviços singulares, ou pequenas empresas.</p>
                            <div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Gestão de Agenda</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de Serviços</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de Funcionários</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de marcações</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Pagamento Online ou em Loja</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Gestão de Funcionários</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Estatísticas e Relatórios</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Notificações Email</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Notificações SMS</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Políticas de cancelamento para pagamentos online</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Módulo de Faturação</div>
                            </div>
<!--  <button class="btn btn-outline-warning btn-rounded">Get Started</button> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mb-6">
                    <div class="card pricing-card border-primary active">
                        <div class="card-body">
                            <h3>Mega</h3>
                            <h3 class="text-primary">49.99€</h3>
                            <p class="payment-period">por mês</p>
                            <p class="mb-4">Ideal para médias e grandes empresas com múltiplos funcionários e serviços.</p>
                            <div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Gestão de Agenda</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de Serviços</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de Funcionários</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Sem limite de marcações</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Pagamento Online ou em Loja</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Gestão de Funcionários</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Estatísticas e Relatórios</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Notificações Email</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Notificações SMS</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Políticas de cancelamento para pagamentos online</div>
                                <div><span class="material-symbols-outlined ng-star-inserted" style="color: green;"> done </span> Módulo de Faturação</div>
                            </div>

                            <!-- <button class="btn btn-primary btn-rounded">Get Started</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5 mb-5">
        <div class="container">
            <h2>Casos de sucesso</h2>
            <p class="text-muted mb-5">A satisfação dos nossos clientes está em primeiro lugar</p>
            <div class="row">
                <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                    </div>
                    <h5 class="foi-review-heading">Gestão de marcações</h5>
                    <p class="foi-review-content">O agenda já facilitou todo o processo de agendamento para os meus clientes, uma vez que não têm de ligar ou enviar mensagens para poderem fazer uma marcação na Réplik.</p>
                    <div class="media foi-review-user">
                        <img src="https://agendaja.sirv.com/prod/images/customers/7a04df27-3981-4827-9bf0-165bf8acfac1.JPG" alt="user" class="avatar">
                        <div class="media-body">
                            <h6 class="mb-0">Réplik Body&Nails</h6>
                            <p>Claúdia Santos</p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <!-- <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span> -->
                    </div>
                    <h5 class="foi-review-heading">Queres ser o próximo testemunho?</h5>
                    <p class="foi-review-content">Contacta-nos para que possas comprovar a qualidade da nossa solução.</p>
                    <p class="foi-review-content">Aguardamos pelo teu feedback!</p>
                    <div class="media foi-review-user">
                        <!-- <img src="https://backoffice.agendaja.pt/favicon.ico" alt="user" class="avatar">
                        <div class="media-body">
                            <h6 class="mb-0">Aguardamos por ti</h6>
                            <p></p>
                        </div> -->
                    </div>

                </div>
                <!-- <div class="col-md-4 foi-review mb-5 mb-md-0">
                    <div class="foi-rating">
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                        <span class="fas fa-star checked"></span>
                    </div>
                    <h5 class="foi-review-heading">Great support available</h5>
                    <p class="foi-review-content">Thank you for your very professional and prompt response. I wished I had found you before I spent money on a competitors theme.</p>
                    <div class="media foi-review-user">
                        <img src="assets/images/avatar/avatar_13.jpg" alt="user" class="avatar">
                        <div class="media-body">
                            <h6 class="mb-0">Monica Böttger</h6>
                            <p>UX Designer</p>
                        </div>
                    </div>

                </div> -->
            </div>
        </div>
    </section>
    <!-- <section class="py-5 mb-5">
        <div class="container">
            <h2>FAQ</h2>
            <p class="section-subtitle">Frequently Asked Questions</p>
            <div class="row">
                <div class="col-lg-6">
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqOneTitle">
                            <a href="#faqOneCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">What is Foi app?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqOneCollapse" class="collapse" aria-labelledby="faqOneTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqTwoTitle">
                            <a href="#faqTwoCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">Why should I use Foi app?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqTwoCollapse" class="collapse" aria-labelledby="faqTwoTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqThreeTitle">
                            <a href="#faqThreeCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">How can I use Foi app?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqThreeCollapse" class="collapse" aria-labelledby="faqThreeTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqFourTitle">
                            <a href="#faqFourCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">Who will see my updates?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqFourCollapse" class="collapse" aria-labelledby="faqFourTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqFiveTitle">
                            <a href="#faqFiveCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">Can people see my connections?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqFiveCollapse" class="collapse" aria-labelledby="faqFiveTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 landing-faq-card">
                        <div class="card-header bg-white" id="faqSixTitle">
                            <a href="#faqSixCollapse" class="d-flex align-items-center" data-toggle="collapse">
                                <h6 class="mb-0">Being a user, what all rights I have?</h6> <i class="far fa-plus-square ml-auto"></i>
                            </a>
                        </div>
                        <div id="faqSixCollapse" class="collapse" aria-labelledby="faqSixTitle">
                            <div class="card-body">
                                <p class="mb-0 text-gray">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <footer class="foi-footer text-white">
        <div class="container">
            <div class="row footer-content">
                <div class="col-xl-6 col-lg-7 col-md-8">
                    <h2 class="mb-0">Deixa-nos uma mensagem e nós entraremos em contacto contigo.</h2>
                </div>
                <!-- <div class="col-md-4 col-lg-5 col-xl-6 py-3 py-md-0 d-md-flex align-items-center justify-content-end">
                    <a href="contact.html" class="btn btn-danger btn-lg">Contact form</a>
                </div> -->
                <div class="container" style="min-height: 50vh;margin-bottom: 30px; margin-top: 70px; background-color: white; color: black;
                            border-radius: 15px;padding: 15px;">
                    <mat-form-field class="example" appearance="fill" style="width: 100%; background-color: white; color: black;">
                        <mat-label>Nome</mat-label>
                        <input type="text" placeholder="Nome"  matInput
                            [formControl]="contactNameControl"  style="margin-top: 10px;">
                    </mat-form-field>
                    <mat-form-field class="example" appearance="fill" style="width: 100%;">
                        <mat-label>Contacto</mat-label>
                        <input type="text" placeholder="Contacto"  matInput
                            [formControl]="contactPhoneControl"  style="margin-top: 10px;">
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%; background-color: white; color: black;">
                        <mat-label>Motivo contacto</mat-label>
                        <input type="text" placeholder="Ex: Gostaria de uma demonstração do Agenda Já como prestador de serviços."  matInput
                            [formControl]="contactReasonControl"  style="margin-top: 10px;">
                    </mat-form-field>
                    <input type="button" class="company-button" value="Contactem-me!" (click)="contactMe()">
                </div>
            
            </div>
        </div>
    </footer>
</body>

</html>