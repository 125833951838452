
<div  (click)="gotoCheckout()" style="cursor: pointer; height: 50px;">
    <div style="display: inline-block;
    position: relative;
    width: 20px;
    height: 40px;
    margin-top: 20px;
    ">
        <span class="material-symbols-outlined"  style="position: absolute;">
            shopping_cart
        </span>        
    </div>

    <div style="
    display: inline-block;
    position: relative;
    width: 60px;
    height: 40px;">
        <div class="badge" style="position: absolute;">
            <span>{{ CartItems.length }}</span>
        </div>        
    </div>

</div>
