<div class="recover-head">
    <h2>Recuperar password</h2>
</div>


<div class="container" style="padding-top: 50px;padding-bottom: 50px;min-height: 60vh;">
    <p>Por favor, introduza o endereço de e-mail com qual se registou.</p>
    <p>Ser-lhe-á enviado um endereço onde pode efetuar a reposição de uma nova password.</p>

    <div style="text-align: center;">
        <form [formGroup]="emailFormGroup">
            <mat-form-field class="form-full-width" style="width: 350px;">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="E-mail" 
                    formControlName="emailCtrl" required
                    [(ngModel)]="email">
            </mat-form-field>
        </form>
        <br>

        <button mat-raised-button color="primary" [disabled]="!emailFormGroup.valid" (click)="recoverPassword()">Recuperar password</button>

        <div class="alert alert-success alert-row" role="alert" *ngIf="sentSuccess">
            <span>Email enviado com sucesso.</span>
        </div>
    </div>

</div>  


