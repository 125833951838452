import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AgendaJaHttpClient } from './app/http-clients/agendaja/agendaja.apiclient';
import { SessionService } from './app/services/session.service';
import { firstValueFrom } from 'rxjs';
import { PwaService } from './app/services/pwa-service';


if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .then(async ref => {
    const httpClient = ref.injector.get(AgendaJaHttpClient);
    const sessionService = ref.injector.get(SessionService);
    const pwaService = ref.injector.get(PwaService);
    


    if(sessionService.GetSessionToken() == ""){

      const session = await firstValueFrom(httpClient.GetAnonymousSession());
      sessionService.StoreLocalSession(session);

      // httpClient.GetAnonymousSession()
      // .subscribe(session => {
      //   sessionService.StoreLocalSession(session);      
      // });

    }
    else{
      let isSessionValid = await firstValueFrom(httpClient.ValidateSession());

      if(!isSessionValid){
        const session = await firstValueFrom(httpClient.GetAnonymousSession());
        sessionService.StoreLocalSession(session);
      }
      // .subscribe(session => {
      //   if(!session){
      //     sessionService.Clear();
      //     httpClient.GetAnonymousSession()
      //     .subscribe(session => {
      //       sessionService.StoreLocalSession(session);      
      //     });  
      //   }  
      // });

    }

  })
  .catch(err => console.error(err));
