import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { timer, take } from 'rxjs';
import { PromptComponent } from '../components/prompt/prompt.component';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) { }

  public initPwaPrompt(event: any) {
    if (this.platform.ANDROID) {
      this.promptEvent = event;
      this.openPromptComponent('android');
    }
    if (this.platform.IOS) {
      // const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      // if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      //}
    }
  }

  // public initPwaPrompt() {
  //   if (this.platform.ANDROID) {
  //     window.addEventListener('beforeinstallprompt', (event: any) => {
  //       event.preventDefault();
  //       this.promptEvent = event;
  //       this.openPromptComponent('android');
  //     });
  //   }
  //   if (this.platform.IOS) {
  //     const navigatorVar: any = window.navigator;
  //     const isInStandaloneMode = ('standalone' in navigatorVar) && (navigatorVar['standalone']);
  //     if (!isInStandaloneMode) {
  //       this.openPromptComponent('ios');
  //     }
  //   }
  // }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    // timer(1000)
    //   .pipe(take(1))
    //   .subscribe(() => this.bottomSheet.open(PromptComponent, { data: { mobileType, promptEvent: this.promptEvent } }));

    if(mobileType == 'ios')
      this.bottomSheet.open(PromptComponent, { data: { mobileType, promptEvent: this.promptEvent } });
    else
      this.promptEvent.prompt();
  }
}